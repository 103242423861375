import React from "react";
import styles from "components/CardForm/CardForm.module.css";
import { FlexDiv, InputErrorMessage } from "components/index";
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { Icons } from "models/enums";
import { DARK_BLUE, GREY_DARK, GREY_LIGHT, RED, VIOLET } from "styles/colors";
import stripeJs, { StripeElementStyle } from "@stripe/stripe-js";
import { useIsDesktop } from "utils/hooks/useIsDesktop";
import classNames from "classnames";

interface CardFormProps {
  className?: string;
  onCardNumberFocusChange: (isFocused: boolean) => void;
  onCardExpirationDateFocusChange: (isFocused: boolean) => void;
  onCardCVCFocusChange: (isFocused: boolean) => void;
  cardNumberError?: string;
  cardExpirationDateError?: string;
  cardCVCError?: string;
  cardNumberFocused: boolean;
  cardExpirationDateFocused: boolean;
  cardCVCFocused: boolean;
  onCardNumberChange: (event: stripeJs.StripeCardNumberElementChangeEvent) => void;
  onCardExpirationDateChange: (event: stripeJs.StripeCardExpiryElementChangeEvent) => void;
  onCardCVCChange: (event: stripeJs.StripeCardCvcElementChangeEvent) => void;
}

const CardForm: React.FC<CardFormProps> = (props) => {
  // prettier-ignore
  const { onCardCVCFocusChange, onCardNumberFocusChange, onCardExpirationDateFocusChange, cardNumberFocused,
    cardExpirationDateError, cardCVCError, cardNumberError, cardCVCFocused, cardExpirationDateFocused,
    onCardCVCChange, onCardNumberChange, onCardExpirationDateChange, className } = props;
  const isDesktop = useIsDesktop();

  function getBorderStyle(field: "cardNumber" | "cardCVC" | "cardExpirationDate") {
    let isStrongBorder: boolean = cardNumberFocused || !!cardNumberError;
    let showErrorBorder: boolean = !!cardNumberError;
    switch (field) {
      case "cardNumber":
        return {
          border: `${isStrongBorder ? 2 : 1}px solid ${
            showErrorBorder ? RED : cardNumberFocused ? VIOLET : GREY_LIGHT
          }`,
        };
      case "cardExpirationDate":
        isStrongBorder = (cardExpirationDateFocused || !!cardExpirationDateError) && !cardNumberError;
        showErrorBorder = !!cardExpirationDateError && !cardNumberError;
        return {
          borderTop: 0,
          borderRight: 0,
          border: `${isStrongBorder ? 2 : 1}px solid ${
            showErrorBorder ? RED : cardExpirationDateFocused ? VIOLET : GREY_LIGHT
          }`,
        };
      case "cardCVC":
        isStrongBorder = (cardCVCFocused || !!cardCVCError) && !cardNumberError && !cardExpirationDateError;
        showErrorBorder = !!cardCVCError && !cardNumberError && !cardExpirationDateError;
        return {
          borderTop: 0,
          border: `${isStrongBorder ? 2 : 1}px solid ${showErrorBorder ? RED : cardCVCFocused ? VIOLET : GREY_LIGHT}`,
        };
    }
  }

  const stripeCardStyle: StripeElementStyle = {
    base: {
      fontSize: isDesktop ? "16px" : "18px",
      color: DARK_BLUE,
      fontFamily: "Open Sans",
      fontWeight: 500,
    },
    empty: { color: GREY_DARK },
  };
  const stripeOptions = { style: stripeCardStyle };
  const errorMessage = cardNumberError || cardExpirationDateError || cardCVCError;
  return (
    <>
      <FlexDiv className={classNames(styles.cardForm, className)}>
        <FlexDiv className={styles.cardNumberField} style={getBorderStyle("cardNumber")}>
          <CardNumberElement
            options={{ ...stripeOptions, showIcon: true }}
            onChange={onCardNumberChange}
            onFocus={() => onCardNumberFocusChange(true)}
            onBlur={() => onCardNumberFocusChange(false)}
          />
        </FlexDiv>
        <FlexDiv className={styles.cardFormInline}>
          <FlexDiv className={styles.cardExpirationField} style={getBorderStyle("cardExpirationDate")}>
            <CardExpiryElement
              options={stripeOptions}
              onChange={onCardExpirationDateChange}
              onFocus={() => onCardExpirationDateFocusChange(true)}
              onBlur={() => onCardExpirationDateFocusChange(false)}
            />
            {cardExpirationDateError && (
              <FlexDiv className={styles.fieldNotValidCircle}>
                <img src={Icons.EXCLAMATION_MARK} className={styles.exclamationMark} alt={"icon"} />
              </FlexDiv>
            )}
          </FlexDiv>
          <FlexDiv className={styles.cardCVCForm} style={getBorderStyle("cardCVC")}>
            <CardCvcElement
              options={stripeOptions}
              onChange={onCardCVCChange}
              onFocus={() => onCardCVCFocusChange(true)}
              onBlur={() => onCardCVCFocusChange(false)}
            />
            {cardCVCError && (
              <FlexDiv className={styles.fieldNotValidCircle}>
                <img src={Icons.EXCLAMATION_MARK} className={styles.exclamationMark} />
              </FlexDiv>
            )}
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
      <InputErrorMessage errorMessage={errorMessage} />
    </>
  );
};

export default React.memo(CardForm);
