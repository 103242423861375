import { AxiosRequestConfig } from "axios";
import Stripe from "stripe";
import { getPlanType, getPriceUnit } from "utils/helpers";
import { StoreActionTypes } from "models/store/actionTypes";
import { PayPalSubscriptionStatus, SubscriptionStatus } from "models/enums";
export type EmptyObject = Record<string, never>;

export interface PaymentCheckoutState {
  success: boolean;
  isFreeTrial: boolean;
  email?: string;
  priceToPay?: number;
  duration?: string;
  trialEndDate?: string;
}

export interface CanMakePaymentWithGoogleApplePay {
  applePay: boolean;
  googlePay: boolean;
}

export interface ApiRequest<T> {
  url: string;
  options: AxiosRequestConfig;
  includeAuthorizationHeader?: boolean;
}

export interface ApiRequestError {
  message: string;
  title: string;
}

export interface CreatePaymentIntentResponse {
  clientSecret: string;
  paymentIntentId: string;
}

export interface Price extends Stripe.Price {}
export interface Subscription extends Stripe.Subscription {}
export interface PaymentMethod extends Stripe.PaymentMethod {}
export interface Customer extends Stripe.Customer {}
export interface Coupon extends Stripe.Coupon {}

export interface ProductPaymentIntent {
  intentId: string;
  amount: number;
  currency: string;
}

export interface AuthenticationResponse {
  token: string;
}

export interface InputFormType {
  value: string;
  errorMsg?: string;
  isFocused: boolean;
}

export interface AccountFormType {
  name: InputFormType;
  email: InputFormType;
  password: InputFormType;
  formError?: string;
}

export interface UserInfo {
  name: string;
  email: string;
  id: string;
  avatar: string;
  coach: Coach;
  fcmToken?: string;
  timezoneOffset?: number;
  planId?: number;
  isSubscriptionActive: boolean;
  promoAdminUntil: Date;
  promoCodeUntil: Date;
  createdAt: string;
  weekDays?: string[];
  experience?: Experience;
  gender?: Gender;
  themeId?: number;
  amoCategoryId?: number;
  isAdmin?: boolean;
}

export enum Experience {
  "beginner" = "beginner",
  "intermediate" = "intermediate",
  "advanced" = "advanced",
}

export enum Gender {
  "male" = "male",
  "female" = "female",
  "other" = "other",
}

export enum SubscriptionType {
  PAYPAL = "PAYPAL",
  STRIPE = "STRIPE",
}
export interface AccountCardButton {
  text: string;
  onClick: () => void;
  theme?: "DARK" | "LIGHT" | "PRIMARY";
  disabled?: boolean;
  renderAsPaypalButton?: boolean;
}

export interface Product {
  id: string;
  price: number;
  duration: string;
  planType: ReturnType<typeof getPlanType>;
  productType: string;
  priceUnit: ReturnType<typeof getPriceUnit>;
  discount: string | undefined;
  description: string;
  currency: string;
  unit_amount: number;
}

export type ProductInterval = Stripe.Price.Recurring.Interval;

export interface UserSubscription {
  id: string;
  status: SubscriptionStatus;
  nextBillDateUnix: number;
  product: Product;
  type: SubscriptionType;
}

export interface UserPaymentMethod {
  id: string;
  brand: string;
  expMonth: number;
  expYear: number;
  last4: string;
}

export interface Translation {
  en: string;
  pt: string;
}

export interface UIAction {
  name: StoreActionTypes;
  params?: { id: number };
}

export interface PayPalSubscription {
  status: PayPalSubscriptionStatus;
  status_update_time: string;
  id: string;
  plan_id: string;
  start_time: string;
  quantity: string;
  shipping_amount: {
    currency_code: string;
    value: string;
  };
  subscriber: {
    email_address: string;
    payer_id: string;
    name: {
      given_name: string;
      surname: string;
    };
    shipping_address: {
      address: {
        address_line_1: string;
        admin_area_2: string;
        admin_area_1: string;
        postal_code: string;
        country_code: string;
      };
    };
  };
  billing_info: {
    outstanding_balance: {
      currency_code: string;
      value: string;
    };
    cycle_executions: [
      {
        tenure_type: string;
        sequence: number;
        cycles_completed: number;
        cycles_remaining: number;
        total_cycles: number;
      },
      {
        tenure_type: string;
        sequence: number;
        cycles_completed: number;
        cycles_remaining: number;
        current_pricing_scheme_version: number;
        total_cycles: number;
      }
    ];
    next_billing_time: string;
    final_payment_time: string;
    failed_payments_count: number;
  };
  create_time: string;
  update_time: string;
  plan_overridden: false;
}

export interface Coach {
  id: number;
  name: string;
  description: string;
  avatar: string;
  cover: string;
  subscriptionCover: string;
  isAvailable: boolean;
}

export interface PaymentParams {
  coachName?: string;
  planInterval?: string;
  login?: string;
}
