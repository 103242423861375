import React from "react";
import styles from "components/PlanOverview/PlanOverview.module.css";
import AccountCard from "components/AccountCard";
import FlexDiv from "components/FlexDiv";
import PlanTitleSubtitle from "components/PlanTitleSubtitle";
import { AccountCardButton, UserSubscription } from "models/interfaces";
import PriceDuration from "components/PriceDuration";
import classNames from "classnames";
import PaymentDetails from "components/PaymentDetails";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";
import { hasSubscription } from "utils/helpers";
import { useSelector } from "react-redux";
import { selectedCoachSelector } from "store/coach/coachSelectors";
import { TransKey } from "utils/i18n/allTranslationsTyped";

interface PlanOverviewProps {
  subscription: UserSubscription | null;
  onPaymentUpdateClick: () => void;
  cardTitle: string;
  showPriceDuration: boolean;
  cardButtons: AccountCardButton[];
}

const PlanOverview: React.FC<PlanOverviewProps> = ({ subscription, showPriceDuration, cardTitle, cardButtons }) => {
  const { priceUnit, price, planType, description, duration } = subscription?.product || {};
  const { t } = useTypedTranslation();
  const selectedCoach = useSelector(selectedCoachSelector);
  const subscribed = hasSubscription(subscription?.status, subscription?.nextBillDateUnix);
  const plantTitle = subscribed ? `Amo ${planType}` : t("youDontHavePlan");
  const planSubtitle = subscribed ? description : t("getFullAccessSubscribe");
  const translatedDuration = t(duration?.replace(/[^a-zA-Z]/, "").trim() as TransKey);
  const durationInterval = duration?.match(/\d+/);
  const interval = durationInterval ? durationInterval[0] + " " : "";
  return (
    <AccountCard title={cardTitle} buttons={cardButtons}>
      <FlexDiv
        className={classNames(
          styles.planOverviewContainer,
          showPriceDuration ? styles.planOverviewContainerWithPriceDuration : ""
        )}
      >
        <FlexDiv className={styles.planTitleWrapper}>
          <PlanTitleSubtitle planTitle={plantTitle} planSubtitle={planSubtitle} />
          {showPriceDuration && price && priceUnit && duration && (
            <PriceDuration
              className={styles.priceDurationWrapper}
              price={price}
              priceUnit={priceUnit}
              duration={`${interval}${translatedDuration}`}
              type={"small"}
            />
          )}
        </FlexDiv>
        <img src={selectedCoach?.cover} className={styles.coach} alt={"Coach Cover"} />
      </FlexDiv>
      <PaymentDetails />
    </AccountCard>
  );
};

export default React.memo(PlanOverview);
