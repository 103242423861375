import React from "react";
import styles from "components/SideNavBar/SideNavBar.module.css";
import FlexDiv from "components/FlexDiv";
import NavButton from "components/NavButton";
import { Icons, SideBarTabs } from "models/enums";
import DownloadAmo from "components/DownloadAmo";
import classNames from "classnames";
import { useIsDesktop } from "utils/hooks/useIsDesktop";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";

interface SideNavBarProps {
  className?: string;
  onAccountClick: () => void;
  onPlansClick: () => void;
  onProfileClick: () => void;
  onLogoutClick: () => void;
  onToggleBarClick: () => void;
  activeTab: SideBarTabs;
  showBar: boolean;
}

const SideNavBar: React.FC<SideNavBarProps> = ({
  className,
  onAccountClick,
  onPlansClick,
  onProfileClick,
  onLogoutClick,
  activeTab,
  showBar,
  onToggleBarClick,
}) => {
  const { t } = useTypedTranslation();
  const isDesktop = useIsDesktop();
  return (
    <>
      {!isDesktop && (
        <FlexDiv className={styles.mobileTopBar}>
          <img src={!showBar ? Icons.MENU : Icons.CLOSE} className={styles.menuIcon} onClick={onToggleBarClick} />
          <img className={styles.amoBadgeMobile} src={Icons.AMO} />
        </FlexDiv>
      )}
      <FlexDiv
        className={classNames(
          styles.container,
          !isDesktop ? styles.mobileBar : "",
          !showBar ? styles.hiddenNavBar : "",
          className
        )}
      >
        <FlexDiv className={styles.subContainer}>
          <img className={styles.amoBadge} src={Icons.AMO} alt={"Amo Logo"} />
          <NavButton
            isActive={activeTab === SideBarTabs.ACCOUNT}
            title={t("account")}
            icon={Icons.ACTIVITY}
            onClick={onAccountClick}
          />
          <NavButton
            isActive={activeTab === SideBarTabs.PLANS}
            title={t("plans")}
            icon={Icons.WALLET}
            onClick={onPlansClick}
          />
          <NavButton
            isActive={activeTab === SideBarTabs.PROFILE}
            title={t("profile")}
            icon={Icons.PROFILE}
            onClick={onProfileClick}
          />
          <hr className={styles.line} />
          <NavButton
            isActive={activeTab === SideBarTabs.LOG_OUT}
            title={t("logout")}
            icon={Icons.LOGOUT}
            onClick={onLogoutClick}
          />
          <FlexDiv className={styles.downloadContainer}>
            <DownloadAmo />
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    </>
  );
};

export default React.memo(SideNavBar);
