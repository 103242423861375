import React from "react";
import styles from "components/PayWithCardForm/PayWithCardForm.module.css";
import { FlexDiv } from "components";

interface PayWithCardFormProps {}

const PayWithCardForm: React.FC<PayWithCardFormProps> = () => {
  return (
    <FlexDiv className={styles.container}>
      <hr className={styles.line} />
      <p className={styles.payWithCard}>Or Pay With Card</p>
      <hr className={styles.line} />
    </FlexDiv>
  );
};

export default React.memo(PayWithCardForm);
