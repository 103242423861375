import { useMemo, useState } from "react";
import stripeJs from "@stripe/stripe-js";

interface CardInputFormType {
  complete: boolean;
  errorMsg?: string;
  isFocused: boolean;
}

interface CardFormType {
  cardNumber: CardInputFormType;
  cardExpirationDate: CardInputFormType;
  cardCVC: CardInputFormType;
}

export function useCardForm() {
  const [cardForm, setCardForm] = useState<CardFormType>({
    cardNumber: {
      complete: false,
      errorMsg: undefined,
      isFocused: false,
    },
    cardExpirationDate: {
      complete: false,
      errorMsg: undefined,
      isFocused: false,
    },
    cardCVC: {
      complete: false,
      errorMsg: undefined,
      isFocused: false,
    },
  });
  const onCardFormChange =
    (filed: keyof typeof cardForm) =>
    (
      event:
        | stripeJs.StripeCardCvcElementChangeEvent
        | stripeJs.StripeCardExpiryElementChangeEvent
        | stripeJs.StripeCardNumberElementChangeEvent
    ) => {
      setCardForm((prevState) => ({
        ...prevState,
        [filed]: { ...prevState[filed], complete: event.complete, errorMsg: event.error?.message },
      }));
    };

  const onCardFormFocusChange = (fieldKey: keyof typeof cardForm) => (isFocused: boolean) => {
    setCardForm((prevState) => ({ ...prevState, [fieldKey]: { ...prevState[fieldKey], isFocused } }));
  };

  function validateCard() {
    return cardForm.cardNumber.complete && cardForm.cardCVC.complete && cardForm.cardExpirationDate.complete;
  }
  const isCardFormValid = useMemo(() => {
    return validateCard();
  }, [cardForm]);

  return { cardForm, setCardForm, onCardFormChange, onCardFormFocusChange, validateCard, isCardFormValid };
}
