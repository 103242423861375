import { call, put, select, takeLeading } from "typed-redux-saga";
import ApiService, { userRequests } from "services/api";
import { UserActionTypes } from "models/store/actionTypes";
import { getUserInfoSuccess, setTempUserToken, setUserToken } from "store/user/userActions";
import { LoginAction, RegisterAction } from "models/store/userActionts";
import { startAction, stopAction } from "store/ui/uiActions";
import { toast } from "react-toastify";
import { translate } from "utils/i18n";
import { selectedCoachSelector } from "store/coach/coachSelectors";
import { selectCoach } from "store/coach/coachActions";
import ReactPixel from "react-facebook-pixel";
import { FacebookPixelEvent } from "models/enums";

export function* getUserInfoSaga() {
  try {
    const response = yield* call(() => ApiService.callApi(userRequests.getUserInfo()));
    if (response.status === 200) {
      const userInfo = response.data;
      yield put(getUserInfoSuccess(userInfo));
      yield put(selectCoach(userInfo.coach));
    }
  } catch (error) {
    console.log("createPaymentIntentSaga error", error);
  }
}

export function* watchGetUserInfoSaga() {
  yield takeLeading(UserActionTypes.GET_USER_INFO, getUserInfoSaga);
}

export function* loginSaga(action: LoginAction) {
  try {
    yield put(startAction({ name: action.type }));
    const { email, password, isSaveToStore } = action.payload;
    const response = yield* call(() => ApiService.callApi(userRequests.login(email, password)));
    if (response.status === 200) {
      const token = response.data.token;
      if (isSaveToStore) {
        yield put(setUserToken(token));
      } else {
        yield put(setTempUserToken(token));
      }
    } else if (response.status === 401) {
      toast.error(translate("invalidUsernamePassword"));
    } else {
      toast.error(translate("somethingWentWrong"));
    }
  } catch (error) {
    console.log("loginSaga error", error);
    toast.error(translate("somethingWentWrong"));
  } finally {
    yield put(stopAction({ name: action.type }));
  }
}

export function* watchLoginSaga() {
  yield takeLeading(UserActionTypes.LOGIN, loginSaga);
}

export function* registerSaga(action: RegisterAction) {
  try {
    yield put(startAction({ name: action.type }));
    const { name, email, password, isSaveToStore } = action.payload;
    const coach = yield* select(selectedCoachSelector);
    if (!coach) {
      return;
    }
    const response = yield* call(() => ApiService.callApi(userRequests.register(email, password, name, coach.id)));
    if (response.status === 200) {
      yield* call(() => ReactPixel.track(FacebookPixelEvent.CompleteRegistration, { email }));
      const token = response.data.token;
      if (isSaveToStore) {
        yield put(setUserToken(token));
      } else {
        yield put(setTempUserToken(token));
      }
    } else if (response.status === 409) {
      toast.error(translate("emailAlreadyTaken"));
    } else {
      toast.error(translate("somethingWentWrong"));
    }
  } catch (error) {
    console.log("registerSaga error", error);
    toast.error(translate("somethingWentWrong"));
  } finally {
    yield put(stopAction({ name: action.type }));
  }
}

export function* watchRegisterSaga() {
  yield takeLeading(UserActionTypes.REGISTER, registerSaga);
}
