import FlexDiv from "components/FlexDiv";
import InputErrorMessage from "components/InputErrorMessage";
import TotalAmount from "components/TotalAmount";
import React from "react";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";
import styles from "components/PromoCodeForm/PromoCodeForm.module.css";
import PromoCodeInput from "components/PromoCodeInput";
import Tag from "components/Tag";

interface CouponFormProps {
  couponCode: string;
  validCouponCode?: string;
  onCouponSubmit: () => void;
  onRemoveCouponCode: () => void;
  onCouponCodeChange: (value: string) => void;
  priceToPay: number;
  amountOff?: number;
  percentOff?: number;
  isCouponLoading: boolean;
  couponError?: string;
  freeTrialUsed: boolean;
}
const CouponForm: React.FC<CouponFormProps> = ({
  couponCode,
  onCouponCodeChange,
  onCouponSubmit,
  priceToPay,
  validCouponCode,
  onRemoveCouponCode,
  isCouponLoading,
  couponError,
  amountOff,
  percentOff,
  freeTrialUsed,
}) => {
  const { t } = useTypedTranslation();
  return (
    <>
      <FlexDiv className={styles.promoCodeContainer}>
        {validCouponCode && (
          <FlexDiv className={styles.couponContainer}>
            <Tag couponCode={validCouponCode} onRemoveCouponCode={onRemoveCouponCode} />
            <FlexDiv>
              <p className={styles.amountOff}>-R${amountOff}</p>
              <p className={styles.percentOff}>
                {percentOff}% {t("off")}
              </p>
            </FlexDiv>
          </FlexDiv>
        )}
        {!validCouponCode && (
          <PromoCodeInput
            onSubmit={onCouponSubmit}
            placeholder={t("promoCode")}
            value={couponCode}
            onValueChange={onCouponCodeChange}
            hasError={!!couponError}
            isLoading={isCouponLoading}
          />
        )}
        <InputErrorMessage errorMessage={couponError} />
      </FlexDiv>
      <FlexDiv>
        {!freeTrialUsed ? (
          <>
            <TotalAmount text={t("totalAfterToday")} price="R$0.00" bold />
            <TotalAmount text={t("totalAfterTrial")} price={`R$${priceToPay}`} />
          </>
        ) : (
          <TotalAmount text={t("totalPriceToPay")} price={`R$${priceToPay}`} />
        )}
      </FlexDiv>
    </>
  );
};

export default React.memo(CouponForm);
