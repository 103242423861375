import React from "react";
import styles from "components/Tag/Tag.module.css";
import FlexDiv from "components/FlexDiv";
import { Icons } from "models/enums";

interface TagProps {
  couponCode?: string;
  onRemoveCouponCode: () => void;
}

const Tag: React.FC<TagProps> = ({ couponCode, onRemoveCouponCode }) => {
  if (!couponCode) {
    return null;
  }
  return (
    <FlexDiv className={styles.container}>
      <img src={Icons.TAG_FILL} className={styles.tagIcon} />
      <p className={styles.text}>{couponCode}</p>
      <img src={Icons.TAG_CLOSE} className={styles.closeIcon} onClick={onRemoveCouponCode} />
    </FlexDiv>
  );
};

export default React.memo(Tag);
