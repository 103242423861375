import React, { HTMLProps } from "react";
import styles from "components/UnderlinedText/UnderlinedText.module.css";
import classNames from "classnames";

interface UnderlinedTextProps {
  text: string;
  className?: string;
}

const UnderlinedText: React.FC<UnderlinedTextProps & HTMLProps<HTMLParagraphElement>> = ({
  text,
  className,
  ...restProps
}) => {
  return (
    <p
      className={classNames(styles.text, className)}
      {...restProps}
      style={restProps.onClick ? { cursor: "pointer" } : undefined}
    >
      {text}
    </p>
  );
};

export default React.memo(UnderlinedText);
