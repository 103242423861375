import React from "react";
import Lottie from "lottie-react";
import gradientLoader from "assets/lottie/gradientLoader.json";
import styles from "screens/Plan/Plan.module.css";
import { AvatarAmo, BuyProductButton, FlexDiv, MainLayout } from "components";
import { MixpanelEvent, Routes } from "models/enums";
import { generatePath, useHistory } from "react-router-dom";
import { useIsDesktop } from "utils/hooks/useIsDesktop";
import { useDispatch, useSelector } from "react-redux";
import { setActiveProduct } from "store/products/productActions";
import { productsSelector } from "store/products/productSelectors";
import { Product } from "models/interfaces";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";
import { selectedCoachSelector } from "store/coach/coachSelectors";
import { TransKey } from "utils/i18n/allTranslationsTyped";
import { useCoachFromParams } from "utils/hooks/useCoachFromParams";
import { useMount } from "react-use";
import { userTokenSelector } from "store/user/userSelectors";
import { FREE_TRIAL_DAYS } from "models/constants";
import mixpanel from "mixpanel-browser";
import { Trans } from "react-i18next";

const Plan: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTypedTranslation();
  const products = useSelector(productsSelector);
  const selectedCoach = useSelector(selectedCoachSelector);
  const isDesktop = useIsDesktop();
  const history = useHistory();
  const token = useSelector(userTokenSelector);
  const { coachName } = useCoachFromParams();

  useMount(() => {
    mixpanel.track(MixpanelEvent.PAYMENT_PLANS, { coach: coachName });
    if (token) {
      history.replace(Routes.ACCOUNT_PLANS);
    }
  });

  function onProductClick(product: Product) {
    dispatch(setActiveProduct(product));
    const planInterval = product.productType.toLowerCase() || "";
    const coachName = selectedCoach?.name?.toLowerCase() || "";
    mixpanel.track(MixpanelEvent.PAYMENT_PLAN_SELECT, { plan: planInterval, coach: coachName });
    history.push(generatePath(`${Routes.PAYMENT}`, { planInterval, coachName }));
  }

  return (
    <MainLayout
      leftSide={isDesktop ? <AvatarAmo isPlansScreen={true} /> : null}
      rightSide={
        <FlexDiv className={styles.container}>
          <FlexDiv className={styles.gradientAvatarContainer}>
            <Lottie animationData={gradientLoader} className={styles.gradientLoader} />
            <FlexDiv className={styles.avatarContainer}>
              <img src={selectedCoach?.avatar} className={styles.trainerAvatar} alt={"trainer"} />
            </FlexDiv>
          </FlexDiv>
          <FlexDiv className={styles.titleContainer}>
            <h1 className={styles.title}>
              <Trans i18nKey={"chooseYourPlanTrial"} values={{ days: FREE_TRIAL_DAYS }} />
            </h1>
            <h2 className={styles.subtitle}>{t("afterTrialChargesApply")}</h2>
          </FlexDiv>
          <FlexDiv className={styles.planContainer}>
            {products.map((product) => {
              const duration = t(product?.duration?.replace(/[^a-zA-Z]/, "").trim() as TransKey);
              const durationInterval = product?.duration?.match(/\d+/);
              const interval = durationInterval ? durationInterval[0] + " " : "";
              return (
                <BuyProductButton
                  onClick={() => onProductClick(product)}
                  key={product.id}
                  type={t(product.productType.toLowerCase() as TransKey)}
                  priceUnit={product.priceUnit}
                  price={product.price}
                  duration={`${interval}${duration}`}
                  trialDuration={FREE_TRIAL_DAYS}
                  discount={product.discount}
                />
              );
            })}
          </FlexDiv>
        </FlexDiv>
      }
    />
  );
};

export default React.memo(Plan);
