import { combineReducers } from "redux";
import stripeReducer, { StripeState } from "store/stripe/stripeReducer";
import userReducer, { UserState } from "store/user/userReducer";
import productReducer from "store/products/productReducer";
import coachReducer from "store/coach/coachReducer";
import uiReducer, { UIState } from "store/ui/uiReducer";
import paypalReducer, { PaypalState } from "store/paypal/paypalReducer";
import { UserActionTypes } from "models/store/actionTypes";
import { StripeActions } from "models/store/stripeActions";
import { UserActions } from "models/store/userActionts";
import { ProductActions } from "models/store/productActions";
import { CoachActions } from "models/store/coachActions";
import { UIActions } from "models/store/uiActions";
import { PayPalActions } from "models/store/paypalActions";

const appReducer = combineReducers({
  stripe: stripeReducer,
  user: userReducer,
  product: productReducer,
  coach: coachReducer,
  ui: uiReducer,
  paypal: paypalReducer,
});

const rootReducer = (
  state: StoreState,
  action: StripeActions | UserActions | ProductActions | CoachActions | UIActions | PayPalActions
) => {
  if (action.type === UserActionTypes.LOGOUT) {
    return appReducer(
      {
        product: state.product,
        coach: state.coach,
        stripe: undefined as unknown as StripeState,
        user: undefined as unknown as UserState,
        ui: undefined as unknown as UIState,
        paypal: undefined as unknown as PaypalState,
      },
      action
    );
  }

  return appReducer(state, action);
};

export default rootReducer;
export type StoreState = ReturnType<typeof appReducer>;
