import { initReactI18next } from "react-i18next";
import i18next, { TOptions } from "i18next";
import { TransKey } from "utils/i18n/allTranslationsTyped";
import english from "utils/i18n/en";
import portuguese from "utils/i18n/pt";
import languageDetector from "i18next-browser-languagedetector";

i18next
  .use(languageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    resources: { en: english, pt: portuguese },
    // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: { escapeValue: false },
  });

export function translate(key: TransKey, options?: TOptions): string {
  return i18next.t(key, options);
}

export default i18next;
