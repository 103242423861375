import React, { useCallback, useEffect, useState } from "react";
import styles from "screens/Authentication/Authentication.module.css";
import { AccountForm, FlexDiv, PrimaryButton, PrivacyPolicy } from "components";
import { Icons, Routes } from "models/enums";
import { useAuthenticationForm } from "utils/hooks/useAuthenticationForm";
import { useSelector } from "react-redux";
import { login, register } from "store/user/userActions";
import { userTokenSelector } from "store/user/userSelectors";
import { useHistory, useLocation } from "react-router-dom";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";
import { useAuthentication } from "utils/hooks/useAuthentication";

interface AuthenticationProps {}

const Authentication: React.FC<AuthenticationProps> = () => {
  const { t } = useTypedTranslation();
  const userToken = useSelector(userTokenSelector);
  const history = useHistory();
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(location.pathname === Routes.AUTHENTICATION_LOGIN);
  const { accountForm, onForgotPasswordClick, onAccountFormFocusChange, onAccountFormValueChange, isAccountFormValid } =
    useAuthenticationForm(isLogin);
  const { isLoading, onLoginClick, onRegisterClick } = useAuthentication(accountForm);

  const onEnterPress = useCallback(
    (event: KeyboardEvent) => {
      if (isAccountFormValid && event.keyCode === 13) {
        isLogin ? onLoginClick() : onRegisterClick();
      }
    },
    [isAccountFormValid, isLogin, onLoginClick, onRegisterClick]
  );

  useEffect(() => {
    document.addEventListener("keydown", onEnterPress);
    return () => {
      document.removeEventListener("keydown", onEnterPress);
    };
  }, [onEnterPress]);

  useEffect(() => {
    if (userToken) {
      history.push(Routes.ACCOUNT);
    }
  }, [userToken]);

  return (
    <FlexDiv className={styles.container}>
      <img src={Icons.AMO} className={styles.amo} />
      <FlexDiv className={styles.accountFormContainer}>
        <FlexDiv className={styles.accountForm}>
          <AccountForm
            name={accountForm.name.value}
            nameErrorMessage={accountForm.name.errorMsg}
            onNameFocusChange={onAccountFormFocusChange("name")}
            onNameChange={onAccountFormValueChange("name")}
            email={accountForm.email.value}
            onEmailChange={onAccountFormValueChange("email")}
            emailErrorMsg={accountForm.email.errorMsg}
            onEmailFocusChange={onAccountFormFocusChange("email")}
            password={accountForm.password.value}
            onPasswordFocusChange={onAccountFormFocusChange("password")}
            passwordErrorMsg={accountForm.password.errorMsg}
            onPasswordChange={onAccountFormValueChange("password")}
            onForgotPasswordClick={onForgotPasswordClick}
            isLogin={isLogin}
            onHaveAccountClick={() => setIsLogin(!isLogin)}
            errorMsg={accountForm.formError}
            showSectionIndex={false}
            onlyLogin={true}
          />
          <PrimaryButton
            isLoading={isLoading}
            disabled={!isAccountFormValid}
            buttonText={isLogin ? t("login") : t("register")}
            className={styles.button}
            onClick={isLogin ? onLoginClick : onRegisterClick}
          />
          <PrivacyPolicy />
        </FlexDiv>
      </FlexDiv>
    </FlexDiv>
  );
};

export default React.memo(Authentication);
