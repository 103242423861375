import { ProductActionTypes } from "models/store/actionTypes";
import { Price, Product } from "models/interfaces";
import { ProductActions } from "models/store/productActions";

export const getProducts = (): ProductActions => ({
  type: ProductActionTypes.GET_PRODUCTS,
  payload: {},
});

export const getProductsSuccess = (products: Price[]): ProductActions => ({
  type: ProductActionTypes.GET_PRODUCTS_SUCCESS,
  payload: { products },
});

export const setActiveProduct = (product: Product): ProductActions => ({
  type: ProductActionTypes.SET_ACTIVE_PRODUCT,
  payload: { product },
});
