import { PaypalActionTypes } from "models/store/actionTypes";
import { UserSubscription } from "models/interfaces";
import { PayPalActions } from "models/store/paypalActions";

export interface PaypalState {
  subscription: null | UserSubscription;
  freeTrialUsed: boolean;
}

const initialState: PaypalState = {
  subscription: null,
  freeTrialUsed: false,
};

const paypalReducer = (state = initialState, action: PayPalActions): PaypalState => {
  switch (action.type) {
    case PaypalActionTypes.SET_PAYPAL_SUBSCRIPTION:
      return { ...state, subscription: action.payload.subscription };
    case PaypalActionTypes.GET_PAYPAL_CUSTOMER_DATA_SUCCESS:
      return { ...state, freeTrialUsed: action.payload.freeTrialUsed };
    default:
      return state;
  }
};

export default paypalReducer;
