import { StoreState } from "store/rootReducer";
import { Coaches } from "models/enums";
import { createSelector } from "reselect";
import { Coach } from "models/interfaces";

export const selectedCoachSelector = (store: StoreState) => store.coach.selectedCoach;
export const coachesSelector = (store: StoreState) => store.coach.coaches;
export const defaultCoachSelector = createSelector([coachesSelector], (coaches): Coach | undefined => {
  return coaches.find((c) => c.name === Coaches.LISIANE);
});
