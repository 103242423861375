import React from "react";
import styles from "components/PaymentCard/PaymentCard.module.css";
import FlexDiv from "components/FlexDiv";
import classNames from "classnames";
import { isChrome, isSafari } from "react-device-detect";
import { Images } from "models/enums";
import { useIsDesktop } from "utils/hooks/useIsDesktop";

interface PaymentCardProps {
  title: string;
  icon: string;
  onClick: () => void;
  isSelected: boolean;
  children?: React.ReactNode;
}

const PaymentCard: React.FC<PaymentCardProps> = ({ onClick, isSelected, title, icon, children }) => {
  const isDesktop = useIsDesktop();
  if (!isDesktop) {
    return (
      <FlexDiv className={classNames(styles.container, isSelected ? styles.containerActive : "")} onClick={onClick}>
        <FlexDiv className={styles.inline}>
          <FlexDiv className={classNames(styles.radioButton, isSelected ? styles.selectedRadioButton : "")} />
          <FlexDiv>
            <p className={styles.title}>{title}</p>
            <img src={icon} className={styles.icon} />
          </FlexDiv>
        </FlexDiv>
      </FlexDiv>
    );
  }
  return (
    <FlexDiv className={classNames(styles.container, isSelected ? styles.containerActive : "")} onClick={onClick}>
      <FlexDiv className={styles.inlineContainer}>
        <FlexDiv className={styles.inline}>
          <FlexDiv className={classNames(styles.radioButton, isSelected ? styles.selectedRadioButton : "")} />
          <p className={styles.title}>{title}</p>
        </FlexDiv>
        <FlexDiv className={styles.cardContainer}>
          <img src={icon} />
        </FlexDiv>
      </FlexDiv>
      {isSelected && children && <FlexDiv className={styles.childContainer}>{children}</FlexDiv>}
    </FlexDiv>
  );
};

export default React.memo(PaymentCard);
