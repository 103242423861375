import React, { useMemo } from "react";
import styles from "components/UserCardDetailsPreview/UserCardDetailsPreview.module.css";
import FlexDiv from "components/FlexDiv/FlexDiv";
import { useSelector } from "react-redux";
import { CardBrands, Icons } from "models/enums";
import { userPaymentMethodSelector } from "store/user/userSelectors";

interface UserCardDetailsPreviewProps {}

const UserCardDetailsPreview: React.FC<UserCardDetailsPreviewProps> = (props) => {
  const paymentMethod = useSelector(userPaymentMethodSelector);
  const { icon, cardLast4 } = useMemo(() => {
    if (paymentMethod === "paypal") {
      return { icon: Icons.PAY_PAL, cardLast4: "" };
    }
    let icon;
    switch (paymentMethod?.brand) {
      case CardBrands.MASTER_CARD:
        icon = Icons.MASTER_CARD;
        break;
      case CardBrands.VISA:
        icon = Icons.VISA_CARD;
        break;
      case CardBrands.DINERS_CLUB:
        icon = Icons.DINERS_CLUB_CARD;
        break;
      case CardBrands.DISCOVER:
        icon = Icons.DISCOVER_CARD;
        break;
      case CardBrands.JCB:
        icon = Icons.JCB_CARD;
        break;
      case CardBrands.AMERICAN_EXPRESS:
        icon = Icons.AMERICAN_EXPRESS_CARD;
        break;
      default:
        icon = Icons.GENERIC_CARD;
        break;
    }
    return { icon, cardLast4: `...${paymentMethod?.last4}` || "" };
  }, [paymentMethod]);

  return (
    <FlexDiv className={styles.cardInfo}>
      <p className={styles.card}>{cardLast4}</p>
      <img src={icon} className={!cardLast4 ? styles.iconWithoutText : styles.cardIcon} alt={"Card Icon"} />
    </FlexDiv>
  );
};

export default React.memo(UserCardDetailsPreview);
