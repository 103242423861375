import React from "react";
import styles from "components/PaymentFeedback/PaymentFeedback.module.css";
import { Icons } from "models/enums";
import UnderlinedText from "components/UnderlinedText";
import FlexDiv from "components/FlexDiv";
import DownloadAmoButton from "components/DownloadAmoButton";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";
import { Trans } from "react-i18next";
import classNames from "classnames";
import { FREE_TRIAL_DAYS } from "models/constants";

interface PaymentFeedbackProps {
  success: boolean;
  freeTrialUsed: boolean;
  onGoBackClick: () => void;
  onLoginClick: () => void;
  onGooglePlayDownloadClick: () => void;
  onAppStoreDownloadClick: () => void;
  amountToCharge?: number;
  duration?: string;
  trialEndDate?: string;
}

const PaymentFeedback: React.FC<PaymentFeedbackProps> = ({
  success,
  onAppStoreDownloadClick,
  onGooglePlayDownloadClick,
  onGoBackClick,
  duration,
  trialEndDate,
  amountToCharge,
  onLoginClick,
  freeTrialUsed,
}) => {
  const { t } = useTypedTranslation();
  const successText = freeTrialUsed ? (
    t("yourPaymentSuccessful")
  ) : (
    <Trans i18nKey={"yourTrialPaymentSuccessful"} values={{ days: FREE_TRIAL_DAYS }} />
  );
  return (
    <FlexDiv className={styles.container}>
      <div className={styles.iconContainer}>
        <img src={success ? Icons.SUCCESS : Icons.FAIL} className={styles.icon} />
      </div>
      <FlexDiv className={styles.textButtonContainer}>
        <p className={classNames(styles.title, !success ? styles.titleFailed : "")}>
          {success ? successText : t("yourPaymentFailed")}
        </p>
        {!success && (
          <>
            <UnderlinedText className={styles.goBack} text={t("goBackToCheckout")} onClick={onGoBackClick} />
            <UnderlinedText className={styles.goBack} text={t("loginToYourAccount")} onClick={onLoginClick} />
            <hr className={styles.line} />
            <p className={styles.downloadApp}>{t("downloadAmo")}</p>
          </>
        )}
        <img src={Icons.DOWN_ARROW} alt="Down arrow" className={styles.downArrow} />
        <FlexDiv className={styles.badgeContainer}>
          <DownloadAmoButton type={"APPLE"} className={styles.downloadButton} onClick={onAppStoreDownloadClick} />
          <div className={styles.separator} />
          <DownloadAmoButton type={"GOOGLE"} className={styles.downloadButton} onClick={onGooglePlayDownloadClick} />
        </FlexDiv>
        {success && !freeTrialUsed && (
          <>
            <hr className={styles.line} />
            <FlexDiv className={styles.afterTrialMessage}>
              <p className={styles.trialText}>
                {t("afterTrialEndsYouWillBeCharged", { amountToCharge, duration, trialEndDate })}
              </p>
            </FlexDiv>
          </>
        )}
      </FlexDiv>
    </FlexDiv>
  );
};

export default React.memo(PaymentFeedback);
