import React from "react";
import styles from "components/BuyProductButton/BuyProductButton.module.css";
import { FlexDiv, PriceDuration } from "components";
import DiscountBanner from "components/DiscountBanner";
import { Trans, useTranslation } from "react-i18next";
import { useIsDesktop } from "utils/hooks/useIsDesktop";

interface BuyProductButtonProps {
  type: string;
  price: number;
  duration: string;
  priceUnit: string;
  discount?: string;
  trialDuration?: number;
  onClick: () => void;
}

const BuyProductButton: React.FC<BuyProductButtonProps> = ({
  type,
  discount,
  duration,
  priceUnit,
  price,
  trialDuration,
  onClick,
}) => {
  const isDesktop = useIsDesktop();
  const { t } = useTranslation();
  return (
    <button className={styles.container} onClick={onClick}>
      <FlexDiv className={styles.innerContainer}>
        <FlexDiv className={styles.titleContainer}>
          <p className={styles.title}>{type}</p>
          <DiscountBanner discount={discount} />
        </FlexDiv>
        <PriceDuration duration={duration} price={price} priceUnit={priceUnit} />
        <FlexDiv>
          <p className={styles.dayFreeTrial}>
            <Trans i18nKey="dayFreeTrialBold" values={{ days: trialDuration }} />
          </p>
        </FlexDiv>
      </FlexDiv>
      {!isDesktop && (
        <FlexDiv>
          <div className={styles.getStartedButton}>
            <p className={styles.getStartedText}>{t("getStarted")}</p>
          </div>
        </FlexDiv>
      )}
    </button>
  );
};

export default React.memo(BuyProductButton);
