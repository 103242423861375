import React from "react";
import styles from "components/PlanTitleSubtitle/PlanTitleSubtitle.module.css";

interface PlanTitleSubtitleProps {
  planTitle: string;
  planSubtitle: string | undefined;
}

const PlanTitleSubtitle: React.FC<PlanTitleSubtitleProps> = ({ planTitle, planSubtitle }) => {
  return (
    <>
      <p className={styles.planTitle}>{planTitle}</p>
      <p className={styles.planSubtitle}>{planSubtitle}</p>
    </>
  );
};

export default React.memo(PlanTitleSubtitle);
