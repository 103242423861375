import React from "react";
import styles from "components/ProfileOverview/ProfileOverview.module.css";
import { AccountCard } from "components";
import FlexDiv from "components/FlexDiv";
import classNames from "classnames";
import UserAvatar from "components/UserAvatar/UserAvatar";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";

interface ProfileOverviewProps {
  onEditProfileClick: () => void;
  name: string;
  email: string;
}

const ProfileOverview: React.FC<ProfileOverviewProps> = ({ onEditProfileClick, name, email }) => {
  const { t } = useTypedTranslation();

  return (
    <AccountCard title={t("profile")} buttons={[{ onClick: onEditProfileClick, text: t("editProfile") }]}>
      <FlexDiv className={styles.container}>
        <UserAvatar className={styles.avatar} />
        <FlexDiv className={styles.inlineContainer}>
          <p className={styles.entryText}>{t("name")}</p>
          <p className={styles.entryValue}>{name}</p>
        </FlexDiv>
        <FlexDiv className={classNames(styles.inlineContainer, styles.emailContainer)}>
          <p className={styles.entryText}>{t("email")}</p>
          <p className={styles.entryValue}>{email}</p>
        </FlexDiv>
      </FlexDiv>
    </AccountCard>
  );
};

export default React.memo(ProfileOverview);
