/**
 * DON'T MODIFY MANUALLY
 * This file was automatically generated by generateTranslations.js
 * If you need to modify a translation, modify them in allTranslations.js
 **/

export default {
  translation: {
    invalidUsernamePassword: "Invalid username or password",
    emailAlreadyTaken: "Email already taken",
    login: "Log in",
    register: "Register",
    iAlreadyHaveAccount: "I already have an account",
    createNewAccount: "Create new account",
    password: "Password",
    email: "Email",
    name: "First name",
    createAccount: "Create Account",
    forgotPassword: "Forgot Password?",
    byContinueAcceptTerms: "By continuing you accept our",
    month: "month",
    months: "months",
    xMonths: "{{count}} months",
    year: "year",
    monthly: "monthly",
    quarterly: "quarterly",
    yearly: "yearly",
    xDaysFreeTrial: "{{trialDays}} days free trial. Cancel anytime!",
    pickYourPlan: "Pick your plan and\n let's get started!",
    planByCoach: "{{planDuration}} plan by {{coachName}}",
    checkout: "Checkout",
    securePayment: "Secure Payment",
    payWithCard: "or pay with card",
    pay: "Pay",
    paymentSuccess: "Your payment has been successful",
    paymentFail: "Your payment has failed",
    goBackToCheckout: "Go back to checkout",
    loginToYourAccount: "Login to your account",
    downloadAmo: "Download Amo app and get started!",
    downloadAmoToday: "Download\nAmo Today!",
    hiUser: "Hi {{username}},",
    accountOverview: "Account Overview",
    editProfile: "Edit Profile",
    profile: "Profile",
    yourPlan: "Your Plan",
    fullAccessToAmo: "Full access to Amo. Charged every {{intervalCount}} {{interval}}",
    payment: "Payment",
    yourFreeTrialEndsOn: "Your free trial for {{product}} ends on {{date}}",
    update: "Update",
    changePlan: "Change Plan",
    getPlan: "Get Plan",
    availablePlans: "Available Plans",
    currentPlan: "Current Plan",
    selectPlan: "Select Plan",
    cancelPlan: "Cancel Plan",
    account: "Account",
    plans: "Plans",
    logout: "Log out",
    firstName: "First Name",
    updatePassword: "Update Password",
    currentPassword: "Current Password",
    newPassword: "New Password",
    confirmNewPassword: "Confirm New Password",
    saveProfile: "Save Profile",
    cancel: "Cancel",
    change: "Change",
    creditOrDebitCard: "Credit or Debit Card",
    updatePaymentDetails: "Update Payment Details",
    backToPlans: "Back to Plans",
    paymentOptions: "Payment Options",
    changePayment: "Change Payment",
    youreChangingPlan: "You're changing plan",
    youreSelectingPlan: "You're selecting {{planType}}",
    confirm: "Confirm",
    youreCancellingPlan: "You're cancelling plan",
    areYouSure: "Are you sure?",
    ableToUseAmoUntil: "You’ll be able to continue using Amo until {{date}}",
    confirmCancellation: "Confirm Cancellation",
    paymentInProgress: "Payment in progress",
    byContinuingYouAccept: "By continuing you accept our",
    privacyPolicy: "Privacy Policy",
    and: "and",
    termsOfUse: "Terms of Use",
    firstCreateAccount: "First create Account",
    yourPaymentSuccessful: "You payment is successful! Download the app and log in!",
    yourTrialPaymentSuccessful: "Your {{days}} day trial begins now! <strong>Download the app and log in!</strong>",
    yourPaymentFailed: "Your payment has failed",
    invalidName: "Invalid Name",
    invalidNewPassword: "Invalid New Password",
    invalidConfirmPassword: "Invalid Confirm Password",
    passwordsDontMatch: "Passwords don't match",
    invalidEmail: "Invalid Email",
    invalidPassword: "Invalid Password",
    paymentDetails: "Payment Details",
    youDontHavePlan: "You don't have a plan",
    getFullAccessSubscribe: "Get full access to Amo. Subscribe today.",
    somethingWentWrong: "Something went wrong.",
    enterValidEmail: "Enter a valid email first",
    yourNextBillFor: "Your next bill for",
    isOn: "is on",
    yourFreeTrialFor: "Your free trial for ",
    endsOn: "ends on",
    yourSubscriptionFor: "Your subscription for",
    planBy: "plan by",
    planTypeByCoach: "{{planType}} plan by {{coachName}}",
    youreChangingPaymentOptionTo: "You’re changing your payment option to {{option}}. You will be charged on {{date}}.",
    promoCodeNotValid: "Promo code is not valid",
    afterTrialEndsYouWillBeCharged:
      "After your trial ends, you will be charged R${{amountToCharge}} per {{duration}} starting {{trialEndDate}}. You can always cancel before then.",
    youWillBeCharged: "You will be charged R${{amountToCharge}} per {{duration}} starting {{firstChargeDate}}.",
    off: "off",
    afterTrialChargesApply: "After trial charges apply. Cancel anytime.",
    dayFreeTrial: "{{days}} days free trial",
    daysFree: "Days free",
    dayFreeTrialBold: "<strong>{{days}} day</strong> free trial",
    getStartedToday: "Get started today",
    getStarted: "Get started",
    plansBy: "Plans by {{coachName}}",
    thenPriceByUnit: "Then {{priceUnit}}{{price}}/{{duration}}",
    promoCode: "Promo Code",
    totalAfterTrial: "Total after trial",
    totalAfterToday: "Total due today",
    totalPriceToPay: "Total price to pay",
    startTrial: "Start Trial",
    cancelAnytime: "Cancel anytime!",
    chooseYourPlanTrial: "Choose your plan and start your <strong>{{days}} day free trial!</strong>",
  },
};
