import { StripeActionTypes } from "models/store/actionTypes";
import { StripeActions } from "models/store/stripeActions";
import { Product, ProductPaymentIntent, UserPaymentMethod, UserSubscription } from "models/interfaces";

export const createPaymentIntent = (productPaymentIntent: ProductPaymentIntent): StripeActions => ({
  type: StripeActionTypes.CREATE_PAYMENT_INTENT,
  payload: productPaymentIntent,
});

export const createPaymentIntentSuccess = (clientSecret: string, paymentIntentId: string): StripeActions => ({
  type: StripeActionTypes.CREATE_PAYMENT_INTENT_SUCCESS,
  payload: { clientSecret, paymentIntentId },
});

export const cancelStripeSubscription = (): StripeActions => ({
  type: StripeActionTypes.CANCEL_STRIPE_SUBSCRIPTION,
  payload: {},
});

export const changeStripeSubscription = (product: Product): StripeActions => ({
  type: StripeActionTypes.CHANGE_STRIPE_SUBSCRIPTION,
  payload: { product },
});

export const createSubscriptionWithExistingPaymentMethod = (product: Product): StripeActions => ({
  type: StripeActionTypes.CREATE_SUBSCRIPTION_WITH_EXISTING_PAYMENT_METHOD,
  payload: { product },
});

export const getStripeCustomerData = (): StripeActions => ({
  type: StripeActionTypes.GET_STRIPE_CUSTOMER_DATA,
  payload: {},
});

export const getStripeCustomerDataSuccess = (
  subscription: UserSubscription | null,
  paymentMethod: null | UserPaymentMethod
): StripeActions => ({
  type: StripeActionTypes.GET_STRIPE_CUSTOMER_DATA_SUCCESS,
  payload: { subscription, paymentMethod },
});

export const setSubscription = (subscription: UserSubscription | null): StripeActions => ({
  type: StripeActionTypes.SET_STRIPE_SUBSCRIPTION,
  payload: { subscription },
});

export const setPaymentMethod = (paymentMethod: null | UserPaymentMethod): StripeActions => ({
  type: StripeActionTypes.SET_PAYMENT_METHOD,
  payload: { paymentMethod },
});
