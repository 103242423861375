import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "screens/Payment/Payment.module.css";
import "./styles.css";
import {
  AccountForm,
  AvatarAmo,
  CheckoutForm,
  FlexDiv,
  MainLayout,
  PaymentFooter,
  PrimaryButton,
  TextInput,
} from "components";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { FacebookPixelEvent, MixpanelEvent, PaymentType, Routes } from "models/enums";
import { useIsDesktop } from "utils/hooks/useIsDesktop";
import { useDispatch, useSelector } from "react-redux";
import { setTempUserToken, setUserToken } from "store/user/userActions";
import { activeProductSelector, productsSelector } from "store/products/productSelectors";
import { useAuthenticationForm } from "utils/hooks/useAuthenticationForm";
import { tempUserTokenSelector, userTokenSelector } from "store/user/userSelectors";
import { useCardForm } from "utils/hooks/useCardForm";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";
import { useGoogleApplePay } from "utils/hooks/useGoogleApplePay";
import { useProcessStripePayment } from "utils/hooks/useProcessStripePayment";
import ApiService, { paypalRequests, stripeRequests } from "services/api";
import { OnClickActions } from "@paypal/paypal-js/types/components/buttons";
import { usePaypalPayment } from "utils/hooks/usePaypalPayment";
import { selectedCoachSelector } from "store/coach/coachSelectors";
import { useAuthentication } from "utils/hooks/useAuthentication";
import { CSSTransition } from "react-transition-group";
import CardSecurePaymentHeader from "components/CardSecurePaymentHeader";
import { useMount } from "react-use";
import { setActiveProduct } from "store/products/productActions";
import { checkIfLoadingSelector } from "store/ui/uiSelectors";
import { ProductActionTypes } from "models/store/actionTypes";
import Loader from "components/Loader";
import { TransKey } from "utils/i18n/allTranslationsTyped";
import { useCoachFromParams } from "utils/hooks/useCoachFromParams";
import { Coupon, PaymentCheckoutState, PaymentParams } from "models/interfaces";
import mixpanel from "mixpanel-browser";
import PromoCodeForm from "components/PromoCodeForm";
import { addDays, format } from "date-fns";
import { DATE_FORMAT_1, FREE_TRIAL_DAYS } from "models/constants";
import { getCouponFromUrl, localizePlanDuration, roundTo2Decimals } from "utils/helpers";
import ReactPixel from "react-facebook-pixel";

const Payment = () => {
  const isDesktop = useIsDesktop();
  const location = useLocation();
  const { t } = useTypedTranslation();
  const product = useSelector(activeProductSelector);
  const products = useSelector(productsSelector);
  const token = useSelector(userTokenSelector);
  const tempToken = useSelector(tempUserTokenSelector);
  const selectedCoach = useSelector(selectedCoachSelector);
  const coachName = selectedCoach?.name;
  const dispatch = useDispatch();
  const history = useHistory();
  useCoachFromParams();
  const { planInterval, login } = useParams() as PaymentParams;
  const [couponCode, setCouponCode] = useState("");
  const [coupon, setCoupon] = useState<Coupon | undefined>();
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  const [couponError, setCouponError] = useState<string | undefined>(undefined);
  const [priceToPay, setPriceToPay] = useState({ priceToShow: product?.price || 0, actualPrice: product?.price || 0 });
  const [freeTrialUsed, setFreeTrialUsed] = useState(false);
  const [isLoadingFreeTrialStatus, setIsLoadingFreeTrialStatus] = useState(true);
  const [alreadyHaveAnAccount, setAlreadyHaveAnAccount] = useState(login === "login");
  const { accountForm, onForgotPasswordClick, onAccountFormValueChange, onAccountFormFocusChange, isAccountFormValid } =
    useAuthenticationForm(alreadyHaveAnAccount);
  const { onCardFormFocusChange, onCardFormChange, cardForm, isCardFormValid } = useCardForm();
  const { googleApplePaymentRequest, canMakePayment } = useGoogleApplePay(accountForm.email.value, isAccountFormValid);
  const { processStripePayment, isProcessingPayment } = useProcessStripePayment(onTransactionDone);
  const { isLoading, onRegisterClick, onLoginClick } = useAuthentication(accountForm, false);
  const rerenderPaypal = useMemo(
    () => Date.now(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accountForm.email.value, accountForm.password.value, accountForm.name.value, alreadyHaveAnAccount]
  );
  const { onPaypalCreateSubscription, onPaypalError, onPaypalCancel, onPaypalApprove } = usePaypalPayment();
  const isLoadingPlans = useSelector(checkIfLoadingSelector)([ProductActionTypes.GET_PRODUCTS]);
  const planDuration = t(product?.duration as TransKey);
  const trialEndDate = format(addDays(new Date(), FREE_TRIAL_DAYS), DATE_FORMAT_1);

  useMount(() => {
    if (token) {
      history.replace(Routes.ACCOUNT_PLANS_PAYMENT);
      return;
    }
    if (tempToken) {
      dispatch(setTempUserToken(undefined));
      return;
    }
    return () => {
      dispatch(setUserToken(undefined));
    };
  });

  useEffect(() => {
    const couponFromUrl = getCouponFromUrl(window.location.href);
    if (product && couponFromUrl) {
      setCouponCode(couponFromUrl);
      onCouponSubmit(couponFromUrl);
    }
  }, [product]);

  useEffect(() => {
    const productFromPathname =
      products.length > 0
        ? products.find((p) => p.productType.toLowerCase() === planInterval) || products[products.length - 1]
        : undefined;
    if (productFromPathname) {
      ReactPixel.track(FacebookPixelEvent.Lead, { product: productFromPathname });
      dispatch(setActiveProduct(productFromPathname));
    }
  }, [dispatch, location.pathname, products]);

  useEffect(() => {
    if (product && coupon?.percent_off) {
      setPriceToPay({
        priceToShow: roundTo2Decimals(product.price - (product.price * ((coupon?.percent_off || 0) / 100) || 1)),
        actualPrice: product.price - (product.price * ((coupon?.percent_off || 0) / 100) || 1),
      });
    }
  }, [product, coupon]);

  useEffect(() => {
    if (tempToken) {
      (async () => {
        try {
          setIsLoadingFreeTrialStatus(true);
          const response = await ApiService.callApi(stripeRequests.getCustomer());
          setFreeTrialUsed(response.data.freeTrialUsed);
        } catch (e) {
          console.log("load customer error", e);
        } finally {
          setIsLoadingFreeTrialStatus(false);
        }
      })();
    }
  }, [tempToken]);

  function onTransactionDone(success: boolean, email?: string) {
    const state: PaymentCheckoutState = {
      success,
      email,
      isFreeTrial: !freeTrialUsed,
      duration: planDuration,
      trialEndDate: trialEndDate,
      priceToPay: priceToPay.priceToShow,
    };
    history.push(Routes.PAYMENT_CHECKOUT, state);
  }

  const onPayClick = (paymentType: PaymentType) => () => {
    try {
      if (paymentType === PaymentType.STRIPE) {
        processStripePayment(accountForm.email.value, coupon?.id);
        mixpanel.track(MixpanelEvent.PAYMENT_PLAN_PAY, { method: "stripe" });
        ReactPixel.track(FacebookPixelEvent.Purchase, { method: "stripe" });
      } else if (paymentType === PaymentType.GOOGLE_APPLE) {
        mixpanel.track(MixpanelEvent.PAYMENT_PLAN_PAY, { method: "google/apple pay" });
        ReactPixel.track(FacebookPixelEvent.Purchase, { method: "google/apple pay" });
        googleApplePaymentRequest?.show();
      }
    } catch (error) {
      console.log("onPayClick error", error);
    }
  };

  const onHaveAccountClick = () => {
    setAlreadyHaveAnAccount((prevState) => !prevState);
  };

  async function onPayPalClick(data: Record<string, unknown>, actions: OnClickActions) {
    try {
      mixpanel.track(MixpanelEvent.PAYMENT_PLAN_PAY, { method: "paypal" });
      await ApiService.callApi(paypalRequests.createCustomer());
      return actions.resolve();
    } catch (error) {
      console.log("onPayPalClick error", error);
      return actions.reject();
    }
  }

  async function onCouponSubmit(defaultCoupon?: string) {
    try {
      if (!selectedCoach?.id || !product?.productType) {
        return;
      }
      const coupon = typeof defaultCoupon === "string" ? defaultCoupon : couponCode;
      setIsCouponLoading(true);
      const response = await ApiService.callApi(
        stripeRequests.getCoupon(coupon, selectedCoach?.id, product.productType.toLowerCase())
      );
      if (response.data.coupon) {
        setCoupon(response.data.coupon);
        setCouponError(undefined);
      } else {
        setCouponError(t("promoCodeNotValid"));
      }
    } catch (e) {
      console.log("onCouponSubmit error", e);
    } finally {
      setIsCouponLoading(false);
    }
  }

  function onRemoveCouponCode() {
    setCouponCode("");
    setCoupon(undefined);
    setCouponError(undefined);
    setPriceToPay({ priceToShow: product?.price || 0, actualPrice: product?.price || 0 });
  }

  if (isLoadingPlans || !product) {
    return <Loader className={styles.loader} />;
  }

  const isPayDisabled = !isAccountFormValid || !isCardFormValid;
  const isLoggedOrRegistered = !!tempToken && !isLoadingFreeTrialStatus;
  return (
    <MainLayout
      leftSide={
        <AvatarAmo
          planType={product.planType}
          useDesktopDimensions={isDesktop}
          price={priceToPay.priceToShow}
          freeTrialUsed={freeTrialUsed}
          priceUnit={product.priceUnit}
          duration={localizePlanDuration(product.duration)}
          coachName={coachName}
        />
      }
      rightSide={
        <FlexDiv className={styles.formContainer}>
          {!isLoggedOrRegistered ? (
            <>
              <AccountForm
                name={accountForm.name.value}
                nameErrorMessage={accountForm.name.errorMsg}
                onNameFocusChange={onAccountFormFocusChange("name")}
                onNameChange={onAccountFormValueChange("name")}
                email={accountForm.email.value}
                onEmailChange={onAccountFormValueChange("email")}
                emailErrorMsg={accountForm.email.errorMsg}
                onEmailFocusChange={onAccountFormFocusChange("email")}
                password={accountForm.password.value}
                onPasswordFocusChange={onAccountFormFocusChange("password")}
                passwordErrorMsg={accountForm.password.errorMsg}
                onPasswordChange={onAccountFormValueChange("password")}
                onForgotPasswordClick={onForgotPasswordClick}
                onHaveAccountClick={onHaveAccountClick}
                className={styles.accountForm}
                isLogin={alreadyHaveAnAccount}
                errorMsg={accountForm.formError}
              />
              <PrimaryButton
                isLoading={isLoading}
                disabled={!isAccountFormValid}
                buttonText={t(alreadyHaveAnAccount ? "login" : "register")}
                className={styles.login}
                onClick={alreadyHaveAnAccount ? onLoginClick : onRegisterClick}
              />
            </>
          ) : (
            <TextInput value={accountForm.email.value} placeholder={t("email")} type={"email"} completed={true} />
          )}
          <FlexDiv className={styles.checkoutContainer}>
            <CardSecurePaymentHeader title={t("checkout")} sectionIndex={2} disabled={!isLoggedOrRegistered} />
            <CSSTransition in={isLoggedOrRegistered} timeout={300} classNames={"alert"} unmountOnExit={true}>
              <>
                <PromoCodeForm
                  freeTrialUsed={freeTrialUsed}
                  priceToPay={priceToPay.priceToShow}
                  onCouponSubmit={onCouponSubmit}
                  validCouponCode={coupon?.name || undefined}
                  couponCode={couponCode}
                  amountOff={roundTo2Decimals(product.price - priceToPay.actualPrice)}
                  percentOff={coupon?.percent_off || undefined}
                  onCouponCodeChange={setCouponCode}
                  isCouponLoading={isCouponLoading}
                  onRemoveCouponCode={onRemoveCouponCode}
                  couponError={couponError}
                />
                <CheckoutForm
                  onPaypalCancel={onPaypalCancel}
                  onPayPalError={onPaypalError}
                  onPaypalApprove={onPaypalApprove}
                  onPaypalCreateSubscription={onPaypalCreateSubscription}
                  rerenderPaypal={rerenderPaypal}
                  onPayPalClick={onPayPalClick}
                  googleApplePayDisabled={!isAccountFormValid}
                  onGoogleApplePayClick={onPayClick(PaymentType.GOOGLE_APPLE)}
                  canMakePayment={canMakePayment}
                  onCardNumberFocusChange={onCardFormFocusChange("cardNumber")}
                  onCardCVCFocusChange={onCardFormFocusChange("cardCVC")}
                  onCardExpirationDateFocusChange={onCardFormFocusChange("cardExpirationDate")}
                  cardNumberError={cardForm.cardNumber.errorMsg}
                  cardNumberFocused={cardForm.cardNumber.isFocused}
                  cardCVCError={cardForm.cardCVC.errorMsg}
                  cardCVCFocused={cardForm.cardCVC.isFocused}
                  cardExpirationDateError={cardForm.cardExpirationDate.errorMsg}
                  cardExpirationDateFocused={cardForm.cardExpirationDate.isFocused}
                  onCardExpirationDateChange={onCardFormChange("cardExpirationDate")}
                  onCardCVCChange={onCardFormChange("cardCVC")}
                  onCardNumberChange={onCardFormChange("cardNumber")}
                />
                <PrimaryButton
                  isLoading={isProcessingPayment}
                  onClick={onPayClick(PaymentType.STRIPE)}
                  disabled={isPayDisabled}
                  buttonText={`${t(freeTrialUsed ? "pay" : "startTrial")}${
                    freeTrialUsed ? ` R$ ${priceToPay.priceToShow}` : ""
                  }`}
                  className={styles.payButton}
                />
                <PaymentFooter
                  amountToCharge={priceToPay.priceToShow}
                  duration={planDuration}
                  isFreeTrial={!freeTrialUsed}
                  trialEndDate={freeTrialUsed ? undefined : trialEndDate}
                />
              </>
            </CSSTransition>
          </FlexDiv>
        </FlexDiv>
      }
    />
  );
};

export default React.memo(Payment);
