import React, { FormEvent, HTMLProps, useState } from "react";
import styles from "components/TextInput/TextInput.module.css";
import classNames from "classnames";
import { FlexDiv } from "components";
import { GREY_DARK, RED, VIOLET } from "styles/colors";
import { Icons } from "models/enums";

interface TextInputProps {
  hasError?: boolean;
  onValueChange?: (newValue: string) => void;
  value: string;
  placeholder: string;
  className?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  completed?: boolean;
}

const TextInput: React.FC<TextInputProps & HTMLProps<HTMLInputElement>> = ({
  hasError,
  value,
  onValueChange,
  placeholder,
  className,
  onFocus,
  onBlur,
  completed,
  ...restProps
}) => {
  const [isFocused, setIsFocused] = useState(false);

  function onFocusChange(focused: boolean) {
    setIsFocused(focused);
    if (focused && onFocus) {
      onFocus();
    } else if (!focused && onBlur) {
      onBlur();
    }
  }

  function onInput(e: FormEvent<HTMLInputElement>) {
    if (onValueChange) {
      onValueChange((e.target as HTMLInputElement).value);
    }
  }

  return (
    <FlexDiv className={classNames(styles.container, className)}>
      <input
        type={"text"}
        value={value}
        placeholder={placeholder}
        disabled={completed}
        className={classNames(
          styles.input,
          completed ? styles.inputCompleted : "",
          hasError ? styles.inputError : "",
          isFocused && !hasError ? styles.inputFocus : ""
        )}
        onInput={onInput}
        onBlur={() => onFocusChange(false)}
        onFocus={() => onFocusChange(true)}
        {...restProps}
      />
      {completed && <img src={Icons.CHECKMARK} className={styles.checkMark} />}
      <label className={styles.label} style={{ color: hasError ? RED : !isFocused && !!value ? GREY_DARK : "" }}>
        {placeholder}
      </label>
    </FlexDiv>
  );
};

export default React.memo(TextInput);
