import React from "react";
import styles from "components/AccountCard/AccountCard.module.css";
import FlexDiv from "components/FlexDiv";
import classNames from "classnames";
import { AccountCardButton } from "models/interfaces";
import SecondaryButton from "components/SecondaryButton";
import Loader from "components/Loader";
import PrimaryButton from "components/PrimaryButton";
import { useIsDesktop } from "utils/hooks/useIsDesktop";
import CustomPaypalButton from "components/CustomPaypalButton";

export interface AccountCardProps {
  title: string;
  className?: string;
  buttons?: AccountCardButton[];
  children: React.ReactNode;
  isLoading?: boolean;
}

const AccountCard: React.FC<AccountCardProps> = ({ title, buttons = [], className, children, isLoading }) => {
  const isDesktop = useIsDesktop();
  return (
    <FlexDiv className={classNames(styles.container, className)}>
      <FlexDiv className={styles.titleContainer}>
        <p className={styles.title}>{title}</p>
      </FlexDiv>
      <FlexDiv className={styles.contentContainer}>{children}</FlexDiv>
      {isLoading && <Loader className={styles.loader} />}
      {!isLoading && buttons?.length > 0 && (
        <FlexDiv className={styles.buttonContainer}>
          {(isDesktop ? buttons : buttons?.slice(0)?.reverse()).map((button, index) => {
            if (button.renderAsPaypalButton) {
              return (
                <CustomPaypalButton
                  key={button.text}
                  wrapper={
                    <PrimaryButton
                      key={button.text}
                      buttonText={button.text}
                      className={
                        index !== 0 ? classNames(styles.primaryButton, styles.buttonSeparator) : styles.primaryButton
                      }
                      onClick={button.onClick}
                      disabled={button.disabled}
                    />
                  }
                />
              );
            }
            return button.theme === "PRIMARY" ? (
              <PrimaryButton
                key={button.text}
                buttonText={button.text}
                className={
                  index !== 0 ? classNames(styles.primaryButton, styles.buttonSeparator) : styles.primaryButton
                }
                onClick={button.onClick}
                disabled={button.disabled}
              />
            ) : (
              <SecondaryButton
                key={button.text}
                className={index !== 0 ? styles.buttonSeparator : ""}
                buttonText={button.text}
                theme={button.theme}
                onClick={button.onClick}
                disabled={button.disabled}
              />
            );
          })}
        </FlexDiv>
      )}
    </FlexDiv>
  );
};

export default React.memo(AccountCard);
