import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { ApiRequest } from "models/interfaces";
import { tempUserTokenSelector, userTokenSelector } from "store/user/userSelectors";
import store from "store/configureStore";
const CancelToken = axios.CancelToken;

const defaultOptions: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
  },
  validateStatus(status: number) {
    return [200, 201].includes(status); // Accept only status code 2xx
  },
  timeout: 15000,
};

/**
 * Calls api and returns response data if status OK.
 * setTimeout needed for android to cancel request, if you don't have internet connection or the IP address or
 * domain name that you're requesting not there,in this case axios timeout will not work.
 * @param {string} url The api endpoint.
 * @param {object} options The request options.
 * @returns {Promise<AxiosResponse<any>>}
 */
async function callApi<T>({
  url,
  options,
  includeAuthorizationHeader = true,
}: ApiRequest<T>): Promise<{ data: T; status: number }> {
  let source = CancelToken.source();
  let fetchOptions: AxiosRequestConfig = { ...defaultOptions, ...options, cancelToken: source.token };

  if (includeAuthorizationHeader) {
    const accessToken = userTokenSelector(store.getState()) || tempUserTokenSelector(store.getState());
    fetchOptions = {
      ...fetchOptions,
      headers: { ...fetchOptions.headers, Authorization: `Bearer ${accessToken}` },
    };
  }
  const response = (await axios(url, fetchOptions)) as AxiosResponse<{ data: T; status: number }>;
  return { data: response.data.data, status: response.status };
}

const ApiService = { callApi };

export default ApiService;
export { stripeRequests } from "services/api/stripe";
export { userRequests } from "services/api/user";
export { paypalRequests } from "services/api/paypal";
export { coachRequests } from "services/api/coach";
