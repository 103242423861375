import React, { ButtonHTMLAttributes } from "react";
import styles from "components/PrimaryButton/PrimaryButton.module.css";
import classNames from "classnames";
import Loader from "components/Loader";
import { Icons } from "models/enums";
import FlexDiv from "components/FlexDiv";

type PrimaryButtonRequiredProps = {
  buttonIcon: string;
  buttonText: string;
};

interface PrimaryButtonProps {
  className?: string;
  isLoading?: boolean;
  withRightCircle?: boolean;
}

const PrimaryButton: React.FC<
  Partial<PrimaryButtonRequiredProps> & PrimaryButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ buttonText, buttonIcon, className, isLoading, withRightCircle = true, ...restProps }) => {
  if (isLoading) {
    return <Loader className={className} />;
  }
  return (
    <button className={classNames(styles.button, className)} {...restProps}>
      {buttonIcon ? (
        <img src={buttonIcon} className={styles.buttonIcon} alt={"Button Icon"} />
      ) : (
        <p className={styles.buttonText}>{buttonText}</p>
      )}
      {withRightCircle && (
        <FlexDiv className={styles.circleIcon}>
          <img src={Icons.CHEVRON_RIGHT} className={styles.chevron} />
        </FlexDiv>
      )}
    </button>
  );
};

export default React.memo(PrimaryButton);
