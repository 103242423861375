import React from "react";
import { useSelector } from "react-redux";
import { userAvatarSelector } from "store/user/userSelectors";
import { Images } from "models/enums";
import classNames from "classnames";
import styles from "components/UserAvatar/UserAvatar.module.css";

interface UserAvatarProps {
  className?: string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ className }) => {
  const userAvatar = useSelector(userAvatarSelector);
  return <img src={userAvatar || Images.DEFAULT_AVATAR} className={classNames(styles.avatar, className)} />;
};

export default React.memo(UserAvatar);
