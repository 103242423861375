import React from "react";
import styles from "components/DownloadAmoButton/DownloadAmoButton.module.css";
import { appStoreLink, googlePlayStoreLink } from "models/data";
import { Images } from "models/enums";

interface DownloadAmoButtonProps {
  type: "GOOGLE" | "APPLE";
  className?: string;
  onClick?: () => void;
}

const DownloadAmoButton: React.FC<DownloadAmoButtonProps> = ({ type, onClick, className }) => {
  if (type === "GOOGLE") {
    return (
      <a href={googlePlayStoreLink} className={className} onClick={onClick}>
        <img src={Images.GOOGLE_PLAY_BADGE} className={styles.badge} />
      </a>
    );
  }
  return (
    <a href={appStoreLink} className={className} onClick={onClick}>
      <img src={Images.APP_STORE_BADGE} className={styles.badge} />
    </a>
  );
};

export default React.memo(DownloadAmoButton);
