import React, { useMemo } from "react";
import styles from "screens/Account/AvailablePlans/AvailablePlans.module.css";
import { AccountTitle, FlexDiv, PlanOverview, AvailablePlansCard } from "components";
import { useDispatch, useSelector } from "react-redux";
import { productsSelector } from "store/products/productSelectors";
import { useHistory } from "react-router-dom";
import { Routes } from "models/enums";
import { Product } from "models/interfaces";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";
import { hasSubscription } from "utils/helpers";
import { setActiveProduct } from "store/products/productActions";
import { userSubscriptionSelector } from "store/user/userSelectors";

interface AvailablePlansProps {}

const AvailablePlans: React.FC<AvailablePlansProps> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTypedTranslation();
  const subscription = useSelector(userSubscriptionSelector);
  const products = useSelector(productsSelector);
  const availablePlans = useMemo(() => {
    if (!subscription || !hasSubscription(subscription?.status)) {
      return products;
    }
    return products.filter((p) => p.id !== subscription.product.id);
  }, [products, subscription]);

  const onPaymentUpdateClick = () => {
    history.push(Routes.ACCOUNT_PLANS_PAYMENT);
  };
  const onCancelPlanClick = () => {
    history.push(Routes.ACCOUNT_PLANS_CANCEL);
  };

  function onSelectPlanClick(product: Product) {
    dispatch(setActiveProduct(product));
    history.push(Routes.ACCOUNT_PLANS_CHANGE);
  }

  return (
    <>
      <AccountTitle sectionTitle={t("availablePlans")} />
      {hasSubscription(subscription?.status) && (
        <PlanOverview
          showPriceDuration={true}
          subscription={subscription}
          cardTitle={t("currentPlan")}
          cardButtons={[{ text: t("cancelPlan"), onClick: onCancelPlanClick, theme: "LIGHT" }]}
          onPaymentUpdateClick={onPaymentUpdateClick}
        />
      )}
      <FlexDiv className={hasSubscription(subscription?.status) ? styles.plansCardsWrapper : ""}>
        <AvailablePlansCard
          cardTitle={t("availablePlans")}
          availablePlans={availablePlans}
          onSelectPlanClick={onSelectPlanClick}
        />
      </FlexDiv>
    </>
  );
};

export default React.memo(AvailablePlans);
