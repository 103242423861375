import { call, takeLeading, put } from "typed-redux-saga";
import { CoachActionTypes } from "models/store/actionTypes";
import ApiService, { coachRequests } from "services/api";
import { getAllCoachesSuccess } from "store/coach/coachActions";

function* getAllCoachesSaga() {
  try {
    const response = yield* call(() => ApiService.callApi(coachRequests.getAllCoaches()));
    if (response.data.coaches.length) {
      yield put(getAllCoachesSuccess(response.data.coaches));
    }
  } catch (error) {
    console.log("getAllCoachesSaga error", error);
  }
}

export function* watchGetAllCoachesSaga() {
  yield takeLeading(CoachActionTypes.GET_ALL_COACHES, getAllCoachesSaga);
}
