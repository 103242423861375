import React from "react";
import styles from "components/PaymentFooter/PaymentFooter.module.css";
import { Images } from "models/enums";
import FlexDiv from "components/FlexDiv";
import PrivacyPolicy from "components/PrivacyPolicy";
import classNames from "classnames";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";
import { format } from "date-fns";
import { DATE_FORMAT_1 } from "models/constants";

interface PaymentFooterProps {
  trialEndDate?: string;
  isFreeTrial?: boolean;
  amountToCharge?: number;
  duration?: string;
  className?: string;
}

const PaymentFooter: React.FC<PaymentFooterProps> = ({
  className,
  amountToCharge,
  duration,
  trialEndDate,
  isFreeTrial,
}) => {
  const { t } = useTypedTranslation();

  return (
    <FlexDiv className={classNames(styles.container, className)}>
      {isFreeTrial && trialEndDate && amountToCharge && duration && (
        <p className={styles.trialText}>
          {t("afterTrialEndsYouWillBeCharged", { amountToCharge, duration, trialEndDate })}
        </p>
      )}
      {!isFreeTrial && (
        <p className={styles.trialText}>
          {t("youWillBeCharged", { amountToCharge, duration, firstChargeDate: format(new Date(), DATE_FORMAT_1) })}
        </p>
      )}
      <img src={Images.POWERED_BY_STRIPE} className={styles.poweredByStripe} alt={"stripe"} />
      <PrivacyPolicy />
    </FlexDiv>
  );
};

export default React.memo(PaymentFooter);
