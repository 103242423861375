import { ApiRequest, Coach } from "models/interfaces";

const prefix = "";

const getAllCoaches = (): ApiRequest<{ coaches: Coach[] }> => ({
  url: `${prefix}/coaches`,
  options: { method: "GET" },
  includeAuthorizationHeader: false,
});

export const coachRequests = {
  getAllCoaches,
};
