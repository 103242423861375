import { StripeActions } from "models/store/stripeActions";
import { StripeActionTypes } from "models/store/actionTypes";
import { UserPaymentMethod, UserSubscription } from "models/interfaces";

export interface StripeState {
  clientSecret?: string;
  paymentIntentId?: string;
  subscription: null | UserSubscription;
  paymentMethod: null | UserPaymentMethod;
}

const initialState: StripeState = {
  clientSecret: undefined,
  paymentIntentId: undefined,
  subscription: null,
  paymentMethod: null,
};

const stripeReducer = (state = initialState, action: StripeActions): StripeState => {
  switch (action.type) {
    case StripeActionTypes.CREATE_PAYMENT_INTENT_SUCCESS:
      return { ...state, clientSecret: action.payload.clientSecret, paymentIntentId: action.payload.paymentIntentId };
    case StripeActionTypes.GET_STRIPE_CUSTOMER_DATA_SUCCESS:
      return { ...state, subscription: action.payload.subscription, paymentMethod: action.payload.paymentMethod };
    case StripeActionTypes.SET_STRIPE_SUBSCRIPTION:
      return { ...state, subscription: action.payload.subscription };
    case StripeActionTypes.SET_PAYMENT_METHOD:
      return { ...state, paymentMethod: action.payload.paymentMethod };
    default:
      return state;
  }
};

export default stripeReducer;
