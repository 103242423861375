import { useDispatch, useSelector } from "react-redux";
import { coachesSelector, defaultCoachSelector } from "store/coach/coachSelectors";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Coaches } from "models/enums";
import { selectCoach } from "store/coach/coachActions";
import { PaymentParams } from "models/interfaces";

export function useCoachFromParams() {
  const dispatch = useDispatch();
  const coaches = useSelector(coachesSelector);
  const defaultCoach = useSelector(defaultCoachSelector);
  const [selectedCoachName, setSelectedCoachName] = useState("");
  const { coachName } = useParams() as PaymentParams;

  useEffect(() => {
    let coach = coaches.find(
      (c) => c.name.toLowerCase() === coachName?.toLowerCase() && c.name.toLowerCase() !== Coaches.JAMES.toLowerCase()
    );
    if (!coach) {
      coach = defaultCoach;
    }
    if (coach) {
      setSelectedCoachName(coach.name.toLowerCase());
      dispatch(selectCoach(coach));
    }
  }, [coaches, defaultCoach]);

  return { coachName: selectedCoachName };
}
