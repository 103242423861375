import { all } from "redux-saga/effects";
import {
  watchCreatePaymentIntentSaga,
  watchCancelSubscriptionSaga,
  watchChangeSubscriptionSaga,
  watchCreateSubscriptionWithExistingPaymentMethodSaga,
  watchGetStripeCustomerDataSaga,
} from "store/stripe/stripeSaga";
import { watchGetProductsSaga } from "store/products/productSagas";
import {
  watchCancelPaypalSubscriptionSaga,
  watchGetPayPalSubscriptionSaga,
  watchUpdatePaypalSubscriptionSaga,
  watchGetPaypalCustomerSaga,
} from "store/paypal/paypalSaga";
import { watchGetUserInfoSaga, watchLoginSaga, watchRegisterSaga } from "store/user/userSagas";
import { watchGetAllCoachesSaga } from "store/coach/coachSaga";

export default function* rootSaga() {
  // prettier-ignore
  yield all([
        // STRIPE
        watchCreatePaymentIntentSaga(), watchCancelSubscriptionSaga(), watchChangeSubscriptionSaga(),
        watchCreateSubscriptionWithExistingPaymentMethodSaga(), watchGetStripeCustomerDataSaga(),
        // PRODUCTS
        watchGetProductsSaga(),
        // COACHES
        watchGetAllCoachesSaga(),
        // USER
        watchGetUserInfoSaga(), watchLoginSaga(), watchRegisterSaga(),
        // PAY PAL
        watchCancelPaypalSubscriptionSaga(), watchGetPayPalSubscriptionSaga(), watchUpdatePaypalSubscriptionSaga(),
        watchGetPaypalCustomerSaga()
  ]);
}
