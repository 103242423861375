import { UserActions } from "models/store/userActionts";
import { UserActionTypes } from "models/store/actionTypes";
import { UserInfo } from "models/interfaces";

export const setUserToken = (token: string | undefined): UserActions => ({
  type: UserActionTypes.SET_USER_TOKEN,
  payload: { token },
});

export const setTempUserToken = (tempToken: string | undefined): UserActions => ({
  type: UserActionTypes.SET_TEMP_USER_TOKEN,
  payload: { tempToken },
});

export const getUserInfo = (): UserActions => ({
  type: UserActionTypes.GET_USER_INFO,
  payload: {},
});

export const getUserInfoSuccess = (userInfo: UserInfo): UserActions => ({
  type: UserActionTypes.GET_USER_INFO_SUCCESS,
  payload: { userInfo },
});

export const logout = (): UserActions => ({
  type: UserActionTypes.LOGOUT,
  payload: {},
});

export const login = (email: string, password: string, isSaveToStore: boolean): UserActions => ({
  type: UserActionTypes.LOGIN,
  payload: { email, password, isSaveToStore },
});

export const register = (email: string, password: string, name: string, isSaveToStore: boolean): UserActions => ({
  type: UserActionTypes.REGISTER,
  payload: { name, email, password, isSaveToStore },
});
