import ApiService, { stripeRequests } from "services/api";
import { call, put, takeLeading } from "typed-redux-saga";
import { ProductActionTypes } from "models/store/actionTypes";
import { getProductsSuccess } from "store/products/productActions";
import { startAction, stopAction } from "store/ui/uiActions";
import { GetProductsAction } from "models/store/productActions";

function* getProductsSaga(action: GetProductsAction) {
  try {
    yield put(startAction({ name: action.type }));
    const response = yield* call(() => ApiService.callApi(stripeRequests.getProducts()));
    yield put(
      getProductsSuccess(
        response.data.prices.sort((a, b) => {
          if (!a.unit_amount || !b.unit_amount) {
            return 1;
          }
          return b.unit_amount - a.unit_amount;
        })
      )
    );
  } catch (error) {
    console.log("getProductsSaga error", error);
  } finally {
    yield put(stopAction({ name: action.type }));
  }
}
export function* watchGetProductsSaga() {
  yield takeLeading(ProductActionTypes.GET_PRODUCTS, getProductsSaga);
}
