import React, { FormEvent, HTMLProps, useState } from "react";
import styles from "components/PromoCodeInput/PromoCodeInput.module.css";
import classNames from "classnames";
import { FlexDiv } from "components";
import { GREY_DARK, RED } from "styles/colors";
import Loader from "components/Loader/Loader";

interface PromoCodeInputProps {
  hasError?: boolean;
  onValueChange?: (newValue: string) => void;
  value: string;
  isLoading: boolean;
  onSubmit: () => void;
  placeholder: string;
  className?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  completed?: boolean;
}

const PromoCodeInput: React.FC<PromoCodeInputProps & HTMLProps<HTMLInputElement>> = ({
  hasError,
  value,
  onValueChange,
  placeholder,
  className,
  onFocus,
  onBlur,
  completed,
  onSubmit,
  isLoading,
  ...restProps
}) => {
  const [isFocused, setIsFocused] = useState(false);

  function onFocusChange(focused: boolean) {
    setIsFocused(focused);
    if (focused && onFocus) {
      onFocus();
    } else if (!focused && onBlur) {
      onBlur();
    }
  }

  function onInput(e: FormEvent<HTMLInputElement>) {
    if (onValueChange) {
      onValueChange((e.target as HTMLInputElement).value);
    }
  }

  return (
    <FlexDiv
      className={classNames(
        styles.container,
        isFocused && !hasError ? styles.inputFocus : "",
        hasError ? styles.inputError : "",
        className
      )}
    >
      <input
        type={"text"}
        value={value}
        placeholder={placeholder}
        disabled={completed}
        className={classNames(styles.input, completed ? styles.inputCompleted : "")}
        onInput={onInput}
        onBlur={() => onFocusChange(false)}
        onFocus={() => onFocusChange(true)}
        {...restProps}
      />
      <label className={styles.label} style={{ color: hasError ? RED : !isFocused && !!value ? GREY_DARK : "" }}>
        {placeholder}
      </label>
      <div>{isLoading && <Loader size={20} className={styles.loader} />}</div>
      {!isLoading && value && <input type="button" value={"Apply"} className={styles.button} onClick={onSubmit} />}
    </FlexDiv>
  );
};

export default React.memo(PromoCodeInput);
