import React from "react";
import styles from "components/FormTitle/FormTitle.module.css";
import { FlexDiv } from "components";
import classNames from "classnames";

interface FormTitleProps {
  title: string;
  sectionIndex?: number;
  disabled?: boolean;
}

const FormTitle: React.FC<FormTitleProps> = ({ title, sectionIndex, disabled }) => {
  return (
    <FlexDiv className={styles.container}>
      {sectionIndex !== undefined && (
        <FlexDiv className={classNames(styles.circle, disabled ? styles.disabledCircle : "")}>
          <p className={classNames(styles.sectionIndex, disabled ? styles.disabled : "")}>{sectionIndex}</p>
        </FlexDiv>
      )}
      <p className={classNames(styles.title, disabled ? styles.disabled : "")}>{title}</p>
    </FlexDiv>
  );
};

export default React.memo(FormTitle);
