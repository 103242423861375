import ApiService, { paypalRequests } from "services/api";
import { call, put, select, takeLeading } from "typed-redux-saga";
import { PaypalActionTypes } from "models/store/actionTypes";
import { activeProductSelector, productsSelector } from "store/products/productSelectors";
import { getPaypalCustomerDataSuccess, setPaypalSubscription } from "store/paypal/paypalActions";
import { Product, SubscriptionType, UserSubscription } from "models/interfaces";
import {
  getPaypalSubscriptionIdByStripeProduct,
  getStripeProductByPaypalId,
  getStripeStatusFromPaypalStatus,
} from "utils/helpers";
import { startAction, stopAction } from "store/ui/uiActions";
import {
  CancelPaypalSubscriptionAction,
  GetPayPalSubscription,
  UpdatePaypalSubscriptionAction,
} from "models/store/paypalActions";
import { SubscriptionStatus } from "models/enums";
import { paypalFreeTrialUsedSelector, paypalSubscriptionSelector } from "store/paypal/paypalSelectors";
import { GetStripeCustomerDataAction } from "models/store/stripeActions";

function* getPayPalSubscriptionSaga(action: GetPayPalSubscription) {
  try {
    yield put(startAction({ name: action.type }));
    const products = yield* select(productsSelector);
    if (!products.length) {
      return;
    }
    const response = yield* call(() => ApiService.callApi(paypalRequests.getPaypalSubscription()));
    const { subscription, nextBillDateUnix } = response.data;
    let userSubscription: UserSubscription | null = null;
    if (subscription) {
      const status = getStripeStatusFromPaypalStatus(subscription.status);
      userSubscription = {
        id: subscription.id,
        status,
        nextBillDateUnix: nextBillDateUnix as number,
        product: getStripeProductByPaypalId(products, subscription.plan_id) as Product,
        type: SubscriptionType.PAYPAL,
      };
      yield put(getPaypalCustomerDataSuccess(true));
    }
    yield put(setPaypalSubscription(userSubscription));
  } catch (error) {
    console.log("getPayPalSubscription error", error);
  } finally {
    yield put(stopAction({ name: action.type }));
  }
}

export function* watchGetPayPalSubscriptionSaga() {
  yield takeLeading(PaypalActionTypes.GET_PAYPAL_SUBSCRIPTION, getPayPalSubscriptionSaga);
}

function* cancelPaypalSubscriptionSaga(action: CancelPaypalSubscriptionAction) {
  try {
    yield put(startAction({ name: action.type }));
    const paypalSubscription = yield* select(paypalSubscriptionSelector);
    yield* call(() => ApiService.callApi(paypalRequests.cancelSubscription()));
    yield put(getPaypalCustomerDataSuccess(true));
    yield put(
      setPaypalSubscription({
        ...((paypalSubscription || {}) as UserSubscription),
        status: SubscriptionStatus.CANCELLED,
      })
    );
  } catch (error) {
    console.log("cancelPaypalSubscription error", error);
  } finally {
    yield put(stopAction({ name: action.type }));
  }
}

export function* watchCancelPaypalSubscriptionSaga() {
  yield takeLeading(PaypalActionTypes.CANCEL_PAYPAL_SUBSCRIPTION, cancelPaypalSubscriptionSaga);
}

function* updatePaypalSubscriptionSaga(action: UpdatePaypalSubscriptionAction) {
  try {
    yield put(startAction({ name: action.type }));
    const product = yield* select(activeProductSelector);
    const freeTrialUsed = yield* select(paypalFreeTrialUsedSelector);
    const { onSuccess } = action.payload;
    const paypalSubscriptionIdByStripeProduct = getPaypalSubscriptionIdByStripeProduct(product, freeTrialUsed);
    if (!paypalSubscriptionIdByStripeProduct) {
      return;
    }
    const response = yield* call(() =>
      ApiService.callApi(paypalRequests.updateSubscription(paypalSubscriptionIdByStripeProduct))
    );
    if (response.data.link) {
      window.open(response.data.link, "_newtab");
      onSuccess();
    }
  } catch (error) {
    console.log("updatePaypalSubscriptionSaga error", error);
  } finally {
    yield put(stopAction({ name: action.type }));
  }
}

export function* watchUpdatePaypalSubscriptionSaga() {
  yield takeLeading(PaypalActionTypes.UPDATE_PAYPAL_SUBSCRIPTION, updatePaypalSubscriptionSaga);
}

function* getPaypalCustomerDataSaga(action: GetStripeCustomerDataAction) {
  try {
    const response = yield* call(() => ApiService.callApi(paypalRequests.getCustomer()));
    yield put(getPaypalCustomerDataSuccess(response.data.freeTrialUsed));
  } catch (error) {
    console.log("getPaypalCustomerDataSaga error", error);
  }
}

export function* watchGetPaypalCustomerSaga() {
  yield takeLeading(PaypalActionTypes.GET_PAYPAL_CUSTOMER_DATA, getPaypalCustomerDataSaga);
}
