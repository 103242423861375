import React from "react";
import styles from "components/DiscountBanner/DiscountBanner.module.css";
import { FlexDiv } from "components/index";
import classNames from "classnames";

interface DiscountBannerProps {
  discount?: string;
  className?: string;
}

const DiscountBanner: React.FC<DiscountBannerProps> = ({ discount, className }) => {
  if (!discount) {
    return null;
  }
  return (
    <FlexDiv className={classNames(styles.parallelogram, className)}>
      <p className={styles.discount}>{`-${discount}`}</p>
    </FlexDiv>
  );
};

export default React.memo(DiscountBanner);
