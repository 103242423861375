import { PaymentCheckoutState } from "models/interfaces";
import { Routes, SubscriptionStatus } from "models/enums";
import {
  CreateSubscriptionActions,
  OnApproveActions,
  OnApproveData,
  OnClickActions,
} from "@paypal/paypal-js/types/components/buttons";
import ApiService, { paypalRequests } from "services/api";
import { setUserToken } from "store/user/userActions";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { activeProductSelector } from "store/products/productSelectors";
import { getPaypalSubscriptionIdByStripeProduct } from "utils/helpers";
import { getPaypalCustomerDataSuccess, getPaypalSubscription } from "store/paypal/paypalActions";
import { paypalFreeTrialUsedSelector } from "store/paypal/paypalSelectors";
import { stripeSubscriptionSelector } from "store/stripe/stripeSelectors";
import { cancelStripeSubscription } from "store/stripe/stripeActions";
import { selectedCoachSelector } from "store/coach/coachSelectors";

// TODO remove paypal from app
export function usePaypalPayment() {
  const history = useHistory();
  const dispatch = useDispatch();
  const product = useSelector(activeProductSelector);
  const selectedCoach = useSelector(selectedCoachSelector);
  const location = useLocation();
  const freeTrialUsed = useSelector(paypalFreeTrialUsedSelector);
  const stripeSubscription = useSelector(stripeSubscriptionSelector);
  const isLoggedIn = !location.pathname.includes(Routes.PAYMENT.substr(0, Routes.PAYMENT.length - 3));

  function goToCheckout(success: boolean) {
    const state: PaymentCheckoutState = { success, isFreeTrial: false };
    history.push(Routes.PAYMENT_CHECKOUT, state);
  }

  async function onPayPalClick(data: Record<string, unknown>, actions: OnClickActions) {
    try {
      await ApiService.callApi(paypalRequests.createCustomer());
      return actions.resolve();
    } catch (error) {
      return actions.reject();
    }
  }

  async function onPaypalApprove(data: OnApproveData, actions: OnApproveActions) {
    try {
      if (!data.subscriptionID || !selectedCoach) {
        return;
      }
      await ApiService.callApi(paypalRequests.createPayPalSubscription(data.subscriptionID, selectedCoach?.id));
      if (!isLoggedIn) {
        goToCheckout(true);
        dispatch(setUserToken(undefined));
      } else {
        dispatch(getPaypalCustomerDataSuccess(true));
        dispatch(getPaypalSubscription());
        if (location.pathname === Routes.ACCOUNT_PLANS_PAYMENT) {
          history.push(Routes.ACCOUNT);
        }
      }
    } catch (error) {
      console.log("onPaypalApprove error", error);
    }
  }

  async function onPaypalCreateSubscription(data: Record<string, unknown>, actions: CreateSubscriptionActions) {
    try {
      if (stripeSubscription?.status === SubscriptionStatus.ACTIVE) {
        dispatch(cancelStripeSubscription());
      }
      const stripePlanId = getPaypalSubscriptionIdByStripeProduct(stripeSubscription?.product, true);
      const planId = stripePlanId || getPaypalSubscriptionIdByStripeProduct(product, freeTrialUsed);
      if (!planId) {
        console.log("no planId found for product ", product, stripePlanId);
        return "";
      }

      let stripeEndDate =
        stripeSubscription && stripeSubscription?.nextBillDateUnix > Date.now()
          ? stripeSubscription?.nextBillDateUnix
          : null;
      const date = new Date(stripeEndDate || Date.now() + 5000).toISOString();
      return actions.subscription.create({
        plan_id: planId, // Creates the subscription
        start_time: date.substring(0, date.length - 5) + date.charAt(date.length - 1),
      });
    } catch (error) {
      console.log("onPaypalCreateSubscription error", error);
      return "";
    }
  }

  async function onPaypalError(error: Record<string, unknown>) {
    console.log("onPaypalError error", error);
  }

  async function onPaypalCancel() {}

  return {
    onPaypalCreateSubscription,
    onPaypalError,
    onPaypalApprove,
    onPaypalCancel,
    onPayPalClick,
  };
}
