import { useEffect, useMemo, useState } from "react";
import { AccountFormType } from "models/interfaces";
import { validateEmail, validateName, validatePassword } from "utils/helpers";
import ApiService, { userRequests } from "services/api";
import { toast } from "react-toastify";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";

export function useAuthenticationForm(isLogin: boolean) {
  const { t } = useTypedTranslation();
  const [accountForm, setAccountForm] = useState<AccountFormType>({
    name: {
      value: "",
      errorMsg: undefined,
      isFocused: false,
    },
    email: {
      value: "",
      errorMsg: undefined,
      isFocused: false,
    },
    password: {
      value: "",
      errorMsg: undefined,
      isFocused: false,
    },
    formError: undefined,
  });

  useEffect(() => {
    const { value, isFocused } = accountForm.email;
    if (!isFocused && value && !validateEmail(value)) {
      setAccountForm((prevState) => ({
        ...prevState,
        email: { ...prevState.email, errorMsg: t("invalidEmail") },
        formError: undefined,
      }));
    }
  }, [accountForm.email.isFocused]);

  useEffect(() => {
    const { value, isFocused } = accountForm.password;
    if (!isFocused && value && !validatePassword(value)) {
      setAccountForm((prevState) => ({
        ...prevState,
        password: { ...prevState.password, errorMsg: t("invalidPassword") },
        formError: undefined,
      }));
    }
  }, [accountForm.password.isFocused]);

  useEffect(() => {
    const { value, isFocused } = accountForm.name;
    if (!isFocused && value && !validateName(value)) {
      setAccountForm((prevState) => ({
        ...prevState,
        name: { ...prevState.name, errorMsg: t("invalidName") },
        formError: undefined,
      }));
    }
  }, [accountForm.name.isFocused]);

  async function onForgotPasswordClick() {
    const email = accountForm.email.value;
    if (validateEmail(email)) {
      try {
        const response = await ApiService.callApi(userRequests.forgotPassword(email));
        if (response.status === 404) {
          toast.error(t("enterValidEmail"));
          return;
        }
        response.data?.message && toast.success(response.data?.message, { autoClose: 5000 });
      } catch (error) {
        console.log("onForgotPassword error", error);
      }
    } else {
      toast.error(t("enterValidEmail"));
    }
  }

  const onAccountFormValueChange = (fieldKey: "email" | "password" | "name") => (value: string) => {
    const errorMsg = undefined;
    setAccountForm({ ...accountForm, [fieldKey]: { ...accountForm[fieldKey], value, errorMsg }, formError: undefined });
  };

  const onAccountFormFocusChange = (fieldKey: "email" | "password" | "name") => (isFocused: boolean) => {
    setAccountForm({ ...accountForm, [fieldKey]: { ...accountForm[fieldKey], isFocused } });
  };

  function validateAccountForm() {
    const isValidEmail = validateEmail(accountForm.email.value);
    const isValidPassword = validatePassword(accountForm.password.value);
    const isValidName = isLogin ? true : validateName(accountForm.name.value);
    return isValidEmail && isValidPassword && isValidName;
  }

  const isAccountFormValid = useMemo(() => {
    return validateAccountForm();
  }, [accountForm, isLogin]);

  return {
    accountForm,
    setAccountForm,
    onForgotPasswordClick,
    onAccountFormFocusChange,
    onAccountFormValueChange,
    validateAccountForm,
    isAccountFormValid,
  };
}
