import React, { useEffect, useMemo, useState } from "react";
import styles from "screens/Account/Account.module.css";
import { FlexDiv, SideNavBar } from "components";
import { userTokenSelector } from "store/user/userSelectors";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, logout } from "store/user/userActions";
import { useHistory, useLocation } from "react-router-dom";
import { Routes, SideBarTabs } from "models/enums";
import AccountOverview from "screens/Account/AccountOverview";
import AccountProfile from "screens/Account/AccountProfile";
import AvailablePlans from "screens/Account/AvailablePlans";
import PlanChange from "screens/Account/PlanChange";
import ChangePayment from "screens/Account/ChangePayment";
import { useIsDesktop } from "utils/hooks/useIsDesktop";
import classNames from "classnames";
import { getStripeCustomerData } from "store/stripe/stripeActions";
import { useMount } from "react-use";
import { getPaypalCustomerData, getPaypalSubscription } from "store/paypal/paypalActions";
import { getProducts } from "store/products/productActions";
import { getAllCoaches } from "store/coach/coachActions";

interface AccountProps {}

const Account: React.FC<AccountProps> = () => {
  const dispatch = useDispatch();
  const token = useSelector(userTokenSelector);
  const history = useHistory();
  const location = useLocation();
  const activeTab = useMemo(() => {
    switch (location.pathname) {
      case Routes.ACCOUNT_PLANS:
      case Routes.ACCOUNT_PLANS_CANCEL:
      case Routes.ACCOUNT_PLANS_CHANGE:
      case Routes.ACCOUNT_PLANS_PAYMENT:
        return SideBarTabs.PLANS;
      case Routes.ACCOUNT_PROFILE:
        return SideBarTabs.PROFILE;
      default:
        return SideBarTabs.ACCOUNT;
    }
  }, [location.pathname]);

  const isDesktop = useIsDesktop();
  const [showBar, setShowBar] = useState(isDesktop);

  useMount(() => {
    dispatch(getAllCoaches());
    dispatch(getUserInfo());
    dispatch(getProducts());
    dispatch(getStripeCustomerData());
    dispatch(getPaypalSubscription());
    dispatch(getPaypalCustomerData());
  });

  useEffect(() => {
    setShowBar(isDesktop);
  }, [isDesktop]);

  if (!token) {
    history.push(Routes.AUTHENTICATION_LOGIN);
  }

  function onAccountClick() {
    history.push(Routes.ACCOUNT);
  }
  function onPlansClick() {
    history.push(Routes.ACCOUNT_PLANS);
  }
  function onProfileClick() {
    history.push(Routes.ACCOUNT_PROFILE);
  }
  function onLogoutClick() {
    dispatch(logout());
    history.push(Routes.AUTHENTICATION_LOGIN);
  }
  function onToggleBarClick() {
    setShowBar(!showBar);
  }
  const activeTabContent = useMemo(() => {
    switch (location.pathname) {
      case Routes.ACCOUNT_PROFILE:
        return <AccountProfile />;
      case Routes.ACCOUNT_PLANS_PAYMENT:
        return <ChangePayment />;
      case Routes.ACCOUNT_PLANS_CANCEL:
        return <PlanChange type={"cancel"} />;
      case Routes.ACCOUNT_PLANS_CHANGE:
        return <PlanChange type={"change"} />;
      case Routes.ACCOUNT_PLANS:
        return <AvailablePlans />;
      default:
        return <AccountOverview />;
    }
  }, [location.pathname]);

  return (
    <FlexDiv className={styles.container}>
      <SideNavBar
        onToggleBarClick={onToggleBarClick}
        showBar={showBar}
        activeTab={activeTab}
        className={styles.sidebarContainer}
        onAccountClick={onAccountClick}
        onPlansClick={onPlansClick}
        onProfileClick={onProfileClick}
        onLogoutClick={onLogoutClick}
      />
      <FlexDiv className={classNames(styles.contentContainer, !isDesktop && showBar ? styles.mobileBarVisible : "")}>
        {activeTabContent}
      </FlexDiv>
    </FlexDiv>
  );
};

export default React.memo(Account);
