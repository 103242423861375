import React, { useMemo, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "models/enums";
import { Elements } from "@stripe/react-stripe-js";
import Payment from "screens/Payment";
import Authentication from "screens/Authentication";
import Plan from "screens/Plan";
import Account from "screens/Account";
import PaymentCheckout from "screens/PaymentCheckout";
import { useDispatch, useSelector } from "react-redux";
import { clientSecretSelector } from "store/stripe/stripeSelectors";
import { loadStripe } from "@stripe/stripe-js";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";
import { useMount } from "react-use";
import { getAllCoaches } from "store/coach/coachActions";
import { getProducts } from "store/products/productActions";
import mixpanel from "mixpanel-browser";
import ReactPixel from "react-facebook-pixel";

// Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const AppRouter = () => {
  const clientSecret = useSelector(clientSecretSelector);
  const { i18n } = useTypedTranslation();
  const dispatch = useDispatch();
  const [basename, setBasename] = useState("");

  const stripeOptions = useMemo(
    () => ({ clientSecret, fonts: [{ cssSrc: "https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" }] }),
    [clientSecret]
  );

  useMount(() => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY as string);
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_KEY as string);
    dispatch(getAllCoaches());
    dispatch(getProducts());

    if (window.location.pathname.substring(0, 4).includes("/pt/")) {
      setBasename("/pt");
      i18n.changeLanguage("pt");
    } else {
      setBasename("/en");
      i18n.changeLanguage("en");
    }
  });

  if (!basename) {
    return null;
  }

  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <BrowserRouter basename={basename}>
        <Switch>
          <Route path={Routes.AUTHENTICATION_LOGIN} exact={true} component={Authentication} />
          <Route path={Routes.PLANS} component={Plan} />
          <Route path={Routes.PAYMENT} component={Payment} />
          <Route path={Routes.PAYMENT_CHECKOUT} component={PaymentCheckout} />
          <Route path={Routes.ACCOUNT} component={Account} />
          <Redirect to={Routes.ACCOUNT} />
        </Switch>
      </BrowserRouter>
    </Elements>
  );
};

export default React.memo(AppRouter);
