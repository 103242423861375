import React from "react";
import { AccountCard, AccountTitle } from "components";
import { Routes, SubscriptionStatus } from "models/enums";
import { useSelector } from "react-redux";
import { userSubscriptionSelector } from "store/user/userSelectors";
import { useHistory } from "react-router-dom";
import PaymentMethods from "components/PaymentMethods";
import { usePaymentMethods } from "utils/hooks/usePaymentMethods";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";
import { useProcessStripePayment } from "utils/hooks/useProcessStripePayment";
import styles from "screens/Account/ChangePayment/ChangePayment.module.css";
import { format } from "date-fns";
import { dateFormat } from "models/data";

interface ChangePaymentProps {}

const ChangePayment: React.FC<ChangePaymentProps> = (props) => {
  const history = useHistory();
  const { t } = useTypedTranslation();
  const subscription = useSelector(userSubscriptionSelector);
  const { isProcessingPayment, migratePaypalToStripe, updatePaymentDetailsUsingCard } = useProcessStripePayment();
  let isPaypalSubscription = subscription?.type === "PAYPAL";
  const {
    onGoogleApplePayClick,
    onPayPalClick,
    onCreditCardClick,
    selectedOption,
    isValidCardForm,
    setIsValidCardForm,
  } = usePaymentMethods(isPaypalSubscription ? "payPal" : "creditCard");

  async function onUpdatePaymentDetailsClick() {
    if (selectedOption.creditCard) {
      if (subscription?.status === SubscriptionStatus.ACTIVE && subscription.type === "PAYPAL") {
        await migratePaypalToStripe();
      } else {
        await updatePaymentDetailsUsingCard();
      }
    }
  }

  function onBackToPlansClick() {
    history.push(Routes.ACCOUNT_PLANS);
  }

  return (
    <>
      <AccountTitle sectionTitle={t("changePayment")} />
      <AccountCard
        title={t("paymentOptions")}
        isLoading={isProcessingPayment}
        buttons={[
          { text: t("backToPlans"), onClick: onBackToPlansClick, theme: "LIGHT" },
          {
            text: t("updatePaymentDetails"),
            onClick: onUpdatePaymentDetailsClick,
            theme: "PRIMARY",
            disabled:
              (isPaypalSubscription && selectedOption.payPal) || (!isValidCardForm && selectedOption.creditCard),
            renderAsPaypalButton: selectedOption.payPal,
          },
        ]}
      >
        <PaymentMethods
          showGoogleApplePay={false}
          updateCardFromStatus={setIsValidCardForm}
          onCreditCardClick={onCreditCardClick}
          onPayPalClick={onPayPalClick}
          onGoogleApplePayClick={onGoogleApplePayClick}
          isCreditCardSelected={selectedOption.creditCard}
          isPayPalSelected={selectedOption.payPal}
          isGoogleApplePaySelected={selectedOption.googleApplePay}
          canMakePayment={null}
        />
        {((selectedOption.payPal && !isPaypalSubscription) || (selectedOption.creditCard && isPaypalSubscription)) &&
          subscription && (
            <p className={styles.notice}>
              {t("youreChangingPaymentOptionTo", {
                option: isPaypalSubscription ? "Stripe" : "Paypal",
                date: format(subscription?.nextBillDateUnix, dateFormat),
              })}
            </p>
          )}
      </AccountCard>
    </>
  );
};

export default React.memo(ChangePayment);
