import React, { ButtonHTMLAttributes } from "react";
import styles from "components/NavButton/NavButton.module.css";
import classNames from "classnames";

interface NavButtonProps {
  title: string;
  icon: string;
  className?: string;
  isActive: boolean;
}

const NavButton: React.FC<NavButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({
  title,
  icon,
  className,
  isActive,
  ...restProps
}) => {
  return (
    <button className={classNames(styles.button, isActive ? "" : styles.inactiveButton, className)} {...restProps}>
      <img className={classNames(styles.buttonIcon, isActive ? "" : styles.inactiveButtonIcon)} src={icon} />
      <p className={classNames(styles.buttonText, isActive ? "" : styles.inactiveButtonText)}>{title}</p>
    </button>
  );
};

export default React.memo(NavButton);
