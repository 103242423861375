import React from "react";
import styles from "components/AvailablePlanCard/AvailablePlanCard.module.css";
import FlexDiv from "components/FlexDiv/FlexDiv";
import PlanTitleSubtitle from "components/PlanTitleSubtitle/PlanTitleSubtitle";
import PriceDuration from "components/PriceDuration/PriceDuration";
import DiscountBanner from "components/DiscountBanner";
import SecondaryButton from "components/SecondaryButton";
import { useIsDesktop } from "utils/hooks/useIsDesktop";
import classNames from "classnames";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";
import { TransKey } from "utils/i18n/allTranslationsTyped";

interface AvailablePlanCardProps {
  planTitle: string;
  planSubtitle: string;
  price: number;
  priceUnit: string;
  duration: string;
  discount?: string;
  onSelectPlanClick: () => void;
}

const AvailablePlanCard: React.FC<AvailablePlanCardProps> = ({
  planTitle,
  planSubtitle,
  price,
  priceUnit,
  duration,
  discount,
  onSelectPlanClick,
}) => {
  const { t } = useTypedTranslation();
  const isDesktop = useIsDesktop();
  const translatedDuration = t(duration?.replace(/[^a-zA-Z]/, "").trim() as TransKey);
  const durationInterval = duration?.match(/\d+/);
  const interval = durationInterval ? durationInterval[0] + " " : "";
  if (!isDesktop) {
    return (
      <FlexDiv className={styles.container}>
        <DiscountBanner discount={discount} className={styles.discount} />
        <FlexDiv className={classNames(styles.mobileContainer, !discount ? styles.divider : "")}>
          <PlanTitleSubtitle planTitle={planTitle} planSubtitle={planSubtitle} />
          <PriceDuration
            className={styles.priceDurationWrapper}
            price={price}
            priceUnit={priceUnit}
            duration={`${interval}${translatedDuration}`}
            type={"small"}
          />
          <SecondaryButton className={styles.button} buttonText={t("selectPlan")} onClick={onSelectPlanClick} />
        </FlexDiv>
      </FlexDiv>
    );
  }
  return (
    <FlexDiv className={styles.container}>
      <FlexDiv className={styles.leftContainer}>
        <PlanTitleSubtitle planTitle={planTitle} planSubtitle={planSubtitle} />
        <PriceDuration
          className={styles.priceDurationWrapper}
          price={price}
          priceUnit={priceUnit}
          duration={`${interval}${translatedDuration}`}
          type={"small"}
        />
      </FlexDiv>
      <FlexDiv className={styles.rightContainer}>
        <DiscountBanner discount={discount} className={styles.discount} />
        <SecondaryButton className={styles.button} buttonText={t("selectPlan")} onClick={onSelectPlanClick} />
      </FlexDiv>
    </FlexDiv>
  );
};

export default React.memo(AvailablePlanCard);
