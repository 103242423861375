import { AccountFormType } from "models/interfaces";
import { useCallback } from "react";
import { login, register } from "store/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import { checkIfLoadingSelector } from "store/ui/uiSelectors";
import { UserActionTypes } from "models/store/actionTypes";
import mixpanel from "mixpanel-browser";
import { MixpanelEvent } from "models/enums";

export function useAuthentication(accountForm: AccountFormType, isSaveToStore: boolean = true) {
  const dispatch = useDispatch();
  const isLoading = useSelector(checkIfLoadingSelector)([UserActionTypes.LOGIN, UserActionTypes.REGISTER]);
  const onLoginClick = useCallback(() => {
    mixpanel.track(MixpanelEvent.PAYMENT_DASHBOARD_LOGIN);
    dispatch(login(accountForm.email.value, accountForm.password.value, isSaveToStore));
  }, [accountForm.email.value, accountForm.password.value, isSaveToStore, dispatch]);

  const onRegisterClick = useCallback(() => {
    dispatch(register(accountForm.email.value, accountForm.password.value, accountForm.name.value, isSaveToStore));
  }, [accountForm.email.value, accountForm.name.value, accountForm.password.value, isSaveToStore, dispatch]);

  return { isLoading, onLoginClick, onRegisterClick };
}
