import React from "react";
import styles from "components/CardSecurePaymentHeader/CardSecurePaymentHeader.module.css";
import FlexDiv from "components/FlexDiv";
import FormTitle from "components/FormTitle";
import { Icons } from "models/enums";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";

interface CardSecurePaymentHeaderProps {
  sectionIndex?: number;
  title: string;
  disabled?: boolean;
}

const CardSecurePaymentHeader: React.FC<CardSecurePaymentHeaderProps> = ({ sectionIndex, title, disabled }) => {
  const { t } = useTypedTranslation();
  return (
    <FlexDiv className={styles.titleContainer}>
      <FormTitle sectionIndex={sectionIndex} title={title} disabled={disabled} />
      {!disabled && (
        <FlexDiv className={styles.securePaymentLockWrapper}>
          <img src={Icons.LOCK} />
          <p className={styles.securePayment}>{t("securePayment")}</p>
        </FlexDiv>
      )}
    </FlexDiv>
  );
};

export default React.memo(CardSecurePaymentHeader);
