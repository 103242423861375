import { useState } from "react";

const initialSelectedOptionState = {
  googleApplePay: false,
  creditCard: false,
  payPal: false,
};
export function usePaymentMethods(preSelectedOption: keyof typeof initialSelectedOptionState) {
  const [selectedOption, setSelectedOption] = useState({
    ...initialSelectedOptionState,
    [preSelectedOption]: true,
  });
  const [isValidCardForm, setIsValidCardForm] = useState(false);

  function onGoogleApplePayClick() {
    setSelectedOption({ googleApplePay: true, creditCard: false, payPal: false });
  }
  function onCreditCardClick() {
    setSelectedOption({ googleApplePay: false, creditCard: true, payPal: false });
  }
  function onPayPalClick() {
    setSelectedOption({ googleApplePay: false, creditCard: false, payPal: true });
  }

  return {
    selectedOption,
    onGoogleApplePayClick,
    onCreditCardClick,
    onPayPalClick,
    isValidCardForm,
    setIsValidCardForm,
  };
}
