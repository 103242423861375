import React from "react";
import styles from "components/CustomPaypalButton/CustomPaypalButton.module.css";
import FlexDiv from "components/FlexDiv";
import { usePaypalPayment } from "utils/hooks/usePaypalPayment";
import PayPal from "components/PayPal";

interface CustomPaypalButtonProps {
  wrapper: React.ReactNode;
}

const CustomPaypalButton: React.FC<CustomPaypalButtonProps> = ({ wrapper }) => {
  const { onPaypalApprove, onPayPalClick, onPaypalCreateSubscription } = usePaypalPayment();
  return (
    <FlexDiv className={styles.container}>
      <FlexDiv className={styles.buttonWrapper}>{wrapper}</FlexDiv>
      <FlexDiv className={styles.paypalButtonWrapper}>
        <PayPal
          onPayPalClick={onPayPalClick}
          onPaypalCreateSubscription={onPaypalCreateSubscription}
          onPaypalApprove={onPaypalApprove}
        />
      </FlexDiv>
    </FlexDiv>
  );
};

export default React.memo(CustomPaypalButton);
