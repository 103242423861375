import { ApiRequest, PayPalSubscription } from "models/interfaces";

const prefix: string = "paypal";

const getPaypalSubscription = (): ApiRequest<{
  subscription: PayPalSubscription;
  nextBillDateUnix: number | undefined;
}> => ({
  url: `${prefix}/subscription`,
  options: { method: "GET" },
  includeAuthorizationHeader: true,
});

const createPayPalSubscription = (subscriptionId: string, coachId: number): ApiRequest<{}> => ({
  url: `${prefix}/subscription/create`,
  options: { method: "PUT", data: { subscriptionId, coachId } },
  includeAuthorizationHeader: true,
});

const createCustomer = (): ApiRequest<{}> => ({
  url: `${prefix}/customer/create`,
  options: { method: "PUT" },
  includeAuthorizationHeader: true,
});

const cancelSubscription = (): ApiRequest<{}> => ({
  url: `${prefix}/subscription/cancel`,
  options: { method: "POST" },
  includeAuthorizationHeader: true,
});

const updateSubscription = (planId: string): ApiRequest<{ link: string }> => ({
  url: `${prefix}/subscription/update`,
  options: { method: "POST", data: { planId } },
  includeAuthorizationHeader: true,
});

const getCustomer = (): ApiRequest<{ freeTrialUsed: boolean }> => ({
  url: `${prefix}/customer`,
  options: {
    method: "GET",
  },
  includeAuthorizationHeader: true,
});

export const paypalRequests = {
  getPaypalSubscription,
  createCustomer,
  createPayPalSubscription,
  cancelSubscription,
  updateSubscription,
  getCustomer,
};
