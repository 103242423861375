import { UserActionTypes } from "models/store/actionTypes";
import { UserActions } from "models/store/userActionts";
import { UserInfo } from "models/interfaces";

export interface UserState {
  token?: string;
  tempToken?: string;
  userInfo: null | UserInfo;
}

const initialState: UserState = {
  token: undefined,
  tempToken: undefined,
  userInfo: null,
};

const userReducer = (state = initialState, action: UserActions): UserState => {
  switch (action.type) {
    case UserActionTypes.SET_USER_TOKEN:
      return { ...state, token: action.payload.token };
    case UserActionTypes.SET_TEMP_USER_TOKEN:
      return { ...state, tempToken: action.payload.tempToken };
    case UserActionTypes.GET_USER_INFO_SUCCESS:
      return { ...state, userInfo: action.payload.userInfo };
    case UserActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
