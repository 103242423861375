import amo from "assets/icons/amo.svg";
import checkmark from "assets/icons/check-mark.svg";
import exclamationMark from "assets/icons/exclamation-mark.png";
import lock from "assets/icons/lock.svg";
import fail from "assets/icons/icon-fail.svg";
import success from "assets/icons/icon-success.svg";
import activity from "assets/icons/activity.svg";
import wallet from "assets/icons/wallet.svg";
import logout from "assets/icons/logout.svg";
import profile from "assets/icons/profile.svg";
import menu from "assets/icons/menu.svg";
import payPal from "assets/icons/paypal.svg";
import creditCards from "assets/icons/credit-cards.svg";
import close from "assets/icons/close.svg";
import americanExpressCard from "assets/icons/card/American Express.png";
import dinersCard from "assets/icons/card/Dinersclub.png";
import discoverCard from "assets/icons/card/Discover.png";
import genericCard from "assets/icons/card/Generic Card.png";
import jcbCard from "assets/icons/card/JCB.png";
import masterCard from "assets/icons/card/Mastercard.png";
import visaCard from "assets/icons/card/Visa.png";
import googlePaySmall from "assets/icons/google-pay-small.svg";
import applePaySmall from "assets/icons/apple-pay-small.svg";
import applePayWhite from "assets/icons/apple-pay-white.svg";
import chevronRight from "assets/icons/chevron-right.svg";
import tagFill from "assets/icons/tag-fill.svg";
import tagClose from "assets/icons/tag-close.svg";
import downArrow from "assets/icons/down-arrow.svg";

import lisianeAvatar from "assets/images/lisiane.png";
import lisianeDumbbells from "assets/images/lisiane-dumbbells.png";
import securePayment from "assets/images/secure-payment.png";
import poweredByStripe from "assets/images/powered-by-stripe.png";
import appStoreBadge from "assets/images/appstore-badge.svg";
import googlePlayBadge from "assets/images/googleplay-badge.svg";
import defaultAvatar from "assets/images/default-avatar.png";

export const Icons = {
  AMO: amo,
  CHECKMARK: checkmark,
  EXCLAMATION_MARK: exclamationMark,
  LOCK: lock,
  FAIL: fail,
  SUCCESS: success,
  ACTIVITY: activity,
  LOGOUT: logout,
  PROFILE: profile,
  WALLET: wallet,
  MENU: menu,
  CREDIT_CARDS: creditCards,
  PAY_PAL: payPal,
  CLOSE: close,
  AMERICAN_EXPRESS_CARD: americanExpressCard,
  DINERS_CLUB_CARD: dinersCard,
  DISCOVER_CARD: discoverCard,
  GENERIC_CARD: genericCard,
  JCB_CARD: jcbCard,
  MASTER_CARD: masterCard,
  VISA_CARD: visaCard,
  GOOGLE_PAY_SMALL: googlePaySmall,
  APPLE_PAY_SMALL: applePaySmall,
  APPLE_PAY_WHITE: applePayWhite,
  CHEVRON_RIGHT: chevronRight,
  TAG_FILL: tagFill,
  TAG_CLOSE: tagClose,
  DOWN_ARROW: downArrow,
};

export const Images = {
  LISIANE_AVATAR: lisianeAvatar,
  LISIANE_DUMBBELLS: lisianeDumbbells,
  SECURE_PAYMENT: securePayment,
  POWERED_BY_STRIPE: poweredByStripe,
  APP_STORE_BADGE: appStoreBadge,
  GOOGLE_PLAY_BADGE: googlePlayBadge,
  DEFAULT_AVATAR: defaultAvatar,
};

export enum Routes {
  AUTHENTICATION_LOGIN = "/login",
  AUTHENTICATION_REGISTER = "/register",
  ACCOUNT = "/account",
  ACCOUNT_PLANS = "/account/plans",
  ACCOUNT_PLANS_PAYMENT = "/account/plans/payment",
  ACCOUNT_PLANS_CHANGE = "/account/plans/change",
  ACCOUNT_PLANS_CANCEL = "/account/plans/cancel",
  ACCOUNT_PROFILE = "/account/profile",
  PLANS = "/plans/:coachName?",
  PAYMENT = "/payment/:planInterval?/:coachName?/:login?",
  PAYMENT_CHECKOUT = "/paymentCheckout",
}

export enum SideBarTabs {
  ACCOUNT = "Account",
  PLANS = "Plans",
  PROFILE = "Profile",
  LOG_OUT = "Log out",
}

export enum CardBrands {
  AMERICAN_EXPRESS = "amex",
  DINERS_CLUB = "diners",
  DISCOVER = "discover",
  JCB = "jcb",
  MASTER_CARD = "mastercard",
  VISA = "visa",
}

export enum SubscriptionStatus {
  ACTIVE = "active",
  FREE_TRIAL = "trialing",
  CANCELLED = "canceled",
  INCOMPLETE = "incomplete",
  INCOMPLETE_EXPIRED = "incomplete_expired",
  PAST_DUE = "past_due",
  UNPAID = "unpaid",
}

export enum PayPalSubscriptionStatus {
  APPROVAL_PENDING = "APPROVAL_PENDING",
  APPROVED = "APPROVED",
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
}

export enum Coaches {
  JAMES = "James",
  LISIANE = "Lisiane",
  LEO = "Leo",
}

export enum PaymentType {
  STRIPE = "STRIPE",
  GOOGLE_APPLE = "GOOGLE_APPLE",
  PAYPAL = "PAYPAL",
}

export enum MixpanelEvent {
  PAYMENT_DASHBOARD_LOGIN = "PAYMENT_DASHBOARD_LOGIN",
  PAYMENT_PLANS = "PAYMENT_PLANS",
  PAYMENT_PLAN_SELECT = "PAYMENT_PLAN_SELECT",
  PAYMENT_PLAN_PAY = "PAYMENT_PLAN_PAY",
  PAYMENT_PLAN_PAY_SUCCESS = "PAYMENT_PLAN_PAY_SUCCESS",
  PAYMENT_PLAN_PAY_FAIL = "PAYMENT_PLAN_PAY_FAIL",
  DOWNLOAD_APP_STORE = "DOWNLOAD_APP_STORE",
  DOWNLOAD_GOOGLE_PLAY = "DOWNLOAD_GOOGLE_PLAY",
}

export enum FacebookPixelEvent {
  Lead = "LEAD",
  CompleteRegistration = "COMPLETE_REGISTRATION",
  StartTrial = "START_TRIAL",
  Purchase = "PURCHASE",
}

export enum PlanDuration {
  monthly = "monthly",
  quarterly = "quarterly",
  yearly = "yearly",
}
