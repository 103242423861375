export enum StripeActionTypes {
  CREATE_PAYMENT_INTENT = "CREATE_PAYMENT_INTENT",
  CREATE_PAYMENT_INTENT_SUCCESS = "CREATE_PAYMENT_INTENT_SUCCESS",
  GET_STRIPE_CUSTOMER_DATA = "GET_STRIPE_CUSTOMER_DATA",
  GET_STRIPE_CUSTOMER_DATA_SUCCESS = "GET_STRIPE_CUSTOMER_DATA_SUCCESS",
  CANCEL_STRIPE_SUBSCRIPTION = "CANCEL_STRIPE_SUBSCRIPTION",
  CHANGE_STRIPE_SUBSCRIPTION = "CHANGE_STRIPE_SUBSCRIPTION",
  CREATE_SUBSCRIPTION_WITH_EXISTING_PAYMENT_METHOD = "CREATE_SUBSCRIPTION_WITH_EXISTING_PAYMENT_METHOD",
  SET_STRIPE_SUBSCRIPTION = "SET_STRIPE_SUBSCRIPTION",
  SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD",
}

export enum PaypalActionTypes {
  CANCEL_PAYPAL_SUBSCRIPTION = "CANCEL_PAYPAL_SUBSCRIPTION",
  GET_PAYPAL_SUBSCRIPTION = "GET_PAYPAL_SUBSCRIPTION",
  SET_PAYPAL_SUBSCRIPTION = "SET_PAYPAL_SUBSCRIPTION",
  GET_PAYPAL_CUSTOMER_DATA = "GET_PAYPAL_CUSTOMER_DATA",
  GET_PAYPAL_CUSTOMER_DATA_SUCCESS = "GET_PAYPAL_CUSTOMER_DATA_SUCCESS",
  UPDATE_PAYPAL_SUBSCRIPTION = "UPDATE_PAYPAL_SUBSCRIPTION",
}

export enum UserActionTypes {
  SET_USER_TOKEN = "SET_USER_TOKEN",
  SET_TEMP_USER_TOKEN = "SET_TEMP_USER_TOKEN",
  GET_USER_INFO = "GET_USER_INFO",
  GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS",
  LOGOUT = "LOGOUT",
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
}

export enum ProductActionTypes {
  GET_PRODUCTS = "GET_PRODUCTS",
  GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS",
  SET_ACTIVE_PRODUCT = "SET_ACTIVE_PRODUCT",
}

export enum UIActionTypes {
  START_ACTION = "START_ACTION",
  STOP_ACTION = "STOP_ACTION",
  REFRESH_ACTION_START = "REFRESH_ACTION_START",
  REFRESH_ACTION_STOP = "REFRESH_ACTION_STOP",
  ADD_ERROR_ACTION = "ADD_ERROR_ACTION",
  CLEAR_ERROR_ACTIONS = "CLEAR_ERROR_ACTIONS",
}

export enum CoachActionTypes {
  GET_ALL_COACHES = "GET_ALL_COACHES",
  GET_ALL_COACHES_SUCCESS = "GET_ALL_COACHES_SUCCESS",
  SELECT_COACH = "SELECT_COACH",
}

export type StoreActionTypes =
  | StripeActionTypes
  | UIActionTypes
  | UserActionTypes
  | ProductActionTypes
  | PaypalActionTypes
  | CoachActionTypes;
