import { PaypalActionTypes } from "models/store/actionTypes";
import { PayPalActions } from "models/store/paypalActions";
import { UserSubscription } from "models/interfaces";

export const getPaypalSubscription = (): PayPalActions => ({
  type: PaypalActionTypes.GET_PAYPAL_SUBSCRIPTION,
  payload: {},
});

export const setPaypalSubscription = (subscription: null | UserSubscription): PayPalActions => ({
  type: PaypalActionTypes.SET_PAYPAL_SUBSCRIPTION,
  payload: { subscription },
});

export const cancelPaypalSubscription = (): PayPalActions => ({
  type: PaypalActionTypes.CANCEL_PAYPAL_SUBSCRIPTION,
  payload: {},
});

export const updatePaypalSubscription = (onSuccess: () => void): PayPalActions => ({
  type: PaypalActionTypes.UPDATE_PAYPAL_SUBSCRIPTION,
  payload: { onSuccess },
});

export const getPaypalCustomerData = (): PayPalActions => ({
  type: PaypalActionTypes.GET_PAYPAL_CUSTOMER_DATA,
  payload: {},
});

export const getPaypalCustomerDataSuccess = (freeTrialUsed: boolean): PayPalActions => ({
  type: PaypalActionTypes.GET_PAYPAL_CUSTOMER_DATA_SUCCESS,
  payload: { freeTrialUsed },
});
