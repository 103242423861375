import React from "react";
import styles from "screens/Account/AccountOverview/AccountOverview.module.css";
import { AccountTitle, FlexDiv, PlanOverview, ProfileOverview } from "components";
import { useSelector } from "react-redux";
import { emailSelector, userNameSelector, userSubscriptionSelector } from "store/user/userSelectors";
import { useHistory } from "react-router-dom";
import { Routes } from "models/enums";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";
import { hasSubscription } from "utils/helpers";

interface AccountOverviewProps {}

const AccountOverview: React.FC<AccountOverviewProps> = (props) => {
  const history = useHistory();
  const { t } = useTypedTranslation();
  const username = useSelector(userNameSelector);
  const email = useSelector(emailSelector);
  const subscription = useSelector(userSubscriptionSelector);

  const onPaymentUpdateClick = () => {
    history.push(Routes.ACCOUNT_PLANS_PAYMENT);
  };
  const onChangePlanClick = () => {
    history.push(Routes.ACCOUNT_PLANS);
  };

  function onEditProfileClick() {
    history.push(Routes.ACCOUNT_PROFILE);
  }

  return (
    <>
      <AccountTitle sectionTitle={t("accountOverview")} />
      <ProfileOverview name={username as string} email={email as string} onEditProfileClick={onEditProfileClick} />
      <FlexDiv className={styles.planOverviewContainer}>
        <PlanOverview
          subscription={subscription}
          showPriceDuration={false}
          cardTitle={t("yourPlan")}
          cardButtons={[
            {
              text: hasSubscription(subscription?.status) ? t("changePlan") : t("getPlan"),
              onClick: onChangePlanClick,
            },
          ]}
          onPaymentUpdateClick={onPaymentUpdateClick}
        />
      </FlexDiv>
    </>
  );
};

export default React.memo(AccountOverview);
