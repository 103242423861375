import React from "react";
import styles from "components/CheckoutForm/CheckoutForm.module.css";
import stripeJs from "@stripe/stripe-js";
import CardForm from "components/CardForm";
import FlexDiv from "components/FlexDiv";
import PayWithCardForm from "components/PayWithCardForm";
import PrimaryButton from "components/PrimaryButton";
import PayPal from "components/PayPal";
import { Icons } from "models/enums";
import { CanMakePaymentWithGoogleApplePay } from "models/interfaces";
import {
  CreateSubscriptionActions,
  OnApproveActions,
  OnApproveData,
  OnClickActions,
} from "@paypal/paypal-js/types/components/buttons";

interface CheckoutFormProps {
  onGoogleApplePayClick: () => void;
  canMakePayment: CanMakePaymentWithGoogleApplePay | null;
  onCardNumberFocusChange: (isFocused: boolean) => void;
  onCardExpirationDateFocusChange: (isFocused: boolean) => void;
  onCardCVCFocusChange: (isFocused: boolean) => void;
  cardNumberError?: string;
  cardExpirationDateError?: string;
  cardCVCError?: string;
  cardNumberFocused: boolean;
  cardExpirationDateFocused: boolean;
  cardCVCFocused: boolean;
  googleApplePayDisabled: boolean;
  onCardNumberChange: (event: stripeJs.StripeCardNumberElementChangeEvent) => void;
  onCardExpirationDateChange: (event: stripeJs.StripeCardExpiryElementChangeEvent) => void;
  onCardCVCChange: (event: stripeJs.StripeCardCvcElementChangeEvent) => void;
  onPaypalApprove: (data: OnApproveData, actions: OnApproveActions) => Promise<void>;
  onPaypalCreateSubscription: (data: Record<string, unknown>, actions: CreateSubscriptionActions) => Promise<string>;
  onPayPalError: (error: Record<string, unknown>) => void;
  onPaypalCancel: () => void;
  onPayPalClick: (data: Record<string, unknown>, actions: OnClickActions) => void;
  rerenderPaypal: number;
}

const CheckoutForm: React.FC<CheckoutFormProps> = (props) => {
  // prettier-ignore
  const { canMakePayment, onCardCVCFocusChange, onCardNumberFocusChange, onCardExpirationDateFocusChange, onPaypalApprove, onPaypalCreateSubscription,
    cardExpirationDateError, cardCVCError, cardNumberError, cardCVCFocused, cardExpirationDateFocused, cardNumberFocused, onPayPalError, onPayPalClick,
    onPaypalCancel, onCardCVCChange, onCardNumberChange, onCardExpirationDateChange, onGoogleApplePayClick, googleApplePayDisabled, rerenderPaypal } = props;
  return (
    <FlexDiv className={styles.cardFormContainer}>
      <>
        {canMakePayment && (
          <PrimaryButton
            withRightCircle={false}
            onClick={onGoogleApplePayClick}
            disabled={googleApplePayDisabled}
            className={styles.paymentButton}
            buttonIcon={canMakePayment.googlePay ? Icons.GOOGLE_PAY_SMALL : Icons.APPLE_PAY_WHITE}
          />
        )}
        <PayPal
          forceReRender={rerenderPaypal}
          onPayPalClick={onPayPalClick}
          onPaypalCancel={onPaypalCancel}
          disabled={googleApplePayDisabled}
          onPaypalCreateSubscription={onPaypalCreateSubscription}
          onPayPalError={onPayPalError}
          onPaypalApprove={onPaypalApprove}
        />
        <PayWithCardForm />
      </>
      <CardForm
        onCardCVCChange={onCardCVCChange}
        onCardNumberChange={onCardNumberChange}
        cardNumberFocused={cardNumberFocused}
        onCardExpirationDateChange={onCardExpirationDateChange}
        cardExpirationDateFocused={cardExpirationDateFocused}
        cardExpirationDateError={cardExpirationDateError}
        cardCVCFocused={cardCVCFocused}
        onCardCVCFocusChange={onCardCVCFocusChange}
        onCardNumberFocusChange={onCardNumberFocusChange}
        cardCVCError={cardCVCError}
        cardNumberError={cardNumberError}
        onCardExpirationDateFocusChange={onCardExpirationDateFocusChange}
      />
    </FlexDiv>
  );
};

export default React.memo(CheckoutForm);
