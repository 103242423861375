import { useEffect, useState } from "react";

const breakPoint = 780;

export function useIsDesktop() {
  const [width, setWidth] = useState(window.innerWidth);
  const [isDesktop, setIsDesktop] = useState(width > breakPoint);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    setIsDesktop(width > breakPoint);
  }, [width]);

  return isDesktop;
}
