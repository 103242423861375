import isEqual from "lodash.isequal";
import { UIAction } from "models/interfaces";
import { StoreActionTypes, UIActionTypes } from "models/store/actionTypes";
import { UIActions } from "models/store/uiActions";

export interface UIState {
  loader: {
    actions: UIAction[];
    refreshing: StoreActionTypes[];
  };
  errorActions: StoreActionTypes[];
}

export const initialState: UIState = {
  loader: {
    actions: [],
    refreshing: [],
  },
  errorActions: [],
};

const uiReducer = (state = initialState, action: UIActions): UIState => {
  const { loader } = state;
  const { actions, refreshing } = loader;
  switch (action.type) {
    case UIActionTypes.START_ACTION:
      return {
        ...state,
        loader: { ...loader, actions: [...actions, action.payload.action] },
      };
    case UIActionTypes.STOP_ACTION:
      return {
        ...state,
        loader: {
          ...loader,
          actions: actions.filter((addedActions) => {
            if (addedActions.name !== action.payload.action.name) {
              return true;
            } else {
              return !isEqual(addedActions.params, action.payload.action.params);
            }
          }),
        },
      };
    case UIActionTypes.REFRESH_ACTION_START:
      return {
        ...state,
        loader: { ...loader, refreshing: [...refreshing, action.payload.refreshAction] },
      };
    case UIActionTypes.REFRESH_ACTION_STOP:
      return {
        ...state,
        loader: { ...loader, refreshing: refreshing.filter((refresh) => refresh !== action.payload.refreshAction) },
      };
    case UIActionTypes.ADD_ERROR_ACTION:
      return { ...state, errorActions: [...state.errorActions, action.payload.errorAction] };
    case UIActionTypes.CLEAR_ERROR_ACTIONS:
      return { ...state, errorActions: [] };
    default:
      return state;
  }
};

export default uiReducer;
