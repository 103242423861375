import React, { useEffect } from "react";
import styles from "components/PaymentMethods/PaymentMethods.module.css";
import { CardForm, FlexDiv, PaymentCard } from "components/index";
import { Icons } from "models/enums";
import CardSecurePaymentHeader from "components/CardSecurePaymentHeader/CardSecurePaymentHeader";
import PaymentFooter from "components/PaymentFooter/PaymentFooter";
import { useIsDesktop } from "utils/hooks/useIsDesktop";
import { useCardForm } from "utils/hooks/useCardForm";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";
import { CanMakePaymentWithGoogleApplePay } from "models/interfaces";

interface PaymentMethodsProps {
  onGoogleApplePayClick: () => void;
  onCreditCardClick: () => void;
  onPayPalClick: () => void;
  canMakePayment: CanMakePaymentWithGoogleApplePay | null;
  isGoogleApplePaySelected: boolean;
  isCreditCardSelected: boolean;
  isPayPalSelected: boolean;
  updateCardFromStatus: (isValid: boolean) => void;
  showGoogleApplePay?: boolean;
  showPaypal?: boolean;
}

const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  isPayPalSelected,
  onPayPalClick,
  onGoogleApplePayClick,
  isGoogleApplePaySelected,
  isCreditCardSelected,
  onCreditCardClick,
  updateCardFromStatus,
  canMakePayment,
  showGoogleApplePay = true,
  showPaypal = true,
}) => {
  const isDesktop = useIsDesktop();
  const { onCardFormFocusChange, onCardFormChange, cardForm, validateCard } = useCardForm();
  const isValidCardForm = validateCard();
  const { t } = useTypedTranslation();

  useEffect(() => {
    updateCardFromStatus(isValidCardForm);
  }, [isValidCardForm]);

  return (
    <>
      {showGoogleApplePay && canMakePayment && (
        <PaymentCard
          title={!canMakePayment.googlePay ? "Apple Pay" : "Google Pay"}
          icon={!canMakePayment.googlePay ? Icons.APPLE_PAY_SMALL : Icons.GOOGLE_PAY_SMALL}
          onClick={onGoogleApplePayClick}
          isSelected={isGoogleApplePaySelected}
        />
      )}
      <PaymentCard
        title={t("creditOrDebitCard")}
        icon={Icons.CREDIT_CARDS}
        onClick={onCreditCardClick}
        isSelected={isCreditCardSelected}
      >
        {isDesktop && (
          <>
            <CardSecurePaymentHeader title={""} />
            <CardForm
              className={styles.cardForm}
              onCardNumberFocusChange={onCardFormFocusChange("cardNumber")}
              onCardCVCFocusChange={onCardFormFocusChange("cardCVC")}
              onCardExpirationDateFocusChange={onCardFormFocusChange("cardExpirationDate")}
              cardNumberError={cardForm.cardNumber.errorMsg}
              cardNumberFocused={cardForm.cardNumber.isFocused}
              cardCVCError={cardForm.cardCVC.errorMsg}
              cardCVCFocused={cardForm.cardCVC.isFocused}
              cardExpirationDateError={cardForm.cardExpirationDate.errorMsg}
              cardExpirationDateFocused={cardForm.cardExpirationDate.isFocused}
              onCardExpirationDateChange={onCardFormChange("cardExpirationDate")}
              onCardCVCChange={onCardFormChange("cardCVC")}
              onCardNumberChange={onCardFormChange("cardNumber")}
            />
          </>
        )}
      </PaymentCard>
      {showPaypal && (
        <PaymentCard title={"PayPal"} icon={Icons.PAY_PAL} onClick={onPayPalClick} isSelected={isPayPalSelected} />
      )}
      {!isDesktop && isCreditCardSelected && (
        <FlexDiv className={styles.cardFormWrapper}>
          <CardSecurePaymentHeader title={t("paymentDetails")} />
          <CardForm
            className={styles.cardForm}
            onCardNumberFocusChange={onCardFormFocusChange("cardNumber")}
            onCardCVCFocusChange={onCardFormFocusChange("cardCVC")}
            onCardExpirationDateFocusChange={onCardFormFocusChange("cardExpirationDate")}
            cardNumberError={cardForm.cardNumber.errorMsg}
            cardNumberFocused={cardForm.cardNumber.isFocused}
            cardCVCError={cardForm.cardCVC.errorMsg}
            cardCVCFocused={cardForm.cardCVC.isFocused}
            cardExpirationDateError={cardForm.cardExpirationDate.errorMsg}
            cardExpirationDateFocused={cardForm.cardExpirationDate.isFocused}
            onCardExpirationDateChange={onCardFormChange("cardExpirationDate")}
            onCardCVCChange={onCardFormChange("cardCVC")}
            onCardNumberChange={onCardFormChange("cardNumber")}
          />
          <PaymentFooter className={styles.paymentFooter} />
        </FlexDiv>
      )}
    </>
  );
};

export default React.memo(PaymentMethods);
