import React, { ChangeEvent, ChangeEventHandler, useRef, useState } from "react";
import styles from "screens/Account/AccountProfile/AccountProfile.module.css";
import { AccountTitle, FlexDiv, TextInput } from "components";
import { useDispatch, useSelector } from "react-redux";
import { emailSelector, userNameSelector, userSelector } from "store/user/userSelectors";
import AccountCard from "components/AccountCard";
import { Routes } from "models/enums";
import UserAvatar from "components/UserAvatar";
import UnderlinedText from "components/UnderlinedText";
import ApiService, { userRequests } from "services/api";
import { getUserInfo, getUserInfoSuccess } from "store/user/userActions";
import { useHistory } from "react-router-dom";
import { validateEmail, validateName, validatePassword } from "utils/helpers";
import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";

interface AccountProfileProps {}
const initialFieldErrorState = {
  firstName: false,
  email: false,
  password: false,
  newPassword: false,
  confirmPassword: false,
};
const AccountProfile: React.FC<AccountProfileProps> = (props) => {
  const { t } = useTypedTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(userSelector);
  const username = useSelector(userNameSelector);
  const userEmail = useSelector(emailSelector);
  const [firstName, setFirstName] = useState(username);
  const [email, setEmail] = useState(userEmail);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [fieldsHaveError, setFieldsHaveError] = useState(initialFieldErrorState);
  const [errorMsg, setErrorMsg] = useState<undefined | string>(undefined);
  const imageUploadRef = useRef<HTMLInputElement>(null);

  function onCancelClick() {
    history.push(Routes.ACCOUNT);
  }

  async function onSaveProfile() {
    try {
      setErrorMsg(undefined);
      setFieldsHaveError(initialFieldErrorState);
      if (!validatePassword(password)) {
        setErrorMsg(t("invalidPassword"));
        setFieldsHaveError((prevState) => ({ ...prevState, password: true }));
      } else {
        setIsLoading(true);
        if (firstName && firstName?.trim() !== username) {
          if (validateUserName()) {
            await ApiService.callApi(userRequests.changeName(firstName, password));
          }
        }
        if (password && (newPassword || confirmNewPassword)) {
          if (validatePasswords()) {
            await ApiService.callApi(userRequests.changePassword(password, newPassword));
          }
        }
        if (email && email?.trim() !== userEmail) {
          if (validateUserEmail()) {
            await ApiService.callApi(userRequests.changeEmail(email, password));
          }
        }
      }
    } catch (error) {
      console.log("onSaveProfile error", error);
    } finally {
      dispatch(getUserInfo());
      setIsLoading(false);
    }
  }

  function validateUserName() {
    if (!validateName(firstName)) {
      setErrorMsg(t("invalidName"));
      setFieldsHaveError((prevState) => ({ ...prevState, name: true }));
      return false;
    }
    setErrorMsg(undefined);
    setFieldsHaveError((prevState) => ({ ...prevState, name: false }));
    return true;
  }

  function validatePasswords() {
    if (!validatePassword(newPassword)) {
      setErrorMsg(t("invalidNewPassword"));
      setFieldsHaveError((prevState) => ({ ...prevState, newPassword: true, confirmPassword: false }));
      return false;
    }
    if (!validatePassword(confirmNewPassword)) {
      setErrorMsg(t("invalidConfirmPassword"));
      setFieldsHaveError((prevState) => ({ ...prevState, newPassword: false, confirmPassword: true }));
      return false;
    }
    if (newPassword !== confirmNewPassword) {
      setErrorMsg(t("passwordsDontMatch"));
      setFieldsHaveError((prevState) => ({ ...prevState, newPassword: true, confirmPassword: true }));
      return false;
    }
    setErrorMsg(undefined);
    setFieldsHaveError((prevState) => ({ ...prevState, newPassword: false, confirmPassword: false }));
    return true;
  }

  function validateUserEmail() {
    if (!validateEmail(email)) {
      setErrorMsg(t("invalidEmail"));
      setFieldsHaveError((prevState) => ({ ...prevState, email: true }));
      return false;
    }
    setErrorMsg(undefined);
    setFieldsHaveError((prevState) => ({ ...prevState, email: false }));
    return true;
  }

  function onChangeAvatar() {
    imageUploadRef.current?.click();
  }

  async function onImageUpload(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files && event.target.files[0] && user) {
      let img = event.target.files[0];
      const response = await ApiService.callApi(userRequests.uploadAvatar(img, user?.id));
      dispatch(getUserInfoSuccess(response.data));
    }
  }

  return (
    <>
      <AccountTitle sectionTitle={t("profile")} />
      <AccountCard
        title={t("profile")}
        isLoading={isLoading}
        buttons={[
          { text: t("cancel"), onClick: onCancelClick, theme: "LIGHT" },
          { text: t("saveProfile"), onClick: onSaveProfile, theme: "DARK" },
        ]}
      >
        <FlexDiv>
          <FlexDiv className={styles.avatarWrapper}>
            <UserAvatar />
            <input
              ref={imageUploadRef}
              type="file"
              accept="image/*"
              onChange={onImageUpload}
              className={styles.uploadFile}
            />
            <UnderlinedText text={t("change")} onClick={onChangeAvatar} className={styles.changeAvatar} />
          </FlexDiv>
          <TextInput
            hasError={fieldsHaveError.firstName}
            value={firstName as string}
            placeholder={t("firstName")}
            onValueChange={setFirstName}
            type={"text"}
          />
          <TextInput
            hasError={fieldsHaveError.email}
            value={email as string}
            placeholder={t("email")}
            onValueChange={setEmail}
            type={"email"}
          />
        </FlexDiv>
        <FlexDiv>
          <p className={styles.updatePassword}>{t("updatePassword")}</p>
          <TextInput
            hasError={fieldsHaveError.password}
            value={password}
            placeholder={t("currentPassword")}
            onValueChange={setPassword}
            type={"password"}
            autoComplete={"off"}
          />
          <FlexDiv className={styles.newPasswordWrapper}>
            <TextInput
              value={newPassword}
              hasError={fieldsHaveError.newPassword}
              placeholder={t("newPassword")}
              onValueChange={setNewPassword}
              type={"password"}
            />
            <FlexDiv className={styles.confirmPasswordSeparator} />
            <TextInput
              value={confirmNewPassword}
              hasError={fieldsHaveError.confirmPassword}
              placeholder={t("confirmNewPassword")}
              onValueChange={setConfirmNewPassword}
              type={"password"}
            />
          </FlexDiv>
          <InputErrorMessage errorMessage={errorMsg} />
        </FlexDiv>
      </AccountCard>
    </>
  );
};

export default React.memo(AccountProfile);
