import React, { useRef, useEffect, useMemo, useState } from "react";
import {
  CreateSubscriptionActions,
  OnApproveActions,
  OnApproveData,
  OnClickActions,
} from "@paypal/paypal-js/types/components/buttons";
import { loadScript, PayPalNamespace } from "@paypal/paypal-js";
import { useMount } from "react-use";

interface PayPalProps {
  onPaypalApprove: (data: OnApproveData, actions: OnApproveActions) => Promise<void>;
  onPaypalCreateSubscription: (data: Record<string, unknown>, actions: CreateSubscriptionActions) => Promise<string>;
  onPayPalError?: (error: Record<string, unknown>) => void;
  onPaypalCancel?: () => void;
  onPayPalClick: (data: Record<string, unknown>, actions: OnClickActions) => void;
  disabled?: boolean;
  forceReRender?: number;
}

const PayPal: React.FC<PayPalProps> = ({
  onPaypalCancel,
  onPaypalApprove,
  onPaypalCreateSubscription,
  onPayPalClick,
  onPayPalError,
  disabled,
  forceReRender,
}) => {
  const [paypal, setPaypal] = useState<null | PayPalNamespace>(null);
  const key = useMemo(Date.now, [forceReRender]);

  async function loadPaypalScript() {
    try {
      const paypalScript = await loadScript({
        "client-id": process.env.REACT_APP_PAY_PAL_CLIENT_ID,
        intent: "subscription",
        vault: true,
      });
      setPaypal(paypalScript);
    } catch (error) {
      console.error("failed to load the PayPal JS SDK script", error);
    }
  }
  useMount(() => {
    loadPaypalScript();
  });

  useEffect(() => {
    if (paypal && paypal.Buttons) {
      paypal
        .Buttons({
          onInit: (data, actions) => {
            // Disable the buttons
            if (disabled) {
              actions.disable();
            }
          },
          createSubscription: onPaypalCreateSubscription,
          onApprove: onPaypalApprove,
          onError: onPayPalError,
          onClick: onPayPalClick,
          onCancel: onPaypalCancel,
          style: {
            shape: "pill",
            color: disabled ? "silver" : "white",
            layout: "horizontal",
            label: undefined,
            tagline: false,
            height: 55,
          },
        })
        .render("#paypal-button-container");
    }
  }, [paypal, disabled, key]);
  // TODO remove paypal
  return null;
  return <div id="paypal-button-container" key={key}></div>;
};

export default React.memo(PayPal);
