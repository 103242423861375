import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer, { StoreState } from "store/rootReducer";
import rootSaga from "store/rootSaga";
import createSagaMiddleware from "redux-saga";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { PersistConfig, Persistor } from "redux-persist/es/types";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// redux-persist
const persistConfig: PersistConfig<any> = {
  key: "amo.web",
  storage,
  version: 0,
  blacklist: ["ui"],
  stateReconciler: autoMergeLevel2,
};

// @ts-ignore
const persistedReducer = persistReducer<StoreState>(persistConfig, rootReducer);

let persistor: Persistor | null = null;

export function getPersistor(): Persistor {
  return persistor as Persistor;
}

function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware];
  const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middlewares)));
  persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);
  return store;
}

const store = configureStore();
export default store;
