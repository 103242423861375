import { ApiRequest, AuthenticationResponse, UserInfo } from "models/interfaces";
const prefix: string = "users";

const register = (
  email: string,
  password: string,
  name: string,
  coachId: number
): ApiRequest<AuthenticationResponse> => ({
  url: `${prefix}/register`,
  options: {
    method: "POST",
    validateStatus: (status) => [200, 409].includes(status),
    data: { email, password, name, coachId, registerOrigin: "web" },
  },
  includeAuthorizationHeader: false,
});

const login = (email: string, password: string): ApiRequest<AuthenticationResponse> => ({
  url: `${prefix}/login`,
  options: {
    method: "POST",
    validateStatus: (status) => [200, 401].includes(status),
    data: { email, password },
  },
  includeAuthorizationHeader: false,
});

const emailExists = (email: string): ApiRequest<{ isEmailExists: boolean }> => ({
  url: `${prefix}/checkEmail?email=${encodeURIComponent(email)}`,
  options: { method: "GET" },
  includeAuthorizationHeader: false,
});

const forgotPassword = (email: string): ApiRequest<{ message: string }> => ({
  url: `${prefix}/password/forgot`,
  options: { method: "PUT", data: { email }, validateStatus: (status) => [200, 404].includes(status) },
  includeAuthorizationHeader: false,
});

const getUserInfo = (): ApiRequest<UserInfo> => ({
  url: `${prefix}/me`,
  options: { method: "GET" },
  includeAuthorizationHeader: true,
});

const uploadAvatar = (file: File, userId: string): ApiRequest<UserInfo> => {
  const formData = new FormData();
  formData.append("file", file);
  return {
    url: `${prefix}/avatar`,
    options: { method: "POST", data: formData },
    includeAuthorizationHeader: true,
  };
};

const changePassword = (password: string, newPassword: string): ApiRequest<{ message: string }> => ({
  url: `${prefix}/password/change`,
  options: { method: "PUT", data: { password, newPassword } },
  includeAuthorizationHeader: true,
});

const changeEmail = (email: string, password: string): ApiRequest<{ message: string }> => ({
  url: `${prefix}/email/change`,
  options: { method: "PUT", data: { email, password } },
  includeAuthorizationHeader: true,
});

const changeName = (name: string, password: string): ApiRequest<{ message: string }> => ({
  url: `${prefix}/name/change`,
  options: { method: "PUT", data: { name, password } },
  includeAuthorizationHeader: true,
});

export const userRequests = {
  register,
  login,
  emailExists,
  forgotPassword,
  uploadAvatar,
  getUserInfo,
  changeEmail,
  changeName,
  changePassword,
};
