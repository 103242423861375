import { useEffect } from "react";

export function useWindowFocus(enabled: boolean, onFocus: () => void, onBlur?: () => void) {
  useEffect(() => {
    if (enabled) {
      window.addEventListener("focus", onFocus);
      onBlur && window.addEventListener("blur", onBlur);
      // Specify how to clean up after this effect:
      return () => {
        window.removeEventListener("focus", onFocus);
        onBlur && window.removeEventListener("blur", onBlur);
      };
    }
  }, [enabled]);
}
