import React from "react";
import styles from "components/DownloadAmo/DownloadAmo.module.css";
import FlexDiv from "components/FlexDiv";
import DownloadAmoButton from "components/DownloadAmoButton/DownloadAmoButton";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";

interface DownloadAmoProps {}

const DownloadAmo: React.FC<DownloadAmoProps> = (props) => {
  const { t } = useTypedTranslation();
  return (
    <FlexDiv className={styles.container}>
      <p className={styles.title}>{t("downloadAmoToday")}</p>
      <DownloadAmoButton type={"APPLE"} />
      <DownloadAmoButton type={"GOOGLE"} className={styles.googleButton} />
      <FlexDiv className={styles.bottom} />
    </FlexDiv>
  );
};

export default React.memo(DownloadAmo);
