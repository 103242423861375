import { ProductActionTypes } from "models/store/actionTypes";
import { Price, Product } from "models/interfaces";
import { ProductActions } from "models/store/productActions";

export interface ProductState {
  products: Price[];
  activeProduct: Product | null;
}

const initialState: ProductState = {
  products: [],
  activeProduct: null,
};

const productReducer = (state = initialState, action: ProductActions): ProductState => {
  switch (action.type) {
    case ProductActionTypes.GET_PRODUCTS_SUCCESS:
      return { ...state, products: action.payload.products };
    case ProductActionTypes.SET_ACTIVE_PRODUCT:
      return { ...state, activeProduct: action.payload.product };
    default:
      return state;
  }
};

export default productReducer;
