export const privacyPolicyLink = "https://www.amocoach.com/company/privacy-policy-en";
export const termsAndConditionsLink = "https://www.amocoach.com/company/terms-of-use";
export const appStoreLink = "https://apps.apple.com/us/app/amo-personal-trainer/id1443806952";
export const googlePlayStoreLink = "https://play.google.com/store/apps/details?id=com.amo&hl=hr&gl=US";
export const dateFormat = "d MMM yyyy";

export const paypalSandboxProductIds = ["PROD-2CY17924LS410874R"];
export const paypalTrialMonthly = process.env.REACT_APP_PAYPAL_TRIAL_MONTHLY;
export const paypalTrialQuarterly = process.env.REACT_APP_PAYPAL_TRIAL_QUARTERLY;
export const paypalTrialYearly = process.env.REACT_APP_PAYPAL_TRIAL_YEARLY;

export const paypalMonthly = process.env.REACT_APP_PAYPAL_MONTHLY;
export const paypalQuarterly = process.env.REACT_APP_PAYPAL_QUARTERLY;
export const paypalYearly = process.env.REACT_APP_PAYPAL_YEARLY;

export const paypalTrialPlanIds = [paypalTrialMonthly, paypalTrialQuarterly, paypalTrialYearly];
export const paypalPlanIds = [paypalMonthly, paypalQuarterly, paypalYearly];
