import classNames from "classnames";
import FlexDiv from "components/FlexDiv";
import React from "react";
import styles from "components/TrialText/TrialText.module.css";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";
interface TrialTextProps {
  days: number;
  type?: "short";
  className?: string;
}
const TrialText: React.FC<TrialTextProps> = ({ type, days, className }) => {
  const { t } = useTypedTranslation();

  if (type === "short") {
    return (
      <FlexDiv className={classNames(styles.container, className)}>
        <p className={classNames(styles.shortDaysMain)}>{`${days}`}</p>{" "}
        <p className={classNames(styles.shortText)}>{t("daysFree")}</p>
      </FlexDiv>
    );
  }

  return (
    <FlexDiv className={classNames(styles.container, className)}>
      <p className={classNames(styles.daysMain)}>{days}</p>
      <p className={classNames(styles.text)}>{t("dayFreeTrial")}</p>
    </FlexDiv>
  );
};

export default React.memo(TrialText);
