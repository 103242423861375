import React from "react";
import styles from "components/TotalAmount/TotalAmount.module.css";
import FlexDiv from "components/FlexDiv";
import classNames from "classnames";
interface TotalAmountProps {
  text: string;
  price: string;
  bold?: boolean;
}
const TotalAmount: React.FC<TotalAmountProps> = ({ text, price, bold }) => {
  return (
    <FlexDiv className={classNames(styles.container, bold && styles.boldText)}>
      <FlexDiv>{text}</FlexDiv>
      <FlexDiv>{price}</FlexDiv>
    </FlexDiv>
  );
};

export default React.memo(TotalAmount);
