/**
 * DON'T MODIFY MANUALLY
 * This file was automatically generated by generateTranslations.js
 * If you need to modify a translation, modify them in allTranslations.js
 **/

export default {
  translation: {
    invalidUsernamePassword: "Usuário ou senha inválidos",
    emailAlreadyTaken: "Email já registrado",
    login: "Login",
    register: "Registrar",
    iAlreadyHaveAccount: "Já tenho uma conta",
    createNewAccount: "Criar uma nova conta",
    password: "Senha",
    email: "Email",
    name: "Primeiro nome",
    createAccount: "Criar conta",
    forgotPassword: "Esqueceu a senha?",
    byContinueAcceptTerms: "Ao continuar você aceita nosso",
    month: "mês",
    months: "meses",
    xMonths: "{{count}} meses",
    year: "ano",
    monthly: "mensal",
    quarterly: "trimestral",
    yearly: "anual",
    xDaysFreeTrial: "{{trialDays}} dias de teste gratuito. Cancele a qualquer hora!",
    pickYourPlan: "Escolha seu plano e comece seu teste gratuito",
    planByCoach: "{{planDuration}} elaborado por {{coachName}}",
    checkout: "Confirmar compra",
    securePayment: "Pagamento seguro",
    payWithCard: "ou pague com cartão",
    pay: "Pagar",
    paymentSuccess: "O pagamento foi aceito",
    paymentFail: "O pagamento foi recusado",
    goBackToCheckout: "Voltar para o pagamento",
    loginToYourAccount: "Entre na sua conta",
    downloadAmo: "Download o app Amo e comece!",
    downloadAmoToday: "Download o Amo hoje!",
    hiUser: "Olá, {{username}},",
    accountOverview: "Visão geral da conta",
    editProfile: "Editar perfil",
    profile: "Perfil",
    yourPlan: "Seu plano",
    fullAccessToAmo: "Acesso completo ao Amo. Cobrança a cada {{intervalCount}} {{interval}}",
    payment: "Pagamento",
    yourFreeTrialEndsOn: "Seu teste gratuito do {{product}} termina em {{date}}",
    update: "Atualize",
    changePlan: "Alterar o plano",
    getPlan: "Adquira um plano",
    availablePlans: "Planos disponíveis",
    currentPlan: "Plano atual",
    selectPlan: "Selecione um Plano",
    cancelPlan: "Cancele o plano",
    account: "Conta",
    plans: "Planos",
    logout: "Sair",
    firstName: "Nome",
    updatePassword: "Troque a senha",
    currentPassword: "Senha atual",
    newPassword: "Nova senha",
    confirmNewPassword: "Confirme a senha nova",
    saveProfile: "Salve o perfil",
    cancel: "Cancelar",
    change: "Alterar",
    creditOrDebitCard: "Cartão de crédito ou débito",
    updatePaymentDetails: "Atualize os dados de pagamento",
    backToPlans: "Voltar para os planos",
    paymentOptions: "Opções de pagamento",
    changePayment: "Alterar a forma de pagamento",
    youreChangingPlan: "Você vai alterar o plano",
    youreSelectingPlan: "Você selecionou {{planType}}",
    confirm: "Confirma",
    youreCancellingPlan: "Você está cancelando o plano",
    areYouSure: "Tem certeza?",
    ableToUseAmoUntil: "Você poderá usar o Amo até {{date}}",
    confirmCancellation: "Confirmar cancelamento",
    paymentInProgress: "Pagamento em processamento",
    byContinuingYouAccept: "Ao continuar, você aceita a nossa",
    privacyPolicy: "Política de Privacidade",
    and: "e",
    termsOfUse: "os Termos de Uso",
    firstCreateAccount: "Primeiro, crie uma conta",
    yourPaymentSuccessful: "Seu pagamento foi efetuado com sucesso! Baixe o aplicativo e faça login!",
    yourTrialPaymentSuccessful:
      "Seu teste de {{days}} dias começa agora! <strong>Baixe o aplicativo e faça o login!</strong>",
    yourPaymentFailed: "O pagamento foi recusado",
    invalidName: "Nome inválido",
    invalidNewPassword: "Senha nova inválida",
    invalidConfirmPassword: "Confirmação de senha inválida",
    passwordsDontMatch: "Senhas não são iguais",
    invalidEmail: "Email inválido",
    invalidPassword: "Senha inválida",
    paymentDetails: "Detalhes do pagamento",
    youDontHavePlan: "Você não tem acesso a nenhum plano",
    getFullAccessSubscribe: "Adquira acesso completo ao Amo. Inscreva-se hoje!",
    somethingWentWrong: "Algo deu errado",
    enterValidEmail: "Primeiro, digite um email válido",
    yourNextBillFor: "Sua próxima fatura do",
    isOn: "será em",
    yourFreeTrialFor: "Seu teste gratuito do",
    endsOn: "termina em",
    yourSubscriptionFor: "Sua assinatura do",
    planBy: "seu plano",
    planTypeByCoach: "Plano {{planType}}",
    youreChangingPaymentOptionTo: "Você vai alterar a forma de pagamento para {{option}}. A cobrança será em {{date}}.",
    promoCodeNotValid: "O código promocional não é válido",
    afterTrialEndsYouWillBeCharged:
      "Após o término de periodo de teste, será cobrado R${{amountToCharge}} por {{duration}} a partir de {{trialEndDate}}. Cancele a qualquer momento.",
    youWillBeCharged: "Será cobrado R${{amountToCharge}} por {{duration}} a partir de {{firstChargeDate}}.",
    off: "fora",
    afterTrialChargesApply: "Iremos te cobrar após 7 dias de periode de teste. Cancele a qualquer momento.",
    dayFreeTrial: "{{days}} dias de teste gratuito",
    daysFree: "Dias de teste gratuito ",
    dayFreeTrialBold: "<strong>{{days}} dias</strong> de teste gratuito",
    getStartedToday: "Comece hoje",
    getStarted: "Iniciar",
    plansBy: "Planos de {{coachName}}",
    thenPriceByUnit: "Então {{priceUnit}}{{price}}/{{duration}}",
    promoCode: "Código promocional",
    totalAfterTrial: "Total após teste",
    totalAfterToday: "Total devido hoje",
    totalPriceToPay: "Preço total a pagar",
    startTrial: "Iniciar Teste",
    cancelAnytime: "Cancele a qualquer momento!",
    chooseYourPlanTrial: "Escolha seu plano e comece seu teste gratuito de <strong>{{days}} dias!</strong>",
  },
};
