import React from "react";
import styles from "components/Loader/Loader.module.css";
import FlexDiv from "components/FlexDiv/FlexDiv";
import classNames from "classnames";
import ClipLoader from "react-spinners/ClipLoader";
import { VIOLET } from "styles/colors";

interface LoaderProps {
  className?: string;
  size?: number;
}

const Loader: React.FC<LoaderProps> = ({ className, size = 40 }) => {
  return (
    <FlexDiv className={classNames(styles.loader, className)}>
      <ClipLoader color={VIOLET} loading={true} size={size} />
    </FlexDiv>
  );
};

export default React.memo(Loader);
