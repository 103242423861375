import { StoreState } from "store/rootReducer";
import { createSelector } from "reselect";
import { parseStripeProducts } from "utils/helpers";
import { Product } from "models/interfaces";

export const stripeProductsSelector = (store: StoreState) => store.product.products;
export const activeProductSelector = (store: StoreState) => store.product.activeProduct;

export const productsSelector = createSelector([stripeProductsSelector], (products): Product[] =>
  parseStripeProducts(products)
);
