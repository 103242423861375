import React from "react";
import styles from "components/MainLayout/MainLayout.module.css";
import { FlexDiv } from "components";
import classNames from "classnames";

interface MainLayoutProps {
  leftSideClassName?: string;
  rightSideClassName?: string;
  leftSide?: React.ReactNode;
  rightSide: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ leftSide, rightSide, rightSideClassName, leftSideClassName }) => {
  return (
    <FlexDiv className={styles.container}>
      {leftSide && <FlexDiv className={classNames(styles.leftContainer, leftSideClassName)}>{leftSide}</FlexDiv>}
      <FlexDiv className={classNames(styles.rightContainer, rightSideClassName)}>{rightSide}</FlexDiv>
    </FlexDiv>
  );
};

export default React.memo(MainLayout);
