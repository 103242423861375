import {
  ApiRequest,
  Coupon,
  CreatePaymentIntentResponse,
  PaymentMethod,
  Price,
  ProductPaymentIntent,
  Subscription,
} from "models/interfaces";
const prefix: string = "stripe";

const getProducts = (): ApiRequest<{ prices: Price[] }> => ({
  url: `${prefix}/products`,
  options: { method: "GET" },
  includeAuthorizationHeader: false,
});

const getCoupon = (couponId: string, coachId: number, duration: string): ApiRequest<{ coupon?: Coupon }> => ({
  url: `${prefix}/coupon/${coachId}/${duration}/${couponId}`,
  options: { method: "GET" },
  includeAuthorizationHeader: false,
});

const createCustomer = (): ApiRequest<{}> => ({
  url: `${prefix}/customer/create`,
  options: { method: "POST" },
  includeAuthorizationHeader: true,
});

const createPaymentIntent = (item: ProductPaymentIntent): ApiRequest<CreatePaymentIntentResponse> => ({
  url: `${prefix}/paymentIntent/create`,
  options: {
    method: "POST",
    data: { item },
  },
  includeAuthorizationHeader: false,
});

const updatePaymentIntent = (paymentIntentId: string): ApiRequest<CreatePaymentIntentResponse> => ({
  url: `${prefix}/paymentIntent/update`,
  options: {
    method: "PUT",
    data: { paymentIntentId },
  },
  includeAuthorizationHeader: true,
});

const createSubscription = (
  priceId: string,
  paymentMethodId: string,
  coachId: number,
  coupon?: string
  // if freeTrial, there's no clientSecret
): ApiRequest<{ subscriptionId: string; clientSecret?: string }> => ({
  url: `${prefix}/subscription/create`,
  options: {
    method: "POST",
    data: { priceId, paymentMethodId, coachId, coupon },
  },
  includeAuthorizationHeader: true,
});

const migratePaypalSubscriptionToStripe = (
  priceId: string,
  paymentMethodId: string,
  coachId: number
): ApiRequest<{ subscriptionId: string; clientSecret: string }> => ({
  url: `${prefix}/subscription/paypal-migration`,
  options: {
    method: "POST",
    data: { priceId, paymentMethodId, coachId },
  },
  includeAuthorizationHeader: true,
});

const updateSubscription = (
  subscriptionId: string,
  priceId: string,
  coachId: number
): ApiRequest<{ subscription: Subscription }> => ({
  url: `${prefix}/subscription/update`,
  options: {
    method: "PUT",
    data: { priceId, subscriptionId, coachId },
  },
  includeAuthorizationHeader: true,
});

const cancelSubscription = (subscriptionId: string): ApiRequest<{ subscription: Subscription }> => ({
  url: `${prefix}/subscription/cancel`,
  options: {
    method: "PUT",
    data: { subscriptionId },
  },
  includeAuthorizationHeader: true,
});

const getCustomer = (): ApiRequest<{
  paymentMethod: PaymentMethod;
  subscription: Subscription | undefined;
  freeTrialUsed: boolean;
}> => ({
  url: `${prefix}/customer`,
  options: {
    method: "GET",
  },
  includeAuthorizationHeader: true,
});

const updatePaymentMethod = (paymentMethodId: string): ApiRequest<{ paymentMethod: PaymentMethod }> => ({
  url: `${prefix}/paymentMethod/update`,
  options: { method: "PUT", data: { paymentMethodId } },
  includeAuthorizationHeader: true,
});

export const stripeRequests = {
  createCustomer,
  createPaymentIntent,
  getProducts,
  createSubscription,
  cancelSubscription,
  updateSubscription,
  getCustomer,
  migratePaypalSubscriptionToStripe,
  updatePaymentMethod,
  updatePaymentIntent,
  getCoupon,
};
