import React from "react";
import styles from "components/AvailablePlansCard/AvailablePlansCard.module.css";
import AccountCard from "components/AccountCard";
import FlexDiv from "components/FlexDiv";
import AvailablePlanCard from "components/AvailablePlanCard";
import { Product } from "models/interfaces";

interface AvailablePlansCardProps {
  cardTitle: string;
  availablePlans: Product[];
  onSelectPlanClick: (product: Product) => void;
}

const AvailablePlansCard: React.FC<AvailablePlansCardProps> = ({ availablePlans, cardTitle, onSelectPlanClick }) => {
  return (
    <AccountCard title={cardTitle}>
      {availablePlans.map((product) => {
        return (
          <FlexDiv key={product.id}>
            <AvailablePlanCard
              planTitle={`Amo ${product.planType}`}
              planSubtitle={product.description}
              price={product.price}
              priceUnit={product.priceUnit}
              duration={product.duration}
              discount={product.discount}
              onSelectPlanClick={() => onSelectPlanClick(product)}
            />
            <FlexDiv className={styles.separator} />
          </FlexDiv>
        );
      })}
    </AccountCard>
  );
};

export default React.memo(AvailablePlansCard);
