import React from "react";
import styles from "components/AccountForm/AccountForm.module.css";
import { TextInput, FormTitle, FlexDiv, UnderlinedText } from "components";
import classNames from "classnames";
import InputErrorMessage from "components/InputErrorMessage/InputErrorMessage";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";

interface AccountFormProps {
  emailErrorMsg?: string;
  passwordErrorMsg?: string;
  errorMsg?: string;
  isLogin?: boolean;
  onEmailFocusChange: (isFocused: boolean) => void;
  onPasswordFocusChange: (isFocused: boolean) => void;
  onHaveAccountClick: () => void;
  email: string;
  password: string;
  onEmailChange: (value: string) => void;
  onPasswordChange: (value: string) => void;
  className?: string;
  onForgotPasswordClick: () => void;
  name: string;
  onNameFocusChange: (isFocused: boolean) => void;
  onNameChange: (value: string) => void;
  nameErrorMessage?: string;
  showSectionIndex?: boolean;
  onlyLogin?: boolean;
}

const AccountForm: React.FC<AccountFormProps> = (props) => {
  //prettier-ignore
  const {
    emailErrorMsg, passwordErrorMsg, errorMsg, onHaveAccountClick, isLogin, className, password, email,
    onPasswordChange, onEmailChange, onEmailFocusChange, onPasswordFocusChange, showSectionIndex = true,
    onForgotPasswordClick, name, onNameFocusChange, nameErrorMessage,  onNameChange, onlyLogin = false
  } = props;
  const { t } = useTypedTranslation();
  return (
    <FlexDiv className={classNames(styles.container, className)}>
      <FormTitle sectionIndex={showSectionIndex ? 1 : undefined} title={isLogin ? t("login") : t("createAccount")} />
      {!isLogin && (
        <TextInput
          hasError={!!nameErrorMessage}
          value={name}
          onFocus={() => onNameFocusChange(true)}
          onBlur={() => onNameFocusChange(false)}
          onValueChange={onNameChange}
          placeholder={t("name")}
          type={"text"}
        />
      )}
      <TextInput
        hasError={!!emailErrorMsg}
        value={email}
        onFocus={() => onEmailFocusChange(true)}
        onBlur={() => onEmailFocusChange(false)}
        onValueChange={onEmailChange}
        placeholder={t("email")}
        type={"email"}
      />
      <InputErrorMessage errorMessage={errorMsg ? undefined : emailErrorMsg} />
      <TextInput
        hasError={!!passwordErrorMsg}
        value={password}
        onFocus={() => onPasswordFocusChange(true)}
        onBlur={() => onPasswordFocusChange(false)}
        onValueChange={onPasswordChange}
        placeholder={t("password")}
        type={"password"}
      />
      <InputErrorMessage errorMessage={errorMsg || nameErrorMessage || passwordErrorMsg} />
      <FlexDiv className={styles.accountOptionsContainer} style={!isLogin ? { justifyContent: "center" } : undefined}>
        {!onlyLogin && (
          <UnderlinedText onClick={onHaveAccountClick} text={t(isLogin ? "createNewAccount" : "iAlreadyHaveAccount")} />
        )}
        {isLogin && <UnderlinedText text={t("forgotPassword")} onClick={onForgotPasswordClick} />}
      </FlexDiv>
    </FlexDiv>
  );
};

export default React.memo(AccountForm);
