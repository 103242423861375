import React, { ButtonHTMLAttributes } from "react";
import styles from "components/SecondaryButton/SecondaryButton.module.css";
import classNames from "classnames";

interface SecondaryButtonProps {
  buttonText: string;
  className?: string;
  theme?: "DARK" | "LIGHT";
}

const SecondaryButton: React.FC<SecondaryButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({
  buttonText,
  className,
  theme = "DARK",
  ...restProps
}) => {
  return (
    <button
      className={classNames(styles.button, theme === "DARK" ? styles.darkButton : styles.lightButton, className)}
      {...restProps}
    >
      <p className={classNames(styles.buttonText, theme === "DARK" ? styles.darkButtonText : styles.lightButtonText)}>
        {buttonText}
      </p>
    </button>
  );
};

export default React.memo(SecondaryButton);
