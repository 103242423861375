import React from "react";
import { FlexDiv } from "components";
import styles from "components/PriceDuration/PriceDuration.module.css";
import { separateMainPriceAndRestPrice } from "utils/helpers";
import classNames from "classnames";

interface PriceDurationProps {
  priceUnit: string;
  price: number;
  duration: string;
  useDesktopDimensions?: boolean;
  className?: string;
  type?: "small" | "large";
}

const PriceDuration: React.FC<PriceDurationProps> = ({
  duration,
  className,
  priceUnit,
  price,
  useDesktopDimensions,
  type = "large",
}) => {
  if (type === "small") {
    return (
      <FlexDiv className={classNames(className)}>
        <p className={classNames(styles.smallTypeText)}>
          {priceUnit} {price}
        </p>
        <p className={classNames(styles.smallTypeDuration)}>{`/${duration}`}</p>
      </FlexDiv>
    );
  }
  const { priceRest, priceMain } = separateMainPriceAndRestPrice(price);
  return (
    <FlexDiv className={classNames(styles.container, className)}>
      <p className={classNames(styles.priceUnit, useDesktopDimensions ? styles.smallFontSizeDesktop : "")}>
        {priceUnit}
      </p>
      <p className={classNames(styles.priceMain, useDesktopDimensions ? styles.bigFontSizeDesktop : "")}>{priceMain}</p>
      {priceRest && (
        <p className={classNames(styles.priceRest, useDesktopDimensions ? styles.priceRestDesktop : "")}>{priceRest}</p>
      )}
      <p
        className={classNames(styles.duration, useDesktopDimensions ? styles.smallFontSizeDesktop : "")}
      >{`/${duration}`}</p>
    </FlexDiv>
  );
};

export default React.memo(PriceDuration);
