import React from "react";
import styles from "components/InputErrorMessage/InputErrorMessage.module.css";

interface InputErrorMessageProps {
  errorMessage?: string;
}

const InputErrorMessage: React.FC<InputErrorMessageProps> = ({ errorMessage }) => {
  if (!errorMessage) {
    return null;
  }
  return <p className={styles.cardValidationError}>{errorMessage}</p>;
};

export default React.memo(InputErrorMessage);
