import React from "react";
import styles from "components/PaymentDetails/PaymentDetails.module.css";
import FlexDiv from "components/FlexDiv/FlexDiv";
import UnderlinedText from "components/UnderlinedText/UnderlinedText";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { Routes, SubscriptionStatus } from "models/enums";
import { useHistory } from "react-router-dom";
import UserCardDetailsPreview from "components/UserCardDetailsPreview";
import { hasSubscription } from "utils/helpers";
import { userSubscriptionSelector } from "store/user/userSelectors";
import { dateFormat } from "models/data";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";

interface PaymentDetailsProps {}

const PaymentDetails: React.FC<PaymentDetailsProps> = (props) => {
  const history = useHistory();
  const { t } = useTypedTranslation();
  const subscription = useSelector(userSubscriptionSelector);
  if (!subscription || !hasSubscription(subscription?.status, subscription.nextBillDateUnix)) {
    return null;
  }

  const { nextBillDateUnix } = subscription;
  const { priceUnit, price } = subscription?.product || {};
  const paymentDue = hasSubscription(subscription?.status) ? (
    subscription.status === SubscriptionStatus.ACTIVE ? (
      <p className={styles.paymentInfoSubtitle}>
        {t("yourNextBillFor")}{" "}
        <strong>
          {priceUnit}
          {price}
        </strong>{" "}
        {t("isOn")} <strong>{format(nextBillDateUnix, dateFormat)}</strong>
      </p>
    ) : (
      <p className={styles.paymentInfoSubtitle}>
        {t("yourFreeTrialFor")}{" "}
        <strong>
          {priceUnit}
          {price}
        </strong>{" "}
        {t("endsOn")} <strong>{format(nextBillDateUnix, dateFormat)}</strong>
      </p>
    )
  ) : (
    <p className={styles.paymentInfoSubtitle}>
      {t("yourSubscriptionFor")}{" "}
      <strong>
        {priceUnit}
        {price}
      </strong>{" "}
      {t("endsOn")} <strong>{format(nextBillDateUnix, dateFormat)}</strong>
    </p>
  );

  const onPaymentUpdateClick = () => {
    history.push(Routes.ACCOUNT_PLANS_PAYMENT);
  };

  return (
    <FlexDiv className={styles.paymentContainer}>
      <FlexDiv className={styles.paymentInfoContainer}>
        <p className={styles.paymentInfoTitle}>{t("payment")}</p>
        {paymentDue}
      </FlexDiv>
      <FlexDiv className={styles.paymentCardContainer}>
        <UserCardDetailsPreview />
        {hasSubscription(subscription.status) && (
          <UnderlinedText text={t("update")} onClick={onPaymentUpdateClick} className={styles.update} />
        )}
      </FlexDiv>
    </FlexDiv>
  );
};

export default React.memo(PaymentDetails);
