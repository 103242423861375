import React from "react";
import styles from "components/AvatarAmo/AvatarAmo.module.css";
import { FlexDiv, PriceDuration } from "components";
import { Icons } from "models/enums";
import classNames from "classnames";
import { useIsDesktop } from "utils/hooks/useIsDesktop";
import { fitImageToScreen } from "utils/helpers";
import { useResizeDetector } from "react-resize-detector";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";
import { useSelector } from "react-redux";
import { selectedCoachSelector } from "store/coach/coachSelectors";
import { FREE_TRIAL_DAYS } from "models/constants";
import TrialText from "components/TrialText";

interface AvatarAmoProps {
  className?: string;
  isPlansScreen?: boolean;
  coachName?: string;
  duration?: string;
  price?: number;
  priceUnit?: string;
  planType?: string;
  useDesktopDimensions?: boolean;
  freeTrialUsed?: boolean;
}

const AvatarAmo: React.FC<AvatarAmoProps> = ({
  className,
  useDesktopDimensions,
  isPlansScreen,
  price,
  priceUnit,
  duration,
  coachName,
  planType,
  freeTrialUsed,
}) => {
  const { t } = useTypedTranslation();
  const selectedCoach = useSelector(selectedCoachSelector);
  const { width, height, ref } = useResizeDetector();
  const isDesktop = useIsDesktop();
  const coachImgDimensions = fitImageToScreen(
    isDesktop ? 461 : 174,
    isDesktop ? 614 : 232,
    width || window.innerWidth * (isDesktop ? 1 : 0.4),
    height || window.innerHeight * (isDesktop ? 0.7 : 1)
  );

  return (
    <FlexDiv
      className={classNames(isDesktop ? styles.avatarPlanContainerDesktop : styles.avatarPlanContainer, className)}
    >
      <FlexDiv className={isDesktop ? styles.planContainerDesktop : styles.planContainer}>
        <img src={Icons.AMO} className={styles.amoLogo} />
        {isPlansScreen && (
          <FlexDiv className={isDesktop ? styles.planTextWrapper : ""}>
            <FlexDiv>
              <p className={styles.plansBy}>
                {t("plansBy")} {selectedCoach?.name}
              </p>
              <TrialText days={FREE_TRIAL_DAYS} />
              <p className={styles.thenPriceByUnit}>{t("afterTrialChargesApply")}</p>
            </FlexDiv>
          </FlexDiv>
        )}
        {!isPlansScreen && duration && price && priceUnit && (
          <FlexDiv className={isDesktop ? styles.planTextWrapper : ""}>
            <p className={styles.yearlyPlan}>{t("planTypeByCoach", { planType, coachName })}</p>
            {freeTrialUsed ? (
              <>
                <PriceDuration
                  useDesktopDimensions={useDesktopDimensions}
                  duration={duration}
                  price={price}
                  priceUnit={priceUnit}
                />
                <p className={styles.thenPriceByUnit}>{t("cancelAnytime")}</p>
              </>
            ) : (
              <>
                <TrialText type="short" days={FREE_TRIAL_DAYS} />
                <p className={styles.thenPriceByUnit}>{t("thenPriceByUnit", { priceUnit, price, duration })}</p>
              </>
            )}
          </FlexDiv>
        )}
      </FlexDiv>

      <FlexDiv ref={ref} className={isDesktop ? styles.coachContainerDesktop : styles.coachContainer}>
        <img
          src={selectedCoach?.cover}
          alt={"Coach Cover"}
          style={{
            height: coachImgDimensions.height,
            objectFit: "contain",
            width: "100%",
            marginTop: "100%",
          }}
        />
      </FlexDiv>
    </FlexDiv>
  );
};

export default React.memo(AvatarAmo);
