import React from "react";
import styles from "components/AccountTitle/AccountTitle.module.css";
import FlexDiv from "components/FlexDiv";
import { useSelector } from "react-redux";
import { userNameSelector } from "store/user/userSelectors";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";

interface AccountTitleProps {
  sectionTitle: string;
}

const AccountTitle: React.FC<AccountTitleProps> = ({ sectionTitle }) => {
  const username = useSelector(userNameSelector);
  const { t } = useTypedTranslation();
  return (
    <FlexDiv className={styles.container}>
      <p className={styles.greetings}>{t("hiUser", { username })}</p>
      <p className={styles.title}>{sectionTitle}</p>
    </FlexDiv>
  );
};

export default React.memo(AccountTitle);
