import React from "react";
import { AvatarAmo, MainLayout, PaymentFeedback } from "components";
import { generatePath, useHistory, useLocation } from "react-router-dom";
import { MixpanelEvent, Routes } from "models/enums";
import { PaymentCheckoutState } from "models/interfaces";
import { useSelector } from "react-redux";
import { activeProductSelector } from "store/products/productSelectors";
import { useIsDesktop } from "utils/hooks/useIsDesktop";
import { selectedCoachSelector } from "store/coach/coachSelectors";
import mixpanel from "mixpanel-browser";
import { useMount } from "react-use";
import { localizePlanDuration } from "utils/helpers";
import { FacebookPixelEvent } from "models/enums";
import ReactPixel from "react-facebook-pixel";

const PaymentCheckout = () => {
  const history = useHistory();
  const product = useSelector(activeProductSelector);
  const isDesktop = useIsDesktop();
  const selectedCoach = useSelector(selectedCoachSelector);
  const coachName = selectedCoach?.name;
  const { state } = useLocation<PaymentCheckoutState>();

  useMount(() => {
    mixpanel.track(state.success ? MixpanelEvent.PAYMENT_PLAN_PAY_SUCCESS : MixpanelEvent.PAYMENT_PLAN_PAY_FAIL, {
      plan: product?.productType,
    });
    ReactPixel.track(FacebookPixelEvent.StartTrial, { product: product?.productType });
  });

  if (!state || !product) {
    history.push(Routes.PLANS);
    return null;
  }

  function onGoBackClick() {
    history.push(
      generatePath(Routes.PAYMENT, {
        planInterval: product?.productType.toLowerCase() || "",
        coachName: selectedCoach?.name || "",
      })
    );
  }

  function onLoginClick() {
    history.push(Routes.AUTHENTICATION_LOGIN);
  }

  const onAppStoreDownloadClick = () => {
    mixpanel.track(MixpanelEvent.DOWNLOAD_APP_STORE, {
      email: state.email || "",
    });
  };

  const onGooglePlayDownloadClick = () => {
    mixpanel.track(MixpanelEvent.DOWNLOAD_GOOGLE_PLAY, {
      email: state.email || "",
    });
  };

  return (
    <MainLayout
      leftSide={
        isDesktop ? (
          <AvatarAmo
            planType={product.planType}
            useDesktopDimensions={isDesktop}
            price={state.priceToPay || product.price}
            priceUnit={product.priceUnit}
            duration={localizePlanDuration(product.duration)}
            coachName={coachName}
            freeTrialUsed={!state.isFreeTrial}
          />
        ) : null
      }
      rightSide={
        <PaymentFeedback
          onGooglePlayDownloadClick={onGooglePlayDownloadClick}
          onAppStoreDownloadClick={onAppStoreDownloadClick}
          success={state?.success}
          onGoBackClick={onGoBackClick}
          onLoginClick={onLoginClick}
          duration={state.duration}
          amountToCharge={state.priceToPay}
          trialEndDate={state.trialEndDate}
          freeTrialUsed={!state.isFreeTrial}
        />
      }
    />
  );
};

export default React.memo(PaymentCheckout);
