import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { setUserToken } from "store/user/userActions";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activeProductSelector } from "store/products/productSelectors";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";
import { clientSecretSelector, paymentIntentIdSelector, paymentMethodSelector } from "store/stripe/stripeSelectors";
import ApiService, { stripeRequests } from "services/api";
import { Product } from "models/interfaces";
import { Routes } from "models/enums";
import { useHistory, useLocation } from "react-router-dom";
import { PaymentRequestPaymentMethodEvent } from "@stripe/stripe-js";
import { emailSelector } from "store/user/userSelectors";
import { translate } from "utils/i18n";
import { getStripeCustomerData, setPaymentMethod } from "store/stripe/stripeActions";
import { paypalSubscriptionSelector } from "store/paypal/paypalSelectors";
import { hasSubscription, parseCardDetails } from "utils/helpers";
import { selectedCoachSelector } from "store/coach/coachSelectors";

const toastId = "payment";
export function useProcessStripePayment(onTransactionDone?: (success: boolean, email?: string) => void) {
  const { t } = useTypedTranslation();
  const product = useSelector(activeProductSelector);
  const clientSecret = useSelector(clientSecretSelector);
  const selectedCoach = useSelector(selectedCoachSelector);
  const paymentIntentId = useSelector(paymentIntentIdSelector);
  const paymentMethod = useSelector(paymentMethodSelector);
  const paypalSubscription = useSelector(paypalSubscriptionSelector);
  const userEmail = useSelector(emailSelector);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const isPaymentInDashboard = !location.pathname.includes(Routes.PAYMENT.substr(0, Routes.PAYMENT.length - 3));

  async function processStripePaymentInDashboardWithExistingCard(selectedProduct: Product) {
    try {
      if (!stripe || !selectedProduct || !paymentMethod || !selectedCoach) {
        return;
      }
      toast.loading(t("paymentInProgress"), { toastId });
      setIsProcessingPayment(true);
      const createSubscriptionResponse = await ApiService.callApi(
        stripeRequests.createSubscription(selectedProduct.id, paymentMethod?.id, selectedCoach?.id)
      );
      if (!createSubscriptionResponse.data.clientSecret) {
        return;
      }
      // Use card Element to tokenize payment details
      const { error, paymentIntent } = await stripe.confirmCardPayment(createSubscriptionResponse.data.clientSecret, {
        payment_method: paymentMethod.id,
      });

      if (error || !paymentIntent) {
        // show error and collect new card details.
        toast.error(t("paymentFail"), { toastId });
        console.log("handlePayment error", error);
        return;
      }
      dispatch(getStripeCustomerData());
    } catch (error) {
      console.log("processStripePaymentInDashboardWithExistingCard error", error);
    } finally {
      toast.dismiss(toastId);
      setIsProcessingPayment(false);
    }
  }

  async function processStripePaymentInDashboard(email: string) {
    try {
      const cardElement = elements?.getElement(CardNumberElement);
      if (!stripe || !clientSecret || !cardElement || !product || !selectedCoach) {
        return;
      }
      toast.loading(t("paymentInProgress"), { toastId });
      setIsProcessingPayment(true);
      await ApiService.callApi(stripeRequests.createCustomer());

      const createPaymentMethodResponse = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: { email },
      });
      if (!createPaymentMethodResponse.paymentMethod) {
        return;
      }
      const createSubscriptionResponse = await ApiService.callApi(
        stripeRequests.createSubscription(product.id, createPaymentMethodResponse.paymentMethod?.id, selectedCoach?.id)
      );
      // Use card Element to tokenize payment details
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        // if freeTrial, there's no clientSecret
        createSubscriptionResponse.data.clientSecret || clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: { email },
          },
        }
      );

      if (error || !paymentIntent) {
        if (error?.decline_code === "insufficient_funds") {
          const customerResponse = await ApiService.callApi(stripeRequests.getCustomer());
          if (hasSubscription(customerResponse.data.subscription?.status)) {
            dispatch(getStripeCustomerData());
            return;
          }
        }
        // show error and collect new card details.
        toast.error(t("paymentFail"), { toastId });
        console.log("handlePayment error", error);
        return;
      }
      dispatch(getStripeCustomerData());
    } catch (error) {
      console.log("processPayment error", error);
    } finally {
      toast.dismiss(toastId);
      setIsProcessingPayment(false);
    }
  }

  async function migratePaypalToStripe() {
    try {
      const cardElement = elements?.getElement(CardNumberElement);
      const product = paypalSubscription?.product;
      if (!stripe || !clientSecret || !cardElement || !product || !selectedCoach) {
        return;
      }
      setIsProcessingPayment(true);
      await ApiService.callApi(stripeRequests.createCustomer());

      const createPaymentMethodResponse = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: { email: userEmail },
      });
      if (!createPaymentMethodResponse.paymentMethod) {
        return;
      }
      const createSubscriptionResponse = await ApiService.callApi(
        stripeRequests.migratePaypalSubscriptionToStripe(
          product.id,
          createPaymentMethodResponse.paymentMethod?.id,
          selectedCoach?.id
        )
      );
      // Use card Element to tokenize payment details
      const { error, paymentIntent } = await stripe.confirmCardPayment(createSubscriptionResponse.data.clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: { email: userEmail },
        },
      });

      if (error || !paymentIntent) {
        // show error and collect new card details.
        console.log("handlePayment error", error);
        return;
      }
      history.push(Routes.ACCOUNT);
      dispatch(getStripeCustomerData());
    } catch (error) {
      console.log("processPayment error", error);
    } finally {
      setIsProcessingPayment(false);
    }
  }

  async function processStripePayment(email: string, couponCode?: string) {
    try {
      const cardElement = elements?.getElement(CardNumberElement);
      if (!stripe || !cardElement || !product || !selectedCoach) {
        return;
      }
      toast.loading(t("paymentInProgress"), { toastId });
      setIsProcessingPayment(true);
      await ApiService.callApi(stripeRequests.createCustomer());

      const createPaymentMethodResponse = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: { email },
      });
      if (!createPaymentMethodResponse.paymentMethod) {
        return;
      }
      const createSubscriptionResponse = await ApiService.callApi(
        stripeRequests.createSubscription(
          product.id,
          createPaymentMethodResponse.paymentMethod?.id,
          selectedCoach?.id,
          couponCode
        )
      );
      // Use card Element to tokenize payment details
      if (createSubscriptionResponse.data.clientSecret) {
        const { error, paymentIntent } = await stripe.confirmCardPayment(
          // if freeTrial, there's no clientSecret
          createSubscriptionResponse.data.clientSecret,
          {
            payment_method: {
              card: cardElement,
              billing_details: { email },
            },
          }
        );
        if (error || !paymentIntent) {
          // show error and collect new card details.
          onTransactionDone && onTransactionDone(false);
          console.log("handlePayment error", error, paymentIntent);
          return;
        }
      }
      onTransactionDone && onTransactionDone(true, email);
    } catch (error) {
      onTransactionDone && onTransactionDone(false);
      console.log("processPayment error", error);
    } finally {
      toast.dismiss(toastId);
      setIsProcessingPayment(false);
      dispatch(setUserToken(undefined));
    }
  }

  async function processStripeGoogleApplePayment(ev: PaymentRequestPaymentMethodEvent, email: string) {
    try {
      if (!stripe || !product || !selectedCoach || !paymentIntentId) {
        return;
      }
      toast.loading(t("paymentInProgress"), { toastId });
      setIsProcessingPayment(true);
      await ApiService.callApi(stripeRequests.createCustomer());
      const updatePaymentIntentResponse = await ApiService.callApi(stripeRequests.updatePaymentIntent(paymentIntentId));
      const createSubscriptionResponse = await ApiService.callApi(
        stripeRequests.createSubscription(product.id, ev.paymentMethod.id, selectedCoach?.id)
      );
      if (!createSubscriptionResponse.data.clientSecret) {
        !isPaymentInDashboard && onTransactionDone && onTransactionDone(true);
        ev.complete("success");
        return;
      }
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        updatePaymentIntentResponse.data.clientSecret,
        { payment_method: ev.paymentMethod.id, receipt_email: email },
        { handleActions: false }
      );
      if (error) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        console.log("Report to the browser that the payment failed, prompting it to");
        ev.complete("fail");
        return;
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete("success");
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent?.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await stripe.confirmCardPayment(updatePaymentIntentResponse.data.clientSecret);
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
            console.log("ask your customer for a new payment method.");
          } else {
            // The payment has succeeded.
            console.log("The payment has succeeded.1");
          }
        } else {
          // The payment has succeeded.
          console.log("The payment has succeeded.2");
        }
      }

      if (error || !paymentIntent) {
        // show error and collect new card details.
        if (!isPaymentInDashboard) {
          onTransactionDone && onTransactionDone(false);
        } else {
          toast.error(translate("paymentFail"), { toastId });
        }
        console.log("handlePayment error", error);
        return;
      }
      if (!isPaymentInDashboard) {
        onTransactionDone && onTransactionDone(true);
        dispatch(setUserToken(undefined));
      } else {
        dispatch(getStripeCustomerData());
      }
    } catch (error) {
      ev.complete("fail");
      !isPaymentInDashboard && onTransactionDone && onTransactionDone(false);
      console.log("processPayment error", error);
    } finally {
      toast.dismiss(toastId);
      setIsProcessingPayment(false);
      !isPaymentInDashboard && dispatch(setUserToken(undefined));
    }
  }

  async function updatePaymentDetailsUsingCard() {
    try {
      setIsProcessingPayment(true);
      const cardElement = elements?.getElement(CardNumberElement);
      if (!stripe || !cardElement) {
        return;
      }
      const createPaymentMethodResponse = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: { email: userEmail },
      });
      if (createPaymentMethodResponse.error) {
        toast.error(createPaymentMethodResponse.error.message);
        return;
      }
      const updatePaymentMethodResponse = await ApiService.callApi(
        stripeRequests.updatePaymentMethod(createPaymentMethodResponse.paymentMethod?.id)
      );
      dispatch(setPaymentMethod(parseCardDetails(updatePaymentMethodResponse.data.paymentMethod)));
      history.push(Routes.ACCOUNT_PLANS);
    } catch (error) {
      console.log("onUpdatePaymentDetailsClick error", error);
    } finally {
      setIsProcessingPayment(false);
    }
  }

  return {
    processStripePaymentInDashboard,
    processStripePayment,
    isProcessingPayment,
    processStripeGoogleApplePayment,
    processStripePaymentInDashboardWithExistingCard,
    migratePaypalToStripe,
    updatePaymentDetailsUsingCard,
  };
}
