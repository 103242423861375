import { StoreState } from "store/rootReducer";
import { createSelector } from "reselect";
import { SubscriptionType, UserPaymentMethod, UserSubscription } from "models/interfaces";
import { paymentMethodSelector, stripeSubscriptionSelector } from "store/stripe/stripeSelectors";
import { paypalSubscriptionSelector } from "store/paypal/paypalSelectors";
import { hasSubscription } from "utils/helpers";

export const userSelector = (store: StoreState) => store.user.userInfo;
export const userTokenSelector = (store: StoreState) => store.user.token;
export const tempUserTokenSelector = (store: StoreState) => store.user.tempToken;
export const userNameSelector = (store: StoreState) => store.user?.userInfo?.name;
export const emailSelector = (store: StoreState) => store.user?.userInfo?.email;
export const userAvatarSelector = (store: StoreState) => store.user?.userInfo?.avatar;

export const userSubscriptionSelector = createSelector(
  [stripeSubscriptionSelector, paypalSubscriptionSelector],
  (stripeSubscription, paypalSubscription): UserSubscription | null => {
    if (hasSubscription(stripeSubscription?.status)) {
      return stripeSubscription;
    }
    if (hasSubscription(paypalSubscription?.status)) {
      return paypalSubscription;
    }
    return stripeSubscription || paypalSubscription;
  }
);

export const userPaymentMethodSelector = createSelector(
  [userSubscriptionSelector, paymentMethodSelector],
  (userSubscription, paymentMethod): UserPaymentMethod | null | "paypal" => {
    if (userSubscription?.type === SubscriptionType.PAYPAL) {
      return "paypal";
    }
    return paymentMethod;
  }
);
