import React from "react";
import "./App.css";
import store, { getPersistor } from "store/configureStore";
import { Provider } from "react-redux";
import AppRouter from "./AppRouter";
import { injectStyle } from "react-toastify/dist/inject-style";
import { PersistGate } from "redux-persist/lib/integration/react";
import { ToastContainer } from "react-toastify";
import "utils/i18n";

const persistor = getPersistor();
// CALL IT ONCE IN YOUR APP
injectStyle();
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer position={"top-center"} autoClose={2000} />
        <AppRouter />
      </PersistGate>
    </Provider>
  );
}

export default React.memo(App);
