import { Coach } from "models/interfaces";
import { CoachActions } from "models/store/coachActions";
import { CoachActionTypes } from "models/store/actionTypes";

export interface CoachState {
  selectedCoach: Coach | null;
  coaches: Coach[];
}

const initialState: CoachState = {
  selectedCoach: null,
  coaches: [],
};

const coachReducer = (state = initialState, action: CoachActions): CoachState => {
  switch (action.type) {
    case CoachActionTypes.GET_ALL_COACHES_SUCCESS:
      return { ...state, coaches: action.payload.coaches };
    case CoachActionTypes.SELECT_COACH:
      return { ...state, selectedCoach: action.payload.selectedCoach };
    default:
      return state;
  }
};

export default coachReducer;
