import { UIAction } from "models/interfaces";
import { StoreActionTypes, UIActionTypes } from "models/store/actionTypes";
import { UIActions } from "models/store/uiActions";

export const startAction = (action: UIAction): UIActions => ({
  type: UIActionTypes.START_ACTION,
  payload: { action },
});

export const stopAction = (action: UIAction): UIActions => ({
  type: UIActionTypes.STOP_ACTION,
  payload: { action },
});

export const refreshActionStart = (refreshAction: StoreActionTypes): UIActions => ({
  type: UIActionTypes.REFRESH_ACTION_START,
  payload: { refreshAction },
});

export const refreshActionStop = (refreshAction: StoreActionTypes): UIActions => ({
  type: UIActionTypes.REFRESH_ACTION_STOP,
  payload: { refreshAction },
});

export const addErrorAction = (errorAction: StoreActionTypes): UIActions => ({
  type: UIActionTypes.ADD_ERROR_ACTION,
  payload: { errorAction },
});

export const clearErrorActions = (): UIActions => ({
  type: UIActionTypes.CLEAR_ERROR_ACTIONS,
  payload: {},
});
