import { CanMakePaymentWithGoogleApplePay } from "models/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { activeProductSelector } from "store/products/productSelectors";
import { useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { PaymentRequest, PaymentRequestPaymentMethodEvent } from "@stripe/stripe-js";
import { setUserToken } from "store/user/userActions";
import { useProcessStripePayment } from "utils/hooks/useProcessStripePayment";
import { useLocation } from "react-router-dom";
import { Routes } from "models/enums";

export function useGoogleApplePay(email: string, enabled: boolean) {
  const product = useSelector(activeProductSelector);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const [canMakePayment, setCanMakePayment] = useState<CanMakePaymentWithGoogleApplePay | null>(null);
  const [googleApplePaymentRequest, setGoogleApplePaymentRequest] = useState<PaymentRequest | null>(null);
  const { processStripeGoogleApplePayment } = useProcessStripePayment();
  const location = useLocation();
  const isPaymentInDashboard = !location.pathname.includes(Routes.PAYMENT.substr(0, Routes.PAYMENT.length - 3));

  useEffect(() => {
    if (stripe) {
      checkIfCanMakePaymentWithGoogleApplePay();
    }
  }, [stripe]);

  useEffect(() => {
    if (googleApplePaymentRequest && enabled) {
      googleApplePaymentRequest?.on("cancel", async () => {
        !isPaymentInDashboard && dispatch(setUserToken(undefined));
      });
      googleApplePaymentRequest?.on("paymentmethod", async (ev: PaymentRequestPaymentMethodEvent) => {
        await processStripeGoogleApplePayment(ev, email);
      });
    }
  }, [enabled, googleApplePaymentRequest]);

  async function checkIfCanMakePaymentWithGoogleApplePay() {
    if (!stripe || !product) {
      return;
    }
    try {
      const paymentRequest = stripe.paymentRequest({
        country: "BR",
        currency: product.currency,
        total: {
          label: product.productType,
          amount: product.unit_amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      // Check the availability of the Payment Request API.
      const result = await paymentRequest.canMakePayment();
      setCanMakePayment(result as unknown as CanMakePaymentWithGoogleApplePay);
      if (result) {
        setGoogleApplePaymentRequest(paymentRequest);
      }
    } catch (error) {
      console.log("checkIfCanMakePayment error", error);
    }
  }

  return { canMakePayment, googleApplePaymentRequest };
}
