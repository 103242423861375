import React from "react";
import styles from "components/PrivacyPolicy/PrivacyPolicy.module.css";
import { privacyPolicyLink, termsAndConditionsLink } from "models/data";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";
interface PrivacyPolicyProps {}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = (props) => {
  const { t } = useTypedTranslation();
  return (
    <p className={styles.footerText}>
      {t("byContinueAcceptTerms")}
      {"  "}
      <strong>
        <a className={styles.link} href={privacyPolicyLink} target={"_blank"} rel="noreferrer">
          {t("privacyPolicy")}
        </a>{" "}
      </strong>{" "}
      {t("and")}
      <strong>
        {" "}
        <a className={styles.link} href={termsAndConditionsLink} target={"_blank"} rel="noreferrer">
          {t("termsOfUse")}
        </a>
      </strong>
    </p>
  );
};

export default React.memo(PrivacyPolicy);
