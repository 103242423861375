import React, { useState } from "react";
import { AccountCard, AccountTitle } from "components";
import { useDispatch, useSelector } from "react-redux";
import { emailSelector, userPaymentMethodSelector, userSubscriptionSelector } from "store/user/userSelectors";
import { useHistory } from "react-router-dom";
import { Routes } from "models/enums";
import PaymentMethods from "components/PaymentMethods";
import styles from "screens/Account/PlanChange/PlanChange.module.css";
import { usePaymentMethods } from "utils/hooks/usePaymentMethods";
import { cancelStripeSubscription, changeStripeSubscription, createPaymentIntent } from "store/stripe/stripeActions";
import { useTypedTranslation } from "utils/hooks/useTypedTranslation";
import { hasSubscription } from "utils/helpers";
import { useGoogleApplePay } from "utils/hooks/useGoogleApplePay";
import { checkIfLoadingSelector } from "store/ui/uiSelectors";
import { PaypalActionTypes, StripeActionTypes } from "models/store/actionTypes";
import { useMount, useUpdateEffect } from "react-use";
import { useProcessStripePayment } from "utils/hooks/useProcessStripePayment";
import UserCardDetailsPreview from "components/UserCardDetailsPreview";
import { format } from "date-fns";
import { activeProductSelector } from "store/products/productSelectors";
import { SubscriptionType } from "models/interfaces";
import { cancelPaypalSubscription, getPaypalSubscription, updatePaypalSubscription } from "store/paypal/paypalActions";
import { useWindowFocus } from "utils/hooks/useWindowFocus";

interface PlanChangeProps {
  type: "change" | "cancel";
}

const PlanChange: React.FC<PlanChangeProps> = ({ type }) => {
  const subscription = useSelector(userSubscriptionSelector);
  const paymentMethod = useSelector(userPaymentMethodSelector);
  const { t } = useTypedTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const email = useSelector(emailSelector);
  const isLoading = useSelector(checkIfLoadingSelector)([
    StripeActionTypes.CANCEL_STRIPE_SUBSCRIPTION,
    StripeActionTypes.CHANGE_STRIPE_SUBSCRIPTION,
    StripeActionTypes.CREATE_SUBSCRIPTION_WITH_EXISTING_PAYMENT_METHOD,
    PaypalActionTypes.CANCEL_PAYPAL_SUBSCRIPTION,
    PaypalActionTypes.UPDATE_PAYPAL_SUBSCRIPTION,
    PaypalActionTypes.GET_PAYPAL_SUBSCRIPTION,
  ]);
  const {
    onGoogleApplePayClick,
    onPayPalClick,
    onCreditCardClick,
    selectedOption,
    isValidCardForm,
    setIsValidCardForm,
  } = usePaymentMethods(subscription?.type === "PAYPAL" ? "payPal" : "creditCard");
  const { canMakePayment, googleApplePaymentRequest } = useGoogleApplePay(email as string, true);
  const isChange = type === "change";
  const showPaymentMethods = !paymentMethod || (paymentMethod === "paypal" && !hasSubscription(subscription?.status));
  const isNewPlan = isChange && showPaymentMethods;
  const product = useSelector(activeProductSelector);
  const [trackPaypalSubscriptionUpdate, setTrackPaypalSubscriptionUpdate] = useState(false);
  const { processStripePaymentInDashboard, isProcessingPayment, processStripePaymentInDashboardWithExistingCard } =
    useProcessStripePayment();
  useWindowFocus(trackPaypalSubscriptionUpdate, () => {
    dispatch(getPaypalSubscription());
  });

  useUpdateEffect(() => {
    onBackToPlanClick();
    setTrackPaypalSubscriptionUpdate(false);
  }, [subscription?.product?.id, subscription?.status]);

  useMount(() => {
    if (product && isNewPlan) {
      dispatch(createPaymentIntent({ intentId: product.id, currency: product.currency, amount: product.unit_amount }));
    }
  });

  if ((isChange && !product) || (!isChange && !subscription)) {
    history.push(Routes.ACCOUNT_PLANS);
    return null;
  }

  function onBackToPlanClick() {
    history.push(Routes.ACCOUNT);
  }

  async function onCancelPlanClick() {
    if (subscription?.type === SubscriptionType.STRIPE) {
      dispatch(cancelStripeSubscription());
    } else {
      dispatch(cancelPaypalSubscription());
    }
  }

  async function onChangePlanClick() {
    if (paymentMethod === "paypal") {
      dispatch(updatePaypalSubscription(() => setTrackPaypalSubscriptionUpdate(true)));
    } else {
      if (hasSubscription(subscription?.status) && product) {
        dispatch(changeStripeSubscription(product));
      } else if (paymentMethod && product) {
        await processStripePaymentInDashboardWithExistingCard(product);
      }
    }
  }

  async function onNewPlanClick() {
    if (selectedOption.creditCard && email) {
      await processStripePaymentInDashboard(email);
    } else if (selectedOption.googleApplePay && canMakePayment) {
      googleApplePaymentRequest?.show();
    } else {
      console.error("not implemented");
    }
  }

  const description =
    isNewPlan || showPaymentMethods ? null : isChange ? (
      <UserCardDetailsPreview />
    ) : subscription?.nextBillDateUnix ? (
      <p className={styles.description}>
        {t("ableToUseAmoUntil", { date: format(subscription?.nextBillDateUnix, "MMM d, yyyy") })}
      </p>
    ) : null;

  const title = isChange ? t("youreSelectingPlan", { planType: `Amo ${product?.planType}` }) : t("areYouSure");
  return (
    <>
      <AccountTitle sectionTitle={t(type === "cancel" ? "youreCancellingPlan" : "youreChangingPlan")} />
      <AccountCard
        isLoading={isLoading || isProcessingPayment}
        title={title}
        buttons={[
          { text: t("backToPlans"), onClick: onBackToPlanClick, theme: "LIGHT" },
          isNewPlan
            ? {
                text: `${t("pay")} ${product?.priceUnit}${product?.price}`,
                onClick: onNewPlanClick,
                theme: "PRIMARY",
                disabled: !isValidCardForm && selectedOption.creditCard,
                renderAsPaypalButton: selectedOption.payPal,
              }
            : {
                text: isChange ? t("confirm") : t("confirmCancellation"),
                onClick: isChange ? onChangePlanClick : onCancelPlanClick,
                theme: "DARK",
              },
        ]}
      >
        {description}
        {showPaymentMethods && (
          <PaymentMethods
            canMakePayment={canMakePayment}
            updateCardFromStatus={setIsValidCardForm}
            onCreditCardClick={onCreditCardClick}
            onPayPalClick={onPayPalClick}
            onGoogleApplePayClick={onGoogleApplePayClick}
            isCreditCardSelected={selectedOption.creditCard}
            isPayPalSelected={selectedOption.payPal}
            isGoogleApplePaySelected={selectedOption.googleApplePay}
          />
        )}
      </AccountCard>
    </>
  );
};

export default React.memo(PlanChange);
