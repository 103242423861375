import memoize from "lodash.memoize";
import { createSelector } from "reselect";
import { StoreState } from "store/rootReducer";
import { exists } from "utils/helpers";
import { UIAction } from "models/interfaces";
import { StoreActionTypes } from "models/store/actionTypes";

const errorActionsSelector = (store: StoreState) => store.ui.errorActions;
const loadingActionsSelector = (store: StoreState) => store.ui.loader.actions;
const refreshingActionsSelector = (store: StoreState) => store.ui.loader.refreshing;

/**
 * @param actionsToCheck Array of strings
 */
export const checkIfLoadingSelector = createSelector([loadingActionsSelector], (loadingActions: UIAction[]) =>
  memoize((actionsToCheck: StoreActionTypes[]) =>
    loadingActions.some((action: UIAction) => actionsToCheck.includes(action.name))
  )
);

/**
 * @param actionToCheck String
 */
export const checkIfRefreshingSelector = createSelector(
  [refreshingActionsSelector],
  (refreshingActions: StoreActionTypes[]) =>
    memoize((actionToCheck: StoreActionTypes) =>
      refreshingActions.some((action: StoreActionTypes) => action === actionToCheck)
    )
);

/**
 * @param actionsToCheck Array of strings
 */
export const checkIfErrorSelector = createSelector([errorActionsSelector], (errorActions: StoreActionTypes[]) =>
  memoize((actionsToCheck: StoreActionTypes[]) => errorActions.some((action) => actionsToCheck.includes(action)))
);

export const updatingItemIdsSelector = createSelector([loadingActionsSelector], (loadingActions: UIAction[]) =>
  memoize((actionsToCheck: StoreActionTypes[]): number[] => {
    return loadingActions
      .filter((action: UIAction) => actionsToCheck.includes(action.name) && exists(action.params?.id))
      .map((action) => action?.params?.id as number);
  })
);

