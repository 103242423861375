import { CoachActionTypes } from "models/store/actionTypes";
import { CoachActions } from "models/store/coachActions";
import { Coach } from "models/interfaces";

export const getAllCoaches = (): CoachActions => ({
  type: CoachActionTypes.GET_ALL_COACHES,
  payload: {},
});

export const getAllCoachesSuccess = (coaches: Coach[]): CoachActions => ({
  type: CoachActionTypes.GET_ALL_COACHES_SUCCESS,
  payload: { coaches },
});

export const selectCoach = (selectedCoach: Coach): CoachActions => ({
  type: CoachActionTypes.SELECT_COACH,
  payload: { selectedCoach },
});
