import { call, put, select, takeLeading } from "typed-redux-saga";
import ApiService, { stripeRequests } from "services/api";
import { StripeActionTypes } from "models/store/actionTypes";
import {
  CancelSubscriptionAction,
  ChangeSubscriptionAction,
  CreatePaymentIntentAction,
  CreateSubscriptionWithExistingPaymentMethodAction,
} from "models/store/stripeActions";
import {
  createPaymentIntentSuccess,
  getStripeCustomerData,
  getStripeCustomerDataSuccess,
  setSubscription,
} from "store/stripe/stripeActions";
import { Product, SubscriptionType, UserSubscription } from "models/interfaces";
import { startAction, stopAction } from "store/ui/uiActions";
import { userSubscriptionSelector } from "store/user/userSelectors";
import { toast } from "react-toastify";
import { translate } from "utils/i18n";
import { productsSelector } from "store/products/productSelectors";
import { parseCardDetails } from "utils/helpers";
import { paymentMethodSelector } from "store/stripe/stripeSelectors";
import { SubscriptionStatus } from "models/enums";
import { selectedCoachSelector } from "store/coach/coachSelectors";
import { Stripe } from "stripe";

const toastId = "payment";
export function* createPaymentIntentSaga(action: CreatePaymentIntentAction) {
  try {
    // Fetches a payment intent and captures the client secret
    const response = yield* call(() => ApiService.callApi(stripeRequests.createPaymentIntent(action.payload)));
    const { clientSecret, paymentIntentId } = response.data;
    yield put(createPaymentIntentSuccess(clientSecret, paymentIntentId));
  } catch (error) {
    console.log("createPaymentIntentSaga error", error);
  }
}

export function* watchCreatePaymentIntentSaga() {
  yield takeLeading(StripeActionTypes.CREATE_PAYMENT_INTENT, createPaymentIntentSaga);
}

export function* cancelSubscriptionSaga(action: CancelSubscriptionAction) {
  try {
    yield put(startAction({ name: action.type }));
    const subscription = yield* select(userSubscriptionSelector);
    if (!subscription) {
      return;
    }
    const response = yield* call(() => ApiService.callApi(stripeRequests.cancelSubscription(subscription?.id)));
    const nextBillDateUnix = response.data.subscription.current_period_end * 1000;
    const newSubscription: UserSubscription = {
      id: response.data.subscription.id,
      nextBillDateUnix,
      product: subscription?.product,
      status: response.data.subscription.status as SubscriptionStatus,
      type: SubscriptionType.STRIPE,
    };
    yield put(setSubscription(newSubscription));
  } catch (error) {
    console.log("cancelSubscriptionSaga error", error);
  } finally {
    yield put(stopAction({ name: action.type }));
  }
}

export function* watchCancelSubscriptionSaga() {
  yield takeLeading(StripeActionTypes.CANCEL_STRIPE_SUBSCRIPTION, cancelSubscriptionSaga);
}

export function* changeSubscriptionSaga(action: ChangeSubscriptionAction) {
  try {
    yield put(startAction({ name: action.type }));
    const subscription = yield* select(userSubscriptionSelector);
    const selectedCoach = yield* select(selectedCoachSelector);
    const { product } = action.payload;
    if (!subscription || !selectedCoach) {
      return;
    }
    const response = yield* call(() =>
      ApiService.callApi(stripeRequests.updateSubscription(subscription?.id, product.id, selectedCoach?.id))
    );
    const nextBillDateUnix = response.data.subscription.current_period_end * 1000;
    const newSubscription: UserSubscription = {
      id: response.data.subscription.id,
      nextBillDateUnix,
      product,
      status: response.data.subscription.status as SubscriptionStatus,
      type: SubscriptionType.STRIPE,
    };
    toast.success(translate("paymentSuccess"), { toastId });
    yield put(setSubscription(newSubscription));
  } catch (error) {
    toast.error(translate("paymentFail"), { toastId });
    console.log("changeSubscriptionSaga error", error);
  } finally {
    yield put(stopAction({ name: action.type }));
  }
}

export function* watchChangeSubscriptionSaga() {
  yield takeLeading(StripeActionTypes.CHANGE_STRIPE_SUBSCRIPTION, changeSubscriptionSaga);
}

export function* createSubscriptionWithExistingPaymentMethodSaga(
  action: CreateSubscriptionWithExistingPaymentMethodAction
) {
  try {
    const paymentMethod = yield* select(paymentMethodSelector);
    const selectedCoach = yield* select(selectedCoachSelector);
    const { product } = action.payload;
    if (!paymentMethod || !selectedCoach) {
      return;
    }
    yield put(startAction({ name: action.type }));
    yield* call(() =>
      ApiService.callApi(stripeRequests.createSubscription(product?.id, paymentMethod.id, selectedCoach?.id))
    );
    toast.success(translate("paymentSuccess"), { toastId });
    yield put(getStripeCustomerData());
  } catch (error) {
    toast.success(translate("paymentFail"), { toastId });
    console.log("changeSubscriptionSaga error", error);
  } finally {
    yield put(stopAction({ name: action.type }));
  }
}

export function* watchCreateSubscriptionWithExistingPaymentMethodSaga() {
  yield takeLeading(
    StripeActionTypes.CREATE_SUBSCRIPTION_WITH_EXISTING_PAYMENT_METHOD,
    createSubscriptionWithExistingPaymentMethodSaga
  );
}

export function* getStripeCustomerDataSaga() {
  try {
    const customerResponse = yield* call(() => ApiService.callApi(stripeRequests.getCustomer()));
    const { paymentMethod, subscription } = customerResponse.data;
    if (subscription) {
      const products = yield* select(productsSelector);
      // @ts-ignore
      const selectedProduct = products.find((p) => p.id === (subscription?.plan as Stripe.Plan)?.id) as Product;
      const nextBillDateUnix = subscription.current_period_end * 1000;
      yield put(
        getStripeCustomerDataSuccess(
          {
            id: subscription.id,
            nextBillDateUnix,
            product: selectedProduct,
            status: subscription.status as SubscriptionStatus,
            type: SubscriptionType.STRIPE,
          },
          parseCardDetails(paymentMethod)
        )
      );
    } else {
      yield put(getStripeCustomerDataSuccess(null, null));
    }
  } catch (error) {
    console.log("getCustomerDataSaga error", error);
  }
}

export function* watchGetStripeCustomerDataSaga() {
  yield takeLeading(StripeActionTypes.GET_STRIPE_CUSTOMER_DATA, getStripeCustomerDataSaga);
}
