import * as EmailValidator from "email-validator";
import { PaymentMethod, Price, Product, ProductInterval, UserPaymentMethod } from "models/interfaces";
import { translate } from "utils/i18n";
import Stripe from "stripe";
import { PayPalSubscriptionStatus, PlanDuration, Routes, SubscriptionStatus } from "models/enums";
import {
  paypalMonthly,
  paypalQuarterly,
  paypalTrialMonthly,
  paypalTrialQuarterly,
  paypalTrialYearly,
  paypalYearly,
} from "models/data";
import { isAfter } from "date-fns";
import { TransKey } from "utils/i18n/allTranslationsTyped";

export function separateMainPriceAndRestPrice(price: number) {
  let priceMain = price;
  let priceRest: number | undefined;
  const dotIndex = price.toString(10).indexOf(".");
  if (dotIndex > 0) {
    priceRest = parseInt(price.toString(10).substring(dotIndex + 1), 10);
    priceMain = parseInt(price.toString(10).substring(0, dotIndex), 10);
  }
  return { priceMain, priceRest };
}

export function getPriceUnit(priceUnit: string) {
  switch (priceUnit) {
    case "brl":
      return "R$";
    default:
      return "$";
  }
}

export function stripePriceToPrice(price: number): number {
  const priceString = price.toString(10);
  return parseFloat(
    `${priceString.substring(0, priceString.length - 2)}.${priceString.substring(priceString.length - 2)}`
  );
}

export function stripeIntervalToDuration(interval: string, intervalCount: number): string {
  if (intervalCount === 1) {
    return interval;
  }
  return `${intervalCount} ${interval}s`;
}

export function validateEmail(email?: string): boolean {
  if (!email) {
    return false;
  }
  return EmailValidator.validate(email);
}
export function validatePassword(password?: string): boolean {
  if (!password) {
    return false;
  }
  return password.length >= 8;
}
export function validateName(name?: string): boolean {
  if (!name) {
    return false;
  }
  if (/\d/.test(name)) {
    return false;
  }
  return name.trim().length >= 3;
}

export function getPlanType(interval: ProductInterval, intervalCount: number) {
  switch (interval) {
    case "month":
      if (intervalCount === 3) {
        return translate(PlanDuration.quarterly);
      }
      return translate(PlanDuration.monthly);
    default:
      return translate(PlanDuration.yearly);
  }
}

export function fitImageToScreen(srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) {
  const widthHeightRatio = srcWidth / srcHeight;
  const heightWidthRatio = srcHeight / srcWidth;

  if (srcHeight > srcWidth) {
    if (srcHeight > maxHeight) {
      return { width: maxHeight * widthHeightRatio, height: maxHeight };
    }
    if (srcWidth > maxWidth) {
      return { width: maxWidth, height: maxWidth * heightWidthRatio };
    }
  }

  if (srcWidth > maxWidth) {
    return { width: maxWidth, height: maxWidth * heightWidthRatio };
  }
  if (srcHeight > maxHeight) {
    return { width: maxHeight * widthHeightRatio, height: maxHeight };
  }
  return { width: srcWidth, height: srcHeight };
}

export function parseCardDetails(paymentMethod: PaymentMethod): UserPaymentMethod | null {
  if (!paymentMethod || !paymentMethod.card) {
    return null;
  }
  return {
    id: paymentMethod.id,
    brand: paymentMethod.card?.brand,
    expMonth: paymentMethod.card?.exp_month,
    expYear: paymentMethod.card?.exp_year,
    last4: paymentMethod.card?.last4,
  };
}

export function exists(obj: any): boolean {
  return obj !== undefined && obj !== null;
}

export function hasSubscription(status: Stripe.Subscription.Status | undefined, nexBillDateUnix?: number) {
  if (!status) {
    return false;
  }
  if (nexBillDateUnix && status === SubscriptionStatus.CANCELLED && isAfter(nexBillDateUnix, new Date())) {
    return true;
  }
  return [SubscriptionStatus.ACTIVE, SubscriptionStatus.FREE_TRIAL].includes(status as SubscriptionStatus);
}

export function getStripeProductByPaypalId(stripeProducts: Product[], planId: string): Product | undefined {
  if ([paypalMonthly, paypalTrialMonthly].includes(planId)) {
    return stripeProducts.find((p) => p.productType.toLowerCase().includes("monthly"));
  } else if ([paypalQuarterly, paypalTrialQuarterly].includes(planId)) {
    return stripeProducts.find((p) => p.productType.toLowerCase().includes("quarterly"));
  } else if ([paypalYearly, paypalTrialYearly].includes(planId)) {
    return stripeProducts.find((p) => p.productType.toLowerCase().includes("yearly"));
  }
  return undefined;
}
export function getPaypalSubscriptionIdByStripeProduct(
  product: Product | undefined | null,
  freeTrialUsed: boolean
): string | undefined {
  if (!product) {
    return undefined;
  }

  if (product.productType.toLowerCase().includes("monthly")) {
    return freeTrialUsed ? paypalMonthly : paypalTrialMonthly;
  } else if (product.productType.toLowerCase().includes("quarterly")) {
    return freeTrialUsed ? paypalQuarterly : paypalTrialQuarterly;
  } else if (product.productType.toLowerCase().includes("yearly")) {
    return freeTrialUsed ? paypalYearly : paypalTrialYearly;
  }
  return undefined;
}

export function getStripeStatusFromPaypalStatus(status: PayPalSubscriptionStatus): SubscriptionStatus {
  switch (status) {
    case PayPalSubscriptionStatus.ACTIVE:
      return SubscriptionStatus.ACTIVE;
    case PayPalSubscriptionStatus.APPROVAL_PENDING:
      return SubscriptionStatus.INCOMPLETE;
    case PayPalSubscriptionStatus.APPROVED:
      return SubscriptionStatus.INCOMPLETE;
    case PayPalSubscriptionStatus.CANCELLED:
      return SubscriptionStatus.CANCELLED;
    case PayPalSubscriptionStatus.EXPIRED:
      return SubscriptionStatus.INCOMPLETE_EXPIRED;
    case PayPalSubscriptionStatus.SUSPENDED:
      return SubscriptionStatus.INCOMPLETE;
    default:
      return SubscriptionStatus.INCOMPLETE;
  }
}

export function parseStripeProducts(products: Price[]): Product[] {
  return products
    .map((product) => {
      const recurringInterval = product.recurring?.interval!;
      const intervalCount = product.recurring?.interval_count!;
      const planType = getPlanType(recurringInterval, intervalCount);
      const price = stripePriceToPrice(product.unit_amount!);
      const duration = stripeIntervalToDuration(recurringInterval, intervalCount);
      const productNameSplit = (product.product as Stripe.Product)?.name?.split(" ");
      const productType = productNameSplit[productNameSplit.length - 1];
      const priceUnit = getPriceUnit(product.currency);
      const discount = productType === "Yearly" ? "45%" : productType === "Quarterly" ? "19%" : undefined;
      const recurringIntervalCount = recurringInterval === "year" ? 12 : intervalCount > 1 ? intervalCount : 0;
      const description = translate("fullAccessToAmo", {
        intervalCount: recurringIntervalCount > 0 ? recurringIntervalCount : "",
        interval: recurringIntervalCount > 0 ? translate("months") : translate("month"),
      });
      const parsedProduct: Product = {
        id: product.id,
        price,
        duration,
        productType,
        priceUnit,
        discount,
        planType,
        description,
        currency: product.currency,
        unit_amount: product.unit_amount!,
      };
      return parsedProduct;
    })
    .reverse();
}

export function extractProductIdFromPaymentPathname(pathname: string): string {
  return pathname.replace(Routes.PAYMENT.substr(0, Routes.PAYMENT.length - 3), "");
}

export function getCouponFromUrl(url: string): string | undefined {
  const searchString = "coupon=";
  // coupon is missing or not correctly positioned
  if (url.lastIndexOf("coupon=") < 10) {
    return undefined;
  }
  return url.substring(url.lastIndexOf("coupon=") + searchString.length);
}

export function roundTo2Decimals(num: number): number {
  return Math.trunc(num * 100) / 100;
}

export function localizePlanDuration(duration: string) {
  const count = parseInt(duration);
  if (count) {
    return translate("xMonths", { count });
  }
  return translate(duration as TransKey);
}
